<script setup>
import {defineEmits, ref, computed, reactive, watch, onMounted} from 'vue'

const props = defineProps({
    boardID : {
        type: String,
        default: "",
  },
  nrof_stones: {
    type: Number,
    required: true
  },
  nrof_ghost_stones: {
    type: Number,
    default: 0,
  },
  id:{
    type: Number,
    required: true,
  },
  color:{
    type: String,
    default: undefined
  },
  highlight:{
    type: Boolean,
    default:false,
  },
  highlighted:{
    type: Boolean,
    default:false,
  },
  difference: {
    type:Number,
    default: 0,
  },
  show_pip_number: {
    type: String,
    default: "",
  },
});

const emit = defineEmits([
    'move-stone',
    'drag-stone',
    'move-highlight',
    'mounted',
]);

onMounted(() => {
    emit('mounted');
});

function getNrofStones(){
    return Math.min(props.nrof_stones);
}

function getNrofGhostStones(){
    return Math.min(Math.max(5-props.nrof_stones, 0), props.nrof_ghost_stones);
    // return Math.min(props.nrof_stones, props.nrof_ghost_stones);
}

function getClass(index){
    const classes = [props.color];
    if(props.difference > 0 && index > props.nrof_stones - props.difference){
        classes.push("moved");
    }
    if(index > 5){
        // classes.push("stacked");
    }
    return classes; 
}

var start_xy = [null, null];
var minimum_delta_click = 10;

function get_height_in_point(e){
    const elem = document.getElementById(`${props.boardID}-point-${props.id}`);
    const board_elem = document.getElementById(`board-${props.boardID}`);
    const checker_elements = board_elem.getElementsByClassName("stone");

    const elem_pos = elem.getBoundingClientRect();
    const board_pos = board_elem.getBoundingClientRect();

    const stone_height = (checker_elements && checker_elements.length > 0) ? 
            checker_elements[0].getBoundingClientRect().height : 
            elem_pos.width;
    const elem_y = elem_pos.top + window.scrollY;
    minimum_delta_click = stone_height;

    var offset_y;
    var topside = elem_pos.y < (board_pos.y + board_pos.height/3 );

    if(props.id == 0 || props.id == 25){
        topside ^= true;
    }

    if(topside){
        offset_y = e.pageY - (elem_y); // TODO add window.scrollY
    }else{
        offset_y = (elem_y) - e.pageY + elem_pos.height;
    }
    if(offset_y < stone_height/2){
        offset_y = -1;
    }
    const height = Math.floor(offset_y / stone_height) + 1;
    return height;
}

function handle_mousedown(e){
    start_xy[0] = e.pageX;
    start_xy[1] = e.pageY;
    if(e.buttons == 1){// Left mouse button
        const height = get_height_in_point(e);
        emit('drag-stone', [props.id, 'left', height, e]);
    }
}
function handle_mousedrag(e){
    if(e.buttons == 1){// Left mouse button
        const height = get_height_in_point(e);
        emit('drag-stone', [props.id, 'left', height, e]);
    }
}
function handle_mouseup(e){
    if(Math.abs(e.pageX - start_xy[0]) < minimum_delta_click &&
        Math.abs(e.pageY - start_xy[1]) < minimum_delta_click){
        const height = get_height_in_point(e);
        // this was a click
        if(e.which == 1){// Left mouse button
            emit('move-stone', [props.id, 'left', height, e]);
        }else if(e.which == 3){// Left mouse button
            emit('move-stone', [props.id, 'right', height, e]);
        }
    }
}

</script>

<template>
    <!--
        @click.exact="$emit('move-stone', [props.id, 'left'])"
        @click.right.exact.prevent="$emit('move-stone', [props.id, 'right'])"
    -->
    <ul class="slot" :class="
        {bar : (id == 0 || id==25), 
         point: (id!=0 && id!=25),
         odd: id % 2 == 1,
         first: id == 1,
         last: id == 24,
        }
        "
        :id="boardID + '-point-'+id"
        @mousedown="(e) => handle_mousedown(e)"
        @mouseenter="(e) => handle_mousedrag(e)"
        @mouseup="(e) => handle_mouseup(e)"
        @mouseover="$emit('move-highlight', props.id)"
        @mouseleave="$emit('move-highlight', null)"
        @click.right.prevent="(e) => {}"
    >
        <div class="triangle" :class="{highlighted: highlighted}" />
        <div class="pip-number text-xs @md:text-sm z-10"  
            :class="{'invisible': !(props.show_pip_number && id > 0 && id < 25)}"
        >
            <span v-if="props.show_pip_number == 'W'">
                {{ 25 - id }}
            </span>
            <span v-else>
                {{ id }}
            </span>
        </div>
        <li class="stone" v-for="i in Math.min(getNrofStones(), 4)" 
            :class="getClass(i)" 
            :key="id*15 + i"
            :id="boardID + '-stone-'+id+'-'+i"
        >
        </li>
        <li class="stone overflow relative" 
            :id="boardID + '-stone-'+id+'-overflow'"
            :class="getClass(nrof_stones)"
            v-if="nrof_stones > 4"
        >
            <div class="stone stacked" 
                 v-for="idx in (getNrofStones() < 5) ? 0 : getNrofStones() - 4"
                 :set="i = idx + 4"
                :class="getClass(nrof_stones)"
                :id="boardID + '-stone-'+id+'-'+i"
                :key="id*15 + i"
            />
            <div v-if="nrof_stones > 5">
                {{ nrof_stones }}
            </div>
        </li>
        <li v-for="i in getNrofGhostStones()"
            class="stone ghost" 
            :id="boardID + '-ghost-stone-'+id+'-'+i"
        >
        </li>
        <div :class="{highlight: highlight}"></div>
    </ul>
</template>

<style scoped>
.slot{
    display:flex;
    flex-direction:column;
    /*overflow:hidden;*/
    position:relative;
    padding:0;
    margin:0;
}

.slot:nth-child(n+14){
    flex-direction:column-reverse;
    margin-bottom:0;
}
    
.bar{
    background-color:var(--case-color);
    margin-bottom: 0em;
    margin-top: 0em;
    justify-content: flex-start;
    padding-top: 1em;
    padding-bottom:0;
}

.slot:nth-child(n+14).bar{
    margin-bottom: 0;
    padding-bottom: 10%;
    padding-top:0;
    padding-bottom: 1em;
}

.point > .triangle{
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
    position:absolute;
    width:100%;
    height:90%;
}
.slot:nth-child(n+14) > .triangle{
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangle{
    background: var(--point-b-color);
}
.odd > .triangle{
    background: var(--point-w-color);
}

.triangle.highlighted{
    background-color: var(--highlight-light-color);
}
.odd > .triangle.highlighted{
    background-color: var(--highlight-color);
}

.first > .triangle{
    background: var(--point-w-first-color);
}

.last > .triangle{
    background: var(--point-b-first-color);
}


.highlight{
    background-color: var(--case-color);
    width:0.5em;
    height:0.5em;
    position:absolute;
    left: calc(50% - 0.25em);
    border-radius:50%;
    display:inline-block;
    bottom: 1em;
    z-index:200;
}
.slot:nth-child(n+14) > .highlight{
    top: 1em;
}
    
.slot:nth-child(14) {grid-column:1} 
.slot:nth-child(15) {grid-column:2}
.slot:nth-child(16) {grid-column:3}
.slot:nth-child(17) {grid-column:4}
.slot:nth-child(18) {grid-column:5}
.slot:nth-child(19) {grid-column:6}
.slot:nth-child(20) {grid-column:7}
.slot:nth-child(21) {grid-column:8}
.slot:nth-child(22) {grid-column:9}
.slot:nth-child(23) {grid-column:10}
.slot:nth-child(24) {grid-column:11}
.slot:nth-child(25) {grid-column:12}
.slot:nth-child(26) {grid-column:13}

.stone{
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    border-radius: 50%;

    border: solid 2px;
    border: solid 0.4cqw;

    box-sizing: content-box;
    height: auto;
    width: calc(95% - 4px);
    width: calc(95% - 0.8cqw);
    aspect-ratio: 1/1;
    content:content-box;
}
@container (min-aspect-ratio: 13/11 and min-width: 512px){ /* landscape */
    .stone {
        width: auto;
        height: calc(18% - 4px);
        height: calc(18% - 0.8cqw);
    }
}

.stone.W{
    background: var(--stone-w-color);
    border-color: var(--stone-border-w-color);
    color:var(--stone-b-color);
}
.stone.B{
    background: var(--stone-b-color);
    border-color: var(--stone-border-b-color);
    color:var(--stone-w-color);
}
.stone.overflow{
    display:flex;
    font-size:1.5em;
    line-height:100%;
    font-weight:bolder;
    text-align:center;
    align-items:center;
    justify-content:center;
    z-index: 10;
}
.stone.stacked{
    position: absolute;
    /*inset: -2px; /* 2px is the border width */
    /*inset: -0.4cqw; /* 2px is the border width */
    z-index: -10;
    width: 100%;
    height: 100%;
}

.stone.ghost{
    background-image: repeating-linear-gradient(-45deg, 
        var(--stone-ghost-color), var(--stone-ghost-color) 1px, transparent 1px, transparent 6px);
    background-size: 4px 4px;
    background-color: transparent;
    border-color: var(--stone-ghost-color);
}
    
.stone.W.moved{
    border-color: var(--highlight-color);
    box-shadow: inset 0 0 1em var(--highlight-color);
    /*
    background-color: var(--stone-w-highlight-color);
    background-image: repeating-linear-gradient(-45deg, 
        var(--highlight-color), var(--highlight-color) 2px, transparent 2px, transparent 6px);
    background-size: 4px 4px;
    */
}
.stone.B.moved{
    border-color: var(--highlight-color);
    box-shadow: inset 0 0 1rem var(--highlight-color);
    /*
    background-color: var(--stone-b-highlight-color);
    background-image: repeating-linear-gradient(-45deg, 
        var(--point-w-color), var(--point-w-color) 2px, transparent 2px, transparent 6px);
    background-size: 4px 4px;
    */
}
.pip-number{
    position:absolute;
    color: var(--board-text-color);
    text-align:center;
    width:100%;
    top: -1.25em;
}
.slot:nth-child(n+14) > .pip-number{
    bottom: -1.25em;
    top:auto;
}
</style>
