<script setup>
import {ref, computed, reactive, onMounted, watch} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {XMarkIcon, ChevronRightIcon, ChevronLeftIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'
import { useRoute, useRouter } from 'vue-router'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import ButtonLoad from "./ButtonLoad.vue"
import MarkdownRenderer from './MarkdownRenderer.vue'
import Popup from "./Popup.vue"

const app_server = import.meta.env.VITE_APP_SERVER;

const router = useRouter();

function sleep(n) { return new Promise(resolve=>setTimeout(resolve,n)); }

const props = defineProps({
    pages: {
        type: Object,
        default: null,
    },
    show: {
        type: Boolean,
        default: null,
    },
    in_admin: {
        type: Boolean,
        default: false,
    },

});

const extra_data = reactive({
    show: true,
    index: 0,
    current_note: null,
    pages: [],
    release_notes: [],
});

const mock_data = [
    {
        title: "Play the new bots!",
        content: "Aaron joined the fray!",
        image: "/media/screenshots/333344477jjmmoo_2225566688dddde_B1N_43_B_R_2_4_0_161.png",
        action_text: "Play Aaron!",
        action_url: "/bot/",

    },
    {
        title: "Play Jessica!",
        content: "Jessica is mostly interested in playing 1 pointers! See if you can beat her!",
        image: "/media/screenshots/155cccghhhjjjjj_66666888bddddik_N0N_45_W_R_3_0_5_4.png",
    },
];

function get_page(){
    if(extra_data.pages[0] == null ){
        return {};
    }
    if(extra_data.index < 0){
        extra_data.index = extra_data.pages.length - 1;
    }else if(extra_data.index >= extra_data.pages.length){
        extra_data.index = 0;
    }
    return extra_data.pages[extra_data.index];    
}

function closed_popup(){
    extra_data.show = false;
    if(extra_data.current_note != null){
        set_seen(extra_data.current_note.note_id);
    }else{
        console.log("NO CURRENT");
    }
}

const seen_notes = [];
function has_seen(note_id){
    if(props.in_admin || note_id == null){
        return false;
    }
    if(seen_notes.length == 0){
        seen_notes.push(...JSON.parse(localStorage.getItem("releasenotes:seen") || "[null]"));
    }
    console.log(seen_notes);
    return seen_notes.includes(note_id);
}

async function set_seen(note_id){
    console.log("Set seen:", note_id);
    if(has_seen(note_id) || props.in_admin || note_id == null){
        console.log("cancel");
        return;
    }
    var seen = await JSON.parse(localStorage.getItem("releasenotes:seen") || "[null]");

    seen.push(note_id);
    seen = seen.filter((x) => x != null);
    seen = seen.slice(-50); // only keep the last 50

    localStorage.setItem("releasenotes:seen", JSON.stringify(seen))
    await signal(note_id, "view"); 
}

async function get_releasenotes(){
    var url = app_server + `/releasenote/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    
    if(data.status == "success"){
        extra_data.release_notes = data.notes.filter((x) => !has_seen(x.note_id));

        if(extra_data.release_notes.length > 0){
            const note_to_show = extra_data.release_notes[0];
            if(note_to_show.pages.data != null){
                extra_data.pages = note_to_show.pages.data;
                extra_data.current_note = note_to_show;
                extra_data.show = true;
            }
        }
    }
}

async function signal(note_id, action){
    var url = app_server + `/releasenote/${note_id}/signal/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "action": action,
        }),
    });

    const data = await response.json();
    
    if(data.status == "success"){
        console.log("signalled server", note_id, action);
    }
}

function do_note_action(page){
    if(extra_data.current_note != null){
        const note_id = extra_data.current_note.note_id;
        signal(note_id, "click");
        set_seen(note_id);
    }
    if(page.action_url.startsWith("http")){
        if(confirm("You are leaving OpenGammon.com, are you sure?")){
            window.location.href = page.action_url;
        }else{
            return;
        }
    }else{
        router.push(page.action_url);
    }
    extra_data.show = false;
}

watch(() => props.pages, (x, y) => {
    extra_data.pages = props.pages;
});

watch(() => props.show, (x, y) => {
    extra_data.show = props.show;
});


onMounted(() => {
    if(props.pages == null){
        extra_data.pages = mock_data;
    }else{
        extra_data.pages = props.pages;
    }
    if(props.show == null){
        extra_data.show = false;
    }else{
        extra_data.show = props.show;
    }

    get_releasenotes();
});
// 
</script>
<template>
    <Popup :show="extra_data.show"
        @close="closed_popup()"
    >
    <div class="h-[90svh] overflow-y-auto flex flex-col gap-y-8 items-center">
        <h1 class="text-4xl mt-8">
            {{ get_page().title }}
        </h1>

        <div class="flex flex-col gap-y-2 items-center">
            <img class="w-4/5" :src="get_page().image" />
                
            <div class="flex gap-x-1">
                <div class="size-4 rounded-full bg-main-3-color border-2 border-main-3-color" 
                     v-for="p,i in extra_data.pages"
                     :class="{
                             '!bg-accent-3-color !border-accent-4-color': i == extra_data.index
                        }"
                />
            </div>
        </div>

        <div class="grow">
            <MarkdownRenderer 
                :markdown="get_page().content"
                :save="false" 
            />
        </div>

        <button class="btn btn-blue w-1/2 text-2xl !py-4"
                v-if="get_page().action_text"
                @click="do_note_action(get_page())"
        >
            {{ get_page().action_text }}
        </button>

        <ChevronLeftIcon
            class="size-10 absolute top-1/3 left-2 hover:text-accent-3-color cursor-pointer" 
            @click="extra_data.index -= 1"
            v-if="extra_data.index > 0"
        />
        <ChevronRightIcon
            class="size-10 absolute top-1/3 right-2 hover:text-accent-3-color cursor-pointer" 
            @click="extra_data.index += 1"
            v-if="extra_data.pages.length > 1"
        />
    </div>
    </Popup>     
</template>

<style scoped>
</style>


