<script setup>
import {ref, watch, reactive, onMounted, onUnmounted} from 'vue'
import { Howl, Howler } from 'howler'

import checkerAudio from '../assets/audio/checker.m4a'
import checkerHitAudio from '../assets/audio/checker_hit.wav'
import checkerDanceAudio from '../assets/audio/checker_dance.wav'
import checkerBearoffAudio from '../assets/audio/checker_bearoff.wav'
import cubeAudio from '../assets/audio/cube.wav'
import winAudio from '../assets/audio/win.wav'
import loseAudio from '../assets/audio/lose.wav'
import diceAudio from '../assets/audio/dice.wav'
import undoAudio from '../assets/audio/undo.mp3'

import {useGameSettingStore} from '@/stores/gamesettingstore.js'
const gameSettingStore = useGameSettingStore();

var semitone_rate = 1.059463;
var rates = {};
const low = (1/semitone_rate)**2;

var timeout_rate_reset_id = null;

const sounds = {
    checker : new Howl({
        src: [checkerAudio],
        volume: 1.0,
    }),
    hit : new Howl({
        src: [checkerHitAudio],
        volume: 1.0,
        rate: low,
    }),
    dance : new Howl({
        src: [checkerDanceAudio],
        volume: 1.0,
        rate: 1.0,
    }),
    bearoff : new Howl({
        src: [checkerBearoffAudio],
        volume: 1.0,
        rate: low,
    }),
    cube : new Howl({
        src: [cubeAudio],
        volume: 1.0,
        rate: low,
    }),
    win : new Howl({
        src: [winAudio],
        volume: 1.0,
        rate: 1.0,
    }),
    lose : new Howl({
        src: [loseAudio],
        volume: 1.0,
        rate: 1.0,
    }),
    dice : new Howl({
        src: [diceAudio],
        volume: 1.0,
        rate: 1.0,
    }),
    undo : new Howl({
        src: [undoAudio],
        volume: 1.0,
        rate: 1.0,
    }),
};

const props = defineProps({
});

const extra_data = reactive({
});

onMounted(() => {
    document.addEventListener("board-sound", handle_sound);
});

onUnmounted( () => {
    document.removeEventListener("board-sound", handle_sound);
})


function handle_sound(e){
    if(!gameSettingStore.settings.sound){
        return;
    }
    var sound_id;
    const data = e.detail;

    if(data.type == "checker"){
        if(data.is_hit){
            sound_id = "hit";
        }
        else if(data.is_bearoff){
            sound_id = "bearoff";
        }
        else{
            sound_id = "checker";
        }
    }else{
        sound_id = data.type;
    }

    const sound = sounds[sound_id]
    console.log("Playing:", sound_id, sound);
    if(sound){
        const s_id = sound.play();
        rates[sound_id] = rates[sound_id] || sound.rate();
        sound.rate(rates[sound_id], s_id);

        rates[sound_id] *= semitone_rate;
    }
    
    timeout_rate_reset_id = setTimeout(() => {
        clearTimeout(timeout_rate_reset_id);
        rates = {};
    }, 1500);
}

</script>

<template>
</template>

<style scoped>
</style>

