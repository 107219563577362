<script setup>
const model = defineModel();
const emit = defineEmits(["change"]);

</script>
<template>
    <div class="relative">
    <input type="checkbox" class="sr-only peer" v-model="model" @change="$emit('change')">
    <div class="w-11 h-6 bg-main-5-color peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  
                rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full 
                peer-checked:after:border-main-1-color after:content-[''] after:absolute after:top-[2px] 
                after:start-[2px] after:bg-main-1-color after:border-main-4-color after:border 
                after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-accent-3-color">
    </div>
    </div>
</template>
