import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js'; // For Base64 encoding of the secret

import { Match } from '../assets/js/match.js'
import { BoardState } from '../assets/js/board.js'

const app_server = import.meta.env.VITE_APP_SERVER;

export const useMatchSecretStore = defineStore('matchsecret', {
    state: () => { return {
        match_data: null,
    };},
    getters: {
    },
    actions:{

    set_secret(match_id, player_data){
        /*
         * Given a match_id and player_data that we want to save as secrets we
         * save the secret in the store (and localStorage).
         */
        for(const key in player_data){
            const field = player_data[key];
            if(field == null){
                continue;
            }
            if(key != "timestamp" && !(field instanceof String || typeof field == "string")){
                console.log("STR", field,  typeof field);
                player_data[key] = CryptoJS.enc.Base64.stringify(field);
            }
        }

        this.get_secret(match_id); // This loads the store into memory.

        if(this.match_data[match_id] == null){
            this.match_data[match_id] = {
                timestamp: Date.now(),
            };
        }

        // Remove all unset fields in the player data object
        for(const key in player_data){
            if(player_data[key] == null){
                delete player_data[key];
            }
        }

        // Don't override fields in the match_data
        Object.assign(this.match_data[match_id], 
            {...player_data, ...this.match_data[match_id]}
        );

        this.clean_store();
        localStorage.setItem("match:secrets", JSON.stringify(this.match_data))
    },

    clean_store(){
        for(const key in this.match_data){
            const two_weeks = 14*24*60*60*1000;
            if(this.match_data[key].timestamp == null || 
               this.match_data[key].timestamp < Date.now() - two_weeks){
                console.debug("Removing", key);
                delete this.match_data[key]
            }
        }
    },

    get_secret(match_id){
        if(this.match_data == null){
            const match_data_str = localStorage.getItem("match:secrets");

            if(match_data_str == null){
                this.match_data = {};
            }else{
                this.match_data = JSON.parse(match_data_str);
            }
        }

        return this.match_data[match_id];
    },

    async send_secret(match_id){
        if(match_id == null){
            return;
        }

        const request_data = this.get_secret(match_id); 

        if(request_data == null){
            return;
        }

        const response = await fetch(app_server + 
            `/match/${match_id}/secrets/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(request_data),
        });
        
        const data = await response.json();
    }

    }
});


