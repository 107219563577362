<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon, ArrowLeftIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import UserFind from './UserFind.vue'
import Avatar from './Avatar.vue'
import UserTag from './UserTag.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const tournament_id = route.params.tournament_id;
const json_fields = ["structure_options", "participants", "disqualified", "invited", "moderators"]; 
const date_fields = ["create_time","registration_open_time", "start_time", "finish_time"]; 

const select_fields = {
    // time_control: [],
    rated: [ ["Rated", 1], ["Unrated", 0] ],
    visibility: [ ["Listed", "public"], ["Unlisted", "private"], ["Private", "private"] ],
    structure: [ ["Single Elimination", "single-elimination"], ],
};

const advanced_fields = ["rated", "structure_options", "finish_time", "rruleset", "signup_constraint"];

const editable_fields = [
    "name", "description", 
    "points", "time_control", "rated",
    "max_participants",
    "visibility",
    "structure", "structure_options",
    "registration_open_time", "start_time", "finish_time", 
    "duration", "rruleset",
    "signup_constraint",
];

const actions = {
    "cancel matchmaking": cancel_joins,
    "end tournament": end_tournament,
};


const field_info = {
    "name": "A short name for the tournament",
    "description": "A description of the tournament. This field accepts markdown.",
    "points": "The match length of the matches in the tournament",
    "time_control": "The time control used for the matches in this tournament",
    "rated": "If not rated, no rating points exchange hands during the tournament and matches do not count towards average ER's",
    "max_participants": "Maximum number of participants, more can register. Set to 0 for no limit.",
    "visibility": "Listed - everyone can see the tournament, Unlisted - The tournament is not listed on the tournaments page.",
    "structure": "The tournament structure used",
    "start_time": "The time the registration ends, and the participant list is published.",
    "duration": "The time in each round that users can match up in seconds.",
    "rruleset": "The RRULE used to determine the round times.",
    "signup_constraint": "Advanced. You can set a constraint for registrations to the tournament. E.g. 'rating < 800'",
};

const extra_data = reactive({
    info: {},
    match_list: [],
    invitations: [],
    show_edit: false,
    show_advanced: false,

    current_action: null,
});

const tournament_data = reactive({

});

const user_data = reactive({
});

const ping_data = reactive({
    title: "",
    message: "",
})

function get_time_string(utc_timestamp){
    /* 
    Returns a local timestamp accounting for the timezon of the client 

    The timestamp is to be used in datetime-local inputs
    */
    const timezone_offset = (new Date()).getTimezoneOffset()*60*1000;
    const time_string = new Date(
        (utc_timestamp * 1000) - timezone_offset
    ).toISOString().slice(0,16);

    return time_string;
}

async function get_tournament_info(){
    var url = app_server + `/tournament/${tournament_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();


    if(data.matches){
        extra_data.match_list.push(...data.matches);
    }

    Object.assign(tournament_data, data.info);

    if(data.info != null){
        if(data.info.name != null){
            // setPageTitle(data.info.name);
        }
        for(let field of json_fields){
            if(tournament_data[field] != null){
                tournament_data[field] = JSON.stringify(
                    tournament_data[field], null, 4
                );
            }
        }
        for(let field of date_fields){
            if(tournament_data[field] != null){
                tournament_data[field] = get_time_string(tournament_data[field]);
            }
        }
        if(data.info.round_times != null){
            for(let i in data.info.round_times){
                tournament_data.round_times[i][0] = get_time_string(tournament_data.round_times[i][0]);
                tournament_data.round_times[i][1] = get_time_string(tournament_data.round_times[i][1]);
            }
        }
    }
    console.log("Tournament data", data);

    Object.assign(extra_data, data);
}

async function get_user_data(){
    var url = app_server + `/tournament/mod/${tournament_id}/userinfo/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();
    console.log(data);
    Object.assign(user_data, data.users);
}

async function start_tournament(){
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/start/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    get_tournament_info();
}

async function end_round(){
    if(!confirm(`Are you sure you want to end the the current round? This action is irreversible.`)){
        return;
    }
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/endround/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    get_tournament_info();
}

async function end_tournament(){
    if(!confirm(`Are you sure you want to end the tournament? This action is irreversible.`)){
        return;
    }
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/end/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    console.log("END", data);

    get_tournament_info();
}

async function ping_participants(){
    if(!confirm(`Are you sure you want to ping all tournament participants?.`)){
        return;
    }
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/ping/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(ping_data),
    });
    const data = await response.json();

    console.log(data);

    get_tournament_info();
}

async function invite_participants(){
    if(!confirm(`Are you sure you want to invite ${extra_data.invitations.length} participants?.`)){
        return;
    }
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/invite/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            invitations: extra_data.invitations.map(x => x.user_id), 
        }),
    });
    const data = await response.json();

    console.log(data);

    get_tournament_info();
}

async function cancel_joins(){
    if(!confirm(`Are you sure you want to cancel all current joins?.`)){
        return;
    }
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/canceljoins/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();

    get_tournament_info();
}

async function disqualify_user(user_id){
    if(!confirm(`Are you sure you want to disqualify ${user_data[user_id].username}? This action is irreversible.`)){
        return;
    }

    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/disqualify/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            user_id: user_id,
        }),
    });
    const data = await response.json();

    get_tournament_info();
}

async function calculate_round_times(){
    const response = await fetch(app_server + `/tournament/mod/${ tournament_id }/roundtimes/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
        }),
    });

    const data = await response.json()
    console.log(data);
    if(data.status == "success"){
        messageStore.alertUser("Success", "Round times for the tournament were recalculated");
        get_tournament_info();
    }
}

async function save_tournament(){
    // First compute timestamps from the tournament times
    const data = Object.assign({}, tournament_data);
    for(let i in data.round_times){
        data.round_times[i][0] = new Date(data.round_times[i][0]) / 1000;
        data.round_times[i][1] = new Date(data.round_times[i][1]) / 1000;
    }

    var url = app_server + `/admin/tournament/${tournament_id}/`;

    for(let field of json_fields){
        if(data[field] != null){
            data[field] = JSON.parse(data[field]);
        }
    }
    for(let field of date_fields){
        if(data[field] != null){
            data[field] = new Date(data[field]) / 1000;
        }
    }

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const resp_data = await response.json();
    get_tournament_info();
}

onMounted(async () => {
    await Promise.allSettled([
        userStore.loadUser(),
        get_tournament_info(),
        get_user_data(),
    ])
});

</script>
<template>
    <Header/>
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-8 mb-32">
        <h1 class="text-4xl mt-8 mb-4 flex items-center gap-x-4">
            <router-link :to="{name: 'tournament-info', params: {tournament_id: tournament_id}}">
                <ArrowLeftIcon class="size-8 hover:text-accent-3-color" />
            </router-link>
            {{ extra_data.info.name }}
        </h1>
        <div class="flex gap-x-2">
            <ButtonLoad class="btn btn-blue"
                :disabled="extra_data.info.status != 'inactive'"
                :fn="start_tournament"
            >
                Start Tournament
            </ButtonLoad>
            <ButtonLoad 
                class="btn btn-blue"
                :disabled="extra_data.info.status != 'active'"
                :fn="end_round"
            >
                End Round 
            </ButtonLoad>
            <ButtonLoad 
                class="btn btn-blue"
                :disabled="extra_data.info.status != 'active'"
                :fn="end_tournament"
            >
                End Tournament
            </ButtonLoad>
            <button class="btn btn-blue" @click="extra_data.show_edit ^= true">
                Edit
            </button>
        </div>
        <div class="flex">
            <select
                v-model="extra_data.current_action"
            >
                <option :value="null">Choose action...</option>
                <option v-for="key in Object.keys(actions)"
                >
                    {{ key }}
                </option>
            </select>
            <ButtonLoad 
                class="btn btn-blue"
                :disabled="extra_data.current_action == null"
                :fn="actions[extra_data.current_action]"
            >
                Do Action
            </ButtonLoad>
        </div>
        <div class="flex flex-col gap-y-4">
            <div class="flex flex-col gap-y-2 max-w-prose" v-if="extra_data.show_edit">
                <h2 class="text-xl">Tournament Properties</h2>
                <label v-for="field in editable_fields.filter((x) => extra_data.show_advanced || !advanced_fields.includes(x))">
                    <Tooltip :text="field_info[field] || ''">
                    <span class="capitalize">{{ field.replaceAll("_", " ") }}</span>
                    </Tooltip>
                    <textarea 
                        v-model="tournament_data[field]"
                        v-if="['description', 'rruleset', ...json_fields].includes(field)"
                        :id="field"
                        class="w-full"
                    ></textarea>
                    <input type="datetime-local" v-else-if="date_fields.includes(field)"
                        v-model="tournament_data[field]"
                    >
                    <select v-else-if="select_fields[field] != null"
                        v-model="tournament_data[field]"
                    >
                        <option v-for="option in select_fields[field]"
                                :value="option[1]">
                            {{ option[0] }}
                        </option>
                    </select>
                    <input 
                        class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                ring-inset ring-gray-300 placeholder:text-gray-400 
                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        v-model="tournament_data[field]"
                        v-else
                        :id="field"
                    />
                </label>

                <h2 class="text-xl flex items-center gap-x-4 mt-4">
                    Tournament round times
                    <ButtonLoad 
                        class="btn btn-yellow text-base" 
                        :fn="calculate_round_times"
                    >
                        Recalculate
                    </ButtonLoad>
                </h2>
                <div v-for="round_time, i in tournament_data.round_times">
                    <label>Round {{ i+1 }}:
                        <div>
                            <input type="datetime-local" v-model="round_time[0]">
                            <input type="datetime-local" v-model="round_time[1]">
                        </div>
                    </label>
                </div>

                <div class="flex gap-x-2">
                    <ButtonLoad :fn="save_tournament" class="h-10 btn btn-blue grow">
                        Save Tournament Details
                    </ButtonLoad>
                    <button class="btn btn-yellow" @click="extra_data.show_advanced ^= true">
                        Advanced
                    </button>
                </div>
            </div>
        </div>
        <div class="">
            <h2 class="text-xl mb-2">
                Registered Users ({{ extra_data.users_registered }} / {{extra_data.info.max_participants}})
            </h2>
            <div v-for="user_id in extra_data.registered"
                 class="hover:bg-accent-1-color flex justify-between group"
            >
                <div class="flex items-center">
                    <div v-if="extra_data.info.disqualified.includes(user_id)"
                         class="font-bold"
                    >
                        DQ
                    </div>
                    <UserName :user="user_data[user_id]" />
                </div>
                <div class="invisible group-hover:visible">
                    <button class="btn btn-blue" 
                        @click="disqualify_user(user_id)"
                    >
                        disqualify
                    </button>
                </div>
            </div>
        </div>

        <div class="">
            <h2 class="text-xl mb-2">
                Invited Users
            </h2>
            <div v-for="user_id in extra_data.info.invited"
                 class="hover:bg-accent-1-color flex justify-between group"
            >
                <div>
                    <UserName :user="user_data[user_id]" :user_id="user_id"/>
                </div>
                <div class="invisible group-hover:visible">
                    <button class="btn btn-blue">
                        Remove
                    </button>
                </div>
            </div>
        </div>

        <div class="">
            <h2 class="text-xl mb-2">
                Disqualified Users
            </h2>
            <div v-for="user_id in extra_data.info.disqualified"
                 class="hover:bg-accent-1-color flex justify-between group"
            >
                <div>
                    <UserName :user="user_data[user_id]" />
                </div>
                <div class="invisible group-hover:visible">
                    <button class="btn btn-blue">
                        Remove
                    </button>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-y-4">
            <h2 class="text-xl mb-2">
                Ping participants
            </h2>
            <textarea 
                v-model="ping_data.message" 
                class="w-full max-w-prose"
                placeholder="Message to the participants of the tournament"></textarea>
            <button class="btn btn-blue w-64 h-10"
                    :disabled="ping_data.message.length < 3"
                    @click="ping_participants()"
            >
                Send</button>

        </div>
        <div class="flex flex-col gap-y-4">
            <h2 class="text-xl mb-2">
                Invite participants
            </h2>
            <ul>
                <li v-for="participant in extra_data.invitations">
                    <UserName :user="participant" />
                </li>
            </ul>
            <UserFind @selecteduser="x => extra_data.invitations.push(x)"/>
            <button class="btn btn-blue w-64 h-10"
                    :disabled="extra_data.invitations.length < 1"
                    @click="invite_participants()"
            >
                Invite
            </button>

        </div>
    </div>
</template>

<style>
</style>

