<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {ChatBubbleLeftRightIcon, PuzzlePieceIcon, SparklesIcon, FireIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import UserFind from './UserFind.vue'
import UserName from './UserName.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const props = defineProps({
  inline: {
    type: Boolean,
    default: false,
  },
});

const extra_data = reactive({
    type: "rating",
    for_user: null,
    current_offset: 0,
    leaderboard: [],
});

onMounted(() => {
    // await userStore.loadUser();
    // await userStore.loadOnline();
    get_leaderboard();
});

async function get_leaderboard(){
    const response = await fetch(app_server + `/user/leaderboard/?type=${extra_data.type}&user_id=${extra_data.for_user}&offset=${extra_data.current_offset}`,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const response_data = await response.json()
    extra_data.leaderboard = response_data.leaderboard; 
    if(extra_data.leaderboard.length > 0){
        extra_data.current_offset = extra_data.leaderboard[0].rank;
    }else{
        extra_data.current_offset = 0;
    }
}

async function next_leaderboard(){
    extra_data.current_offset += 20;
    extra_data.for_user = null;
    get_leaderboard();
}

async function prev_leaderboard(){
    extra_data.current_offset = Math.max(extra_data.current_offset - 20, 0);
    extra_data.for_user = null;
    get_leaderboard();
}

function get_user_leaderboard(){
    if(!userStore.authenticated){
        return;
    }
    extra_data.for_user=userStore.info.user_id; 
    extra_data.current_offset = 0; 
    get_leaderboard(extra_data.type);
}

</script>
<template>
<Header v-if="!props.inline" />
<Message v-if="!props.inline" />
<div :class="{
    'hidden md:block': props.inline, 
    'w-fit mx-auto': !props.inside
}">
    <h1 class="text-2xl mb-2 flex flex-nowrap justify-between items-center">
        <span>Leaderboard </span>
        <button class="btn btn-yellow text-sm" 
            v-if="extra_data.for_user == null" 
            @click="get_user_leaderboard()">
            Show Rivals
        </button>
        <button class="btn btn-yellow text-sm" 
            v-else 
            @click="extra_data.for_user=null; extra_data.current_offset = 0; get_leaderboard(extra_data.type);"
        >
            Show Top 20
        </button>
    </h1> 

    <UserFind 
            class="mb-4"
            @selecteduser="(user) => {
                extra_data.for_user=user.user_id; 
                get_leaderboard(extra_data.type);
            }"
    />

    <span class="flex gap-x-2">
        <Tooltip :text="'Rating'" :direction="'top'">
        <button class="btn btn-yellow" :disabled="extra_data.type == 'rating'" @click="extra_data.type = 'rating'; get_leaderboard()">
            <FireIcon class="w-6 h-6 inline-block"/>
        </button>
        </Tooltip>

        <Tooltip :text="'Sparkles'" :direction="'top'">
        <button class="btn btn-yellow" 
                :disabled="extra_data.type == 'coins'" 
                @click="extra_data.type='coins'; get_leaderboard()">
            <SparklesIcon class="w-6 h-6 inline-block"/>
        </button>
        </Tooltip>

        <Tooltip :text="'Error Rate'" :direction="'top'">
        <button class="btn btn-yellow" 
                :disabled="extra_data.type == 'er'" 
                @click="extra_data.type = 'er'; get_leaderboard()">
            ER
        </button>
        </Tooltip>

        <Tooltip :text="'Puzzle Rating'" :direction="'top'">
        <button class="btn btn-yellow" 
                :disabled="extra_data.type == 'puzzle_rating'" 
                @click="extra_data.type = 'puzzle_rating'; get_leaderboard()">
            <PuzzlePieceIcon class="w-6 h-6 inline-block"/>
        </button>
        </Tooltip>

        <Tooltip :text="'Comment Rating'" :direction="'top'">
        <button class="btn btn-yellow" 
                :disabled="extra_data.type == 'comment_rating'" 
                @click="extra_data.type = 'comment_rating'; get_leaderboard()">
            <ChatBubbleLeftRightIcon class="w-6 h-6 inline-block"/>
        </button>
        </Tooltip>
        <Tooltip :text="'Master Points'" :direction="'top'">
        <button class="btn btn-yellow" 
                :disabled="extra_data.type == 'master_points'" 
                @click="extra_data.type = 'master_points'; get_leaderboard()">
            <div class="w-6 h-6 inline-block">MP</div>
        </button>
        </Tooltip>
    </span>
    <table class="table-fixed">
        <tr v-for="user, index in extra_data.leaderboard" :key="index" 
            :class="{'font-semibold': userStore.info != null  && user.user_id == userStore.info.user_id}">
            <td class="w-6">{{ user.rank + 1}})</td> 
            <td class="min-w-[10em]"
                :class="{'font-semibold': user.user_id == (extra_data.for_user || userStore.info.user_id)}"
            >
                <UserName :user="user" />
            </td>
            
            <td class="text-right tabular-nums">
                <span v-if="extra_data.type == 'coins'">{{ user.coins.toFixed(0) }}</span>
                <span v-if="extra_data.type == 'elo'">{{ user.elo.toFixed(2) }}</span>
                <span v-if="extra_data.type == 'rating'">{{ user.rating.toFixed(2) }}</span>
                <span v-if="extra_data.type == 'er'">
                    <span v-if="user.er < 100">{{ user.er.toFixed(2) }}</span>
                    <span v-else>-</span>
                </span>
                <span v-if="extra_data.type == 'puzzle_rating'">{{ user.puzzle_rating.toFixed(2) }}</span>
                <span v-if="extra_data.type == 'comment_rating'">{{ user.comment_rating.toFixed(0) }}</span>
                <span v-if="extra_data.type == 'master_points'">{{ user.master_points.toFixed(1) }}</span>
                
            </td>
            <td class="flex flex-nowrap items-center text-left">
                <div 
                    :class="{
                        'arrow-up': user.rank < user.previous_rank, 
                        'arrow-down':user.rank > user.previous_rank}">
                </div>
                <div class="text-sm"
                    v-if="user.rank != user.previous_rank">(<span v-if="user.rank < user.previous_rank">+</span>{{ user.previous_rank - user.rank }})</div>
            </td>
        </tr>
    </table>
    <span class="flex w-full h-6 gap-x-4 py-2">
        <div class="grow flex justify-center items-center btn btn-yellow h-8"
             @click="next_leaderboard()"
        >
            <div class="arrow-next z-10"></div>
        </div>
        <div class="grow flex justify-center items-center btn btn-yellow h-8"
             @click="prev_leaderboard()"
        >
            <div class="arrow-prev"></div>
        </div>
    </span>
</div>

</template>

<style scoped>
.arrow-up{
  display: inline-block;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-bottom: 5px solid  var(--ok-color);
}

.arrow-down{
  display: inline-block;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  
  border-top: 5px solid var(--blunder-color);
}

.arrow-next{
    display: inline-block;
    width: 0; 
    height: 0; 
    border-left: 0.75em solid transparent;
    border-right: 0.75em solid transparent;
    cursor:pointer;

    border-top: 0.75em solid var(--accent-5-color);
}

.arrow-prev{
    display: inline-block;
    width: 0; 
    height: 0; 
    border-left: 0.75em solid transparent;
    border-right: 0.75em solid transparent;
    cursor:pointer;

    border-bottom: 0.75em solid var(--accent-5-color);
}
</style>
