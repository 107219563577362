<script setup>
import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { StarIcon, UserCircleIcon, ClockIcon, ClipboardDocumentIcon, ShareIcon, TrashIcon} from '@heroicons/vue/24/outline'

import Header from './Header.vue'
import Board from './Board.vue'
import ButtonLoad from './ButtonLoad.vue'

import { Match } from '../assets/js/match.js'
import {BoardState} from '../assets/js/board.js'
import {StateMachine } from '../assets/js/statemachine.js'

const app_server = import.meta.env.VITE_APP_SERVER;

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

let update_analysis_timeout_id = null;
const page_size = 20;

const state = reactive({
    current_board: new BoardState(),
    match_info: {},
    clock_data: {},
});

const extra_data = reactive({
    theme_list: [],
    offset: 0,
    featured: 0,
    total:0,
});

const color_preferences = reactive({
});

onMounted(async () => {
    get_themes()
});

onUnmounted(() => {
});

async function get_themes(){
    const get_parameters = {
        offset: extra_data.offset,
        featured: extra_data.featured,
    };
    const search_params = new URLSearchParams(get_parameters)
    const url = `/theme/?${search_params.toString()}`;
    const response = await fetch(app_server + url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    console.log("Themes", data);

    extra_data.theme_list.length = 0;
    extra_data.theme_list.push(...data.themes);
    extra_data.total = data.total;

    window.scrollTo({ top: 0, behavior: 'smooth' });
}

async function get_theme(theme_id){
    const response = await fetch(app_server + `/theme/${theme_id}/`,{
        method: "GET",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();

    console.log(data);
    return {...data.theme};
}

async function vote_theme(theme_id){
    const response = await fetch(app_server + `/theme/${theme_id}/vote/`,{
        method: "POST",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            count: 1
        }),
    });

    const data = await response.json();

    if(data.status == "success"){
        const theme = extra_data.theme_list.find((x) => x.theme_id == theme_id);
        if(theme.vote > 0){
            theme.vote = 0;
            theme.votes -= 1;
        }else{
            theme.vote = 1;
            theme.votes += 1;
        }
    }
}

function select_theme(theme){
    Object.assign(color_preferences, theme.theme_colors);
    for(var key in color_preferences){
        if(theme.theme_colors[key] == null){
            color_preferences[key] = null;
        }
    }
    if(confirm("Are you sure you want to select this theme? This will overwrite your current theme")){
        messageStore.alertUser("Selected Theme", 
            `Selected theme ${theme.name} as your current theme.`
        );
        save_theme();
    }
}

function get_share_url(theme_id){
    const url = new URL(`/theme/builder/?theme_id=${theme_id}`, window.location.href);
    return `/theme/builder/?theme_id=${theme_id}`;
}

async function share_theme(theme_id){
    if(window.isSecureContext){
        const url = new URL(`/theme/builder/?theme_id=${theme_id}`, window.location.href);
        await navigator.clipboard.writeText(url);
        messageStore.alertUser("Share", "Copied the share URL to the clipboard.");
    }
}

function save_theme(){
    for(let preference_key in color_preferences){
        userStore.updatePreference(
        'board', `color.${preference_key}`, color_preferences[preference_key])
    }
    extra_data.changed = false;
    userStore.savePreferences();
}

function get_user(){
    if(extra_data.theme_list.length > 0){
        return extra_data.theme_list[0].author_info;
    }
    return null;
}


async function next(delta){
    var new_offset = extra_data.offset + delta;

    new_offset = Math.min(new_offset, extra_data.total - page_size);
    new_offset = Math.max(0, new_offset);

    extra_data.offset = new_offset;

    await get_themes();
}

async function set_featured(featured){
    extra_data.offset = 0; 
    extra_data.featured = featured; 
    await next(0);
}

</script>
<template>
<Header />
    <div class="container mx-auto max-w-screen-xl">

<h2 class="text-4xl text-center my-12">
    OpenGammon Themes
</h2>
<div class="flex gap-x-3 my-2 px-2">
    <ButtonLoad 
        :fn="() => set_featured(1)"
        class="btn btn-blue"
        :disabled="extra_data.featured == 1"
    >
        Featured
    </ButtonLoad>
    <ButtonLoad 
        :fn="() => set_featured(0)"
        class="btn btn-blue"
        :disabled="extra_data.featured == 0"
    >
        All
    </ButtonLoad>
    <button class="btn btn-blue" 
        @click="router.push({name:'user-theme', params: {user_id: userStore.info.user_id}})"
    >
        My Themes
    </button>
    <button class="btn btn-blue" 
        @click="router.push({name:'theme-builder'})"
    >
        Theme Builder
    </button>
</div>

<div class="grid grid-cols-1 sm:grid-cols-2 gap-4 px-2">
<!-- THEME Picker-->
    <div v-for="theme in extra_data.theme_list" 
        class="flex flex-col"
    >
        <div class="w-full mb-4 cursor-pointer hover:shadow-xl"
            @click.stop="select_theme(theme)"
        >
            <Board 
                :boardID="theme.theme_id"
                :positionString="'11cccghhijjjjkk:44666688dddddlm:N0N:51:W:R:4:2:9:4'" 
                :can_do_actions="false"
                :theme="theme.theme_colors"
            />
        </div>
        <div  
            class="flex justify-between items-center gap-x-6 -mt-3 opacity-70 hover:opacity-100 transition-all">
            <router-link 
                class="flex gap-x-1 items-center"
                :to="{name:'user-theme', params: {user_id: theme.author_info.user_id}}"
            >
                <UserCircleIcon class="w-6 h-6" /> 
                <span>{{ theme.author_info.username }}</span>
            </router-link>
            <div class="flex gap-x-1 items-center hidden">
                <ClockIcon class="w-6 h-6" /> 
                <span>{{ new Date(theme.create_time*1000).toDateString() }}</span>
            </div>
            <div>
                <span>{{ theme.name }}</span>
            </div>
            <div class="flex gap-x-3">
                <div class="flex gap-x-1 items-center">
                    <span class="text-sm">{{ theme.votes }}</span>
                    <StarIcon
                        class="size-6 hover:text-accent-3-color cursor-pointer"
                        :class="{'fill-accent-3-color text-accent-3-color' : theme.vote > 0}"
                        @click="vote_theme(theme.theme_id)"
                    />
                </div>
                <ClipboardDocumentIcon
                    class="size-6 hover:text-accent-3-color cursor-pointer"
                    @click="share_theme(theme.theme_id)"
                />
                <ShareIcon
                    class="size-6 hover:text-accent-3-color cursor-pointer"
                    @click="router.push(get_share_url(theme.theme_id))"
                />
            </div>
        </div>
    </div>

</div>

<div class="flex justify-between w-full px-2 my-8">
    <ButtonLoad 
        class="btn btn-blue"
        :fn="() => next(-page_size)"
        :disabled="extra_data.offset <= 0"
    >
        Previous
    </ButtonLoad>
    <span>
        {{ extra_data.offset }} / {{ extra_data.total }} Themes
    </span>
    <ButtonLoad 
        class="btn btn-blue"
        :fn="() => next(page_size)"
        :disabled="extra_data.offset + page_size >= extra_data.total"
    >
        Next
    </ButtonLoad>
</div>

</div>
</template>


