<script setup>
import { reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { time_controls, time_control_to_text } from '../assets/js/timecontrols.js'
import { setPageTitle } from '../assets/js/titleManager.js';
import { MegaphoneIcon, BoltIcon, ChevronDoubleUpIcon } from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import MatchList from './MatchList.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import Countdown from './Countdown.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

const extra_data = reactive({
    offset:0,
    user_data: [],
    match_list: [],
    show_leaderboard: false,
    show_winlose: true,
    can_queue: true,
    can_signup: true,
    info: null
});

const tournament_data = reactive({
    join_tournament_id: null,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

onMounted(() => {
    reload();
    matchStore.setup();
});

function reload(){
    get_tournament_info();
}

async function get_tournament_info(){
    var url = app_server + `/arena/${tournament_id}/info/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        console.error("ERROR while loading match list", error);
        extra_data.message = "Please log in to create or join matches";
        return error;
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();

    extra_data.user_data = data.user_data;
    extra_data.info = data.info;
    extra_data.extra_info = data.extra_info;
    extra_data.times = data.times;
    extra_data.active = data.active;
    extra_data.can_queue = data.can_queue;
    extra_data.can_signup = data.can_signup;
    matchStore.users_queued = data.users_queued;

    if(data.matches){
        extra_data.match_list.push(...data.matches);
    }
    console.log(data);

    if (extra_data.info && extra_data.info.name) {
        setPageTitle(extra_data.info.name);
    }

    // candy specific
    if(extra_data.info.ranking_system == "candy"){
        extra_data.show_winlose = false;
    }
}

function get_tournament_times(){
    const times = extra_data.times.map( (start_stop) => {
        const start = (new Date(start_stop[0])).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        const stop =(new Date(start_stop[1])).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        const day = day_names[(new Date(start_stop[0])).getDay()];
        return `${start} - ${stop} (${day})`;
    });
    
    const unique_times = times.filter( (item, pos, ary) => {
        return !pos || item != ary[pos - 1];
    });

    return unique_times;
}

function to_timecontrol_text(time_control){
    const control = time_controls.match;
    let timecontrol = control.find((x) => x[1] == time_control);

    if(timecontrol == null){
        timecontrol = [time_control, time_control];
    }
    return timecontrol; 
}

const day_names = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const ranking_system_explanation = {
    "ladder": "You win points for winning the match. You win more points if you beat someone above you",
    "ladder-plus": "You win points for winning the match and lose points if you lose. You win more points if you beat someone above you.",
    "candy": "You start with a fixed amount of candy and play unlimited games with an exponentially increasing stake per period. If you can't pay the stake you are out."
}


function get_candy_bankroll(){
    if(extra_data.extra_info == null || extra_data.user_data == null){
        return 0;
    }
    if(!userStore.authenticated){
        return 0;
    }

    for(let user of extra_data.user_data){
        if(user.user.user_id  == userStore.info.user_id){
            return user.score;
        }
    }
    return extra_data.extra_info.initial_bankroll;
}

function join_chat_lobby(){
    const lobby = extra_data.info.name.replace(/\s/g, ''); 

    const chat = chatStore.join_lobby(lobby);

    if(chat != null){
        messageStore.alertUser("Joined Lobby", "You joined the tournament lobby.");
    }
}

</script>

<template>
<Header />
<Message />
<div class="flex flex-col md:flex-row gap-x-12 gap-y-8 md:h-rest">
    <div class="flex flex-col px-2 md:w-1/3">
        <!-- Arena Information -->
        <div v-if="extra_data.info" class="">
            <h1 class="text-4xl font-semibold mt-4 flex items-center gap-x-8">
                <span>{{ extra_data.info.name }}</span>
                <Tooltip text="Join the tournament lobby">
                <MegaphoneIcon 
                    class="size-6 transition opacity-60 hover:opacity-100 cursor-pointer" 
                    @click="join_chat_lobby()"
                />
                </Tooltip>
            </h1>
            <h2 class="text-lg mt-1 font-thin">
                Match Length: {{ extra_data.info.points }}
            </h2>
            <Tooltip :text="time_control_to_text(extra_data.info.time_control)">
                <h2 class="text-lg font-thin">
                    Time Control: {{ to_timecontrol_text( extra_data.info.time_control )[0] }} 
                </h2>
            </Tooltip>
            <Tooltip :text="ranking_system_explanation[extra_data.info.ranking_system]"
                direction="bottom">
                <h2 class="text-lg font-thin">
                    Ranking: {{ extra_data.info.ranking_system }} 
                </h2>
            </Tooltip>
            <!--- CANDY --->
            <div v-if="extra_data.extra_info != null && extra_data.info.ranking_system == 'candy'"
                 class="mt-4"
            >
                <h2 class="text-lg  font-thin">
                    Current stake: {{ extra_data.extra_info.current_stake }}
                </h2>
                <h2 class="text-lg  font-thin">
                    Next stake: {{ extra_data.extra_info.next_stake }}
                </h2>
                <h2 class="text-lg  font-thin">
                    Next round in: <Countdown :until="extra_data.extra_info.next_period_start" />
                </h2>
                <h2 class="text-lg  font-thin">
                    Your bankroll: 
                    <span :class="{
                        'font-medium text-accent-color': get_candy_bankroll() > extra_data.extra_info.current_stake,
                    }">
                        {{ get_candy_bankroll() }}
                    </span>
                </h2>
                

            </div>
            <h2 class="text-lg font-thin mt-4">
                <p class="font-normal">Upcoming time slots:</p>
                <p v-for="time in get_tournament_times()">
                    {{ time }}
                </p>
            </h2>
            <div v-if="extra_data.info.description && extra_data.info.description.length > 0"
                class="text-lg font-thin w-96 my-4 p-4 isolate bg-main-2-color rounded-sm shadow-md"   
            >
                <MarkdownRenderer 
                    :markdown="extra_data.info.description" 
                    :save="false" 
                />
            </div>
            <h2 class="text-lg font-semibold my-4">
                Users in Queue: {{ matchStore.get_users_queued(tournament_id) }}
            </h2>
            <button class="btn btn-blue mt-2 flex-grow w-80 h-10" 
                    :disabled="!matchStore.can_join_matchqueue || !extra_data.can_queue || !extra_data.can_signup"
                    @click="matchStore.queue_for_arena(tournament_id)"
            >
                Play
            </button>
        </div>
    
        <!-- Leaderboard -->
        <div class="w-full md:w-80 md:block">
            <span class="flex justify-between items-center">
                <h1 class="text-4xl font-semibold mt-4">Leaderboard</h1> 
                <button class="btn btn-blue md:hidden py-2 text-sm h-6" 
                        v-show="!extra_data.show_leaderboard"
                        @click="extra_data.show_leaderboard = true"
                >
                    Show
                </button>
                <button class="btn btn-red md:hidden text-sm"
                        v-show="extra_data.show_leaderboard"
                        @click="extra_data.show_leaderboard = false"
                >
                    Hide
                </button>
            </span>
            <table class="w-full h-full overflow-y-auto my-4" 
                   :class="{'table': extra_data.show_leaderboard, 'md:table hidden': !extra_data.show_leaderboard}">
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-left w-36">User</th>
                        <th>
                            <span v-show="extra_data.show_winlose">W/L</span>
                            <span v-show="!extra_data.show_winlose">Score</span>
                        </th>
                        <th v-if="extra_data.info && extra_data.info.status == 'finished'">
                            MP
                        </th>
                        <th>ER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user_data, index in extra_data.user_data">
                        <td class="">{{ user_data.rank }})</td> 
                        <td class="text-left text-elipsis">
                            <div class=" flex gap-x-2 items-center">
                            <UserName :user="user_data.user" />

                            <span v-if="user_data.streak >= 3">
                                <Tooltip :text="`Current Streak of ${user_data.streak}`"
                                        direction="bottom"
                                >
                                    <BoltIcon class="size-6" 
                                              :class="{
                                                    'text-field-med-color': user_data.streak >= 3 && user_data.streak < 5,
                                                    'text-field-color': user_data.streak >= 5 && user_data.streak < 10,
                                                    'text-brilliant-color': user_data.streak >= 10,
                                                }"
                                    />
                                </Tooltip>
                            </span>
                            <span v-if="user_data.rank_diff >= 3">
                                <Tooltip :text="`Quick riser, rose ${user_data.rank_diff}`"
                                        direction="bottom"
                                >
                                    <ChevronDoubleUpIcon class="size-6" 
                                              :class="{
                                                    'text-field-med-color': user_data.rank_diff >= 3 && user_data.rank_diff < 5,
                                                    'text-field-color': user_data.rank_diff >= 5 && user_data.rank_diff < 10,
                                                    'text-brilliant-color': user_data.rank_diff >= 10,
                                                }"
                                    />
                                </Tooltip>
                            </span>
                            </div>
                        </td>
                        <td class="text-center tabular-nums cursor-pointer" @click="extra_data.show_winlose ^= true">
                            <span v-show="extra_data.show_winlose">
                                {{ user_data.wins }}/{{user_data.losses }}
                            </span>
                            <span v-show="!extra_data.show_winlose">
                                {{user_data.score.toFixed(2)}}
                            </span>
                            </td>
                        <td v-if="extra_data.info && extra_data.info.status == 'finished'" class="text-right tabular-nums">
                            {{ user_data.master_points.toFixed(1) }}
                        </td>
                        <td class="text-right tabular-nums">{{ user_data.er.toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="grow md:h-full">
        <h1 class="text-4xl font-semibold mt-4 mb-4">Arena Matches</h1>
        <MatchList 
            :matchlist_url="`/arena/${tournament_id}/matches/`"
            :tournament_id="tournament_id"
        />
    </div>
</div>

<!--
<ul role="list" class="match-list divide-y divide-gray-100 topi mt-4">
    <button class="btn btn-blue mt-4 center" @click="reload_match_list()">Reload Matches</button>
    <div class="match order-first">{{ extra_data.message }}</div>
    <div class="match flex gap-x-4 py-5" v-for="match in match_list">
        {{ match.match.match_id }} - 
        {{ match.match.points }}gt
        
        <span v-if=match.info.white>
            {{ match.info.white.username }}
        </span>
        <span v-if=match.info.white>
            {{ match.info.white.rating }}
        </span> vs 
        <span v-if=match.info.black>
            {{ match.info.black.username }}
        </span>
        <button class="btn btn-blue"
                v-if="match.match.can_join"
                @click="join_match(match.match.match_id)">
            Join
        </button>
    </div>
</ul>
-->

</template>

