<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'


const extra_data = reactive({
    message: "Welcome join or create a match to start playing.",
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
    query:"",
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const user_list = reactive([]);
onMounted(() => {
    load_user_list();
});

async function load_user_list(){
    var url = app_server + "/admin/user/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`;
    }
    if(extra_data.query.length > 0){
        url += `?q=${extra_data.query}`;
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    }).then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error("Bad response from server");
        }
        return response;    
    }).catch(error => {
        extra_data.message = "Please log in to create or join matches";
        return error;
    });
    
    if(response instanceof Error){
        return;
    }
    
    const data = await response.json();
    user_list.length = 0;// empty the users list
    console.log(data);
    if(data.users){
        user_list.push(...data.users);
        extra_data.total_nrof_users = data.total_nrof_users;
    }
}

function next_page(){
    extra_data.offset += 20;
    load_user_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_user_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
<p>Total users: {{ extra_data.total_nrof_users }}</p>
<p>Offset: {{ extra_data.offset }}</p>
<input v-model="extra_data.query" type="text" />
<button class="btn btn-blue" @click="load_user_list()">Search</button>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Username</th> 
        <th scope="col" class="sticky top-0 z-10">Email</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">PRating</th> 
        <th scope="col" class="sticky top-0 z-10">Rating</th> 
        <th scope="col" class="sticky top-0 z-10">BRating</th> 
        <th scope="col" class="sticky top-0 z-10">Elo</th> 
        <th scope="col" class="sticky top-0 z-10">Exp</th> 
        <th scope="col" class="sticky top-0 z-10">PExp</th> 
        <th scope="col" class="sticky top-0 z-10">Date joined</th> 
        <th scope="col" class="sticky top-0 z-10">Last Login</th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="user_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No users
    </td>
    </tr>
    <tr v-for="user, index in user_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">
            {{ extra_data.offset + index + 1 }}.
        </td>
        <td class="text-left">
            <a href="#" v-if="user.user_id">
                <router-link :to="{name: 'admin-user-detail', params:{user_id: user.user_id}}">
                    {{ user.username }}
                </router-link>
                <span v-if="!user.is_active">- INACTIVE</span>
            </a>
            <span v-else> {{ user.username }} - NOID</span>
        </td>
        <td class="text-left">{{ user.email }}</td>
        <td class="text-left">{{ user.status }}</td>
        <td class="text-right">{{ user.puzzle_rating.toFixed(0) }}</td>
        <td class="text-right">{{ user.rating.toFixed(0) }}</td>
        <td class="text-right">{{ user.bot_rating.toFixed(0) }}</td>
        <td class="text-right">{{ user.elo.toFixed(0) }}</td>
        <td class="text-right">{{ user.experience }}</td>
        <td class="text-right">{{ user.puzzle_experience }}</td>
        <td class="text-center">{{ (new Date(user.date_joined)).toISOString().slice(0,10) }}</td>
        <td class="text-center">{{ (new Date(user.last_login * 1000)).toISOString().slice(0,10) }}</td>
        <td>{{ user.analysis_config }}</td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>
