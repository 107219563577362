import { defineStore } from 'pinia'

const app_server = import.meta.env.VITE_APP_SERVER;

import {useUserStore } from '@/stores/userstore.js'


export const useGameSettingStore = defineStore('gamesetting', {
    state: () => { return {
        settings: {
            sound: false,
            chat: true,
        },
    };},
    getters: {
    },
    actions:{
        async save_to_preferences(){
            const userStore = useUserStore();
            if(userStore.preferences.game == null){
                userStore.preferences.game = {};
            }

            for(const key in this.settings){
                userStore.updatePreference("game", key, this.settings[key]);
            }
            await userStore.savePreferences();
        },

        async load_preferences(){
            const userStore = useUserStore();
            await userStore.loadPreferences();

            if(userStore.preferences.game == null){
                return;
            }

            for(const key in this.settings){
                this.settings[key] = (
                    userStore.preferences["game"][key] || this.settings[key]) == true;
            }

            console.log(this.settings);
        }

    }
});


