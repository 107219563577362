<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {ArrowLeftIcon, UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import {BoardState} from '../assets/js/board.js'

import Header from './Header.vue'
import Message from './Message.vue'
import UserName from './UserName.vue'
import Avatar from './Avatar.vue'
import UserTag from './UserTag.vue'
import Board from './Board.vue'
import ButtonLoad from './ButtonLoad.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const actions = [
    "chat", "match", "login",
];

const translate_double_moves = {
    "EQ ND": "No Double",
    "EQ DT": "Double, Take",
    "EQ DP": "Double, Pass",
};

const durations = [
    ["1h", 3600],
    ["1d", 3600*24],
    ["3d", 3600*24*3],
    ["1w", 3600*24*7],
    ["1m", 3600*24*31],
    ["1y", 3600*24*365],
    ["1c", 3600*24*365*100],
];

const duration_lookup = Object.fromEntries(durations.map( ([a,b]) => [b,a]));
console.log(duration_lookup);

const extra_data = reactive({
    report: null,
    extra_data: null,
    reports: [],
    user: null,
    user_submitted: null,
    open_modal: false,
});

const timeout_data = reactive({
    reason: "",
    duration: 0,
    obj: null,
    user_id: "",
});

function go_to_report(report){ // COPIED from ModerateReports.vue
    if(report.object_type == "user"){
        router.push({name: 'mod-report-user', params: {report_id: report.report_id}});
    }else if(report.object_type == "match"){
        router.push();
    }else if(report.object_type == "puzzle"){
        router.push();
    }
}

watch( () => route.params.report_id, (newID, oldID) =>{
    get_report();
});


async function get_report(){
    const report_id = route.params.report_id;

    const response = await fetch(app_server + `/report/${report_id}/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    console.log(data);

    extra_data.report = data.report;
    extra_data.extra_data = data.extra_data;
    extra_data.reports = data.reports;
    extra_data.user_id = data.report.object_id;

    get_user_info(extra_data.report.submitter_id).then(x => extra_data.user_submitted = x);
}

async function get_user_info(user_id=null){
    if(user_id == null){
        user_id = extra_data.user_id;
    }

    const response = await fetch(app_server + `/user/${user_id}/info/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    
    const user = data.user; 
    user.date_joined = new Date(user.date_joined);

    console.log(data);

    return user
}

async function change_report_status(new_status){
    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/changestatus/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({'status': new_status}),
    });
    
    const data = await response.json();
    console.log("Changed status", data);
    get_report();
    messageStore.alertUser("Changed Status", "Succesfully changed the status to: "+new_status);
}

async function delete_puzzle(){
    if(!confirm("Are you sure you want to delete this puzzle? All solutions will be reverted!")){
        return;
    }
    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/deletepuzzle/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const data = await response.json();
    get_report();
    messageStore.alertUser("Deleted Puzzle", "Succesfully deleted the puzzle");
}

onMounted(async () => {
    await Promise.allSettled([
        userStore.loadUser(),
        get_report(),
    ])
});

function handleMove(){

}

</script>
<template>
    <Header/>
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-8 mb-32">
        <h1 class="flex gap-x-4 items-center text-4xl mt-8 mb-4 font-semibold">
            <a @click="router.go(-1)" class="hover:text-accent-3-color cursor-pointer">
                <ArrowLeftIcon class="size-8" />
            </a>
            Puzzle Report
        </h1>
        <div class="flex gap-x-4 w-full"
             v-if="extra_data.user != null"
        >
            <div class="flex flex-col gap-y-4"
            >
                <h3 class="text-lg font-medium">
                    <router-link :to="{name: 'user-info', params:{user_id: extra_data.user.user_id}}">
                        <UserName :user="extra_data.user" />
                    </router-link>
                </h3>
                <div class="flex gap-x-4">
                    <span class="flex items-center">
                        <FireIcon class="size-6" /> 
                        {{ extra_data.user.rating.toFixed(0) }}
                    </span>
                    <span class="flex items-center">
                        XP {{extra_data.user.experience.toFixed(0)}}
                    </span>
                </div>
            </div>
        </div>
        <div v-if="extra_data.report != null">
            <h2 class="text-2xl">Report</h2>
            <h3 class="text-xl">Status: {{ extra_data.report.status }}</h3>
            <h3 class="text-xl">Reason: {{ extra_data.report.reason }}</h3>
            <h3 class="font-light">Time: {{ (new Date(extra_data.report.submit_time*1000)).toISOString().slice(0,19) }}</h3>
            <h3 class="font-light" v-if="extra_data.report.status == 'closed'">
                Time Closed: {{ (new Date(extra_data.report.closed_time*1000)).toISOString().slice(0,19) }}</h3>
            <h3 class="font-light flex items-center" 
                v-if="extra_data.user_submitted != null"
            >
                <span>Submitted By: </span>
                <UserName :user="extra_data.user_submitted"/>
            </h3>
            <h3 class="font-light flex items-center" 
                v-if="extra_data.report.closed_by"
            >
                <span>Closed By: </span>
                <UserName :user_id="extra_data.report.closed_by"/>
            </h3>
            <div class="my-4">
                {{ extra_data.report.description }}
            </div>
            <ButtonLoad class="btn btn-blue mt-8 h-10"
                    :fn="() => change_report_status('closed')"
                    :disabled="extra_data.report.status == 'closed'"
            >
                Close Report
            </ButtonLoad>
        </div>

        <div v-if="extra_data.extra_data != null "
             class="flex flex-col gap-y-8"
             :set="puzzle=extra_data.extra_data.object"
        >
            <span class="text-xl font-semibold capitalize">Status: {{ puzzle.status }} </span>
            <div>
            <router-link class="block w-full"
                :to="{name:'puzzle', params:{puzzle_id: puzzle.puzzle_id}}">
                <Board :positionString="puzzle.position" 
                       :show_pip_numbers="true"
                       :show_away_default="true"
                       @move-end="handleMove"
                >
                </Board>
            </router-link>
            <div class="flex flex-col">
                <span>{{ new BoardState(puzzle.position).to_xgid() }}</span>
                <router-link :to="{name: 'position', params:{position_id: puzzle.position}}">
                    {{ puzzle.position }}
                </router-link>
            </div>
            </div>

            <div v-if="puzzle.analysis">
                <h2 class="text-lg font-semibold">Analysis</h2>
                <div v-if="puzzle.puzzle_type == 'double'">
                    <div
                        v-for="move, i in puzzle.analysis.double['cube recommendation'].actions"
                        :set="best_move = puzzle.analysis.double['cube recommendation'].best"
                    >
                    <div
                        class="flex justify-between gap-x-4 px-1" 
                    >
                        
                        <span class="grow">
                            {{ translate_double_moves[move[0]] }}
                        </span>
                        <span class="tabular-nums">
                            {{ move[1].toFixed(3) }} ({{ (move[2]).toFixed(3).padStart(6) }})
                        </span>
                    </div>
                    </div>
                </div>
                <div v-else 
                    class="flex justify-between gap-x-4 px-1" 
                    :set="best_move = puzzle.analysis.moves[0]"
                    v-for="move, i in puzzle.analysis.moves"
                >
                    <span>{{ move.ply }}</span>
                    <span class="text-left grow">{{ move.move.repr }}</span>
                    <span class="tabular-nums">
                        {{ move.EQ.toFixed(3) }} 
                        ({{ (move.EQ - best_move.EQ).toFixed(3).padStart(6) }})
                        <span>
                            [{{ extra_data.extra_data.solution_histogram[move.alt] || 0 }}]
                        </span>
                    </span>
                </div>
            </div>

            <div>
                <h2 class="text-lg font-semibold">Solves</h2>
                <table class="w-full">
                    <thead>
                        <th class="text-left">User</th>
                        <th class="text-right">EQ Lost</th>
                        <th class="text-right">Rating delta</th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                <tr v-for="solve in extra_data.extra_data.solves">
                    
                    <td>
                        <UserName :user="extra_data.user_submitted" 
                            v-if="extra_data.user_submitted && solve.user_id == extra_data.user_submitted.user_id" />
                        <router-link v-else
                            :to="{name: 'user-info', params:{user_id: solve.user_id}}"
                            class="font-mono"
                        >
                            {{ solve.user_id.slice(0,5) }}
                        </router-link>
                    </td>

                    <td class="text-right tabular-nums">
                        -{{ solve.eq_difference.toFixed(3) }}
                    </td>
                    <td class="text-right tabular-nums">
                        {{ solve.user_rating_diff.toFixed(2) }}
                    </td>
                </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="extra_data.extra_data.comments">
                <h2 class="text-lg font-semibold">Comments</h2>
                <table class="w-full">
                    <thead>
                        <th class="text-left">User</th>
                        <th class="text-right">Votes</th>
                        <th class="text-right">text</th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                <tr v-for="comment in extra_data.extra_data.comments">
                    
                    <td>
                        <UserName :user="extra_data.user_submitted" 
                            v-if="extra_data.user_submitted && 
                                  comment.user_id == extra_data.user_submitted.user_id" 
                        />
                        <router-link v-else
                            :to="{name: 'user-info', params:{user_id: comment.author_id}}"
                            class="font-mono"
                        >
                            {{ comment.author_id.slice(0,5) }}
                        </router-link>
                    </td>

                    <td class="text-right">
                        {{ comment.votes_up }}/
                        {{ comment.votes_down }}
                    </td>
                    <td class="text-right">
                        {{comment.comment.slice(0,40) }}
                    </td>
                </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="extra_data.extra_data.daily">
                <h2 class="text-lg font-semibold">Daily Puzzle</h2>
                <table class="w-full">
                    <thead>
                        <th class="text-left">Submitted by</th>
                        <th class="text-right">Solves</th>
                        <th class="text-right">Date</th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                <tr v-for="daily in extra_data.extra_data.daily">
                    
                    <td>
                        <UserName :user="daily.submitted_by" 
                            v-if="daily.submitted_by"
                        />
                        <span v-else>None</span>
                    </td>

                    <td class="text-right">
                        {{ daily.correct }}/
                        {{ daily.incorrect }}
                    </td>
                    <td class="text-right">
                        {{ new Date(daily.date * 1000).toDateString() }}
                    </td>
                </tr>
                    </tbody>
                </table>

            </div>
        </div>

        <div v-if="extra_data.report != null && extra_data.report.status == 'open'" class="flex flex-col gap-y-4">
            <h2 class="text-2xl">Delete Puzzle</h2>
                <ButtonLoad class="btn btn-blue h-12"
                            :fn="delete_puzzle"
                >
                    Delete Puzzle & Revert solutions
                </ButtonLoad>

        </div>

        <div>
            <h2 class="text-2xl">Recent Reports</h2>
            <div class="flex justify-between gap-x-8"
                 v-for="report in extra_data.reports"
            >
                <span class="grow hover:underline hover:text-field-color cursor-pointer" 
                    @click="go_to_report(report)"
                >
                    {{ report.reason || 'No Reason'}}
                </span> 
                <span>
                    {{ report.status}}
                </span> 
                <span>
                    {{ report.submitter_id.slice(0,5)}}
                </span> 
                <span class="font-light tabular-nums">
                    {{ (new Date(report.submit_time*1000)).toISOString().slice(0,19) }}
                </span>
            </div>
        </div>

    </div>
</template>

<style>
</style>

