<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var analyser_id;

const extra_data = reactive({
    selected_index: null,
});

const errorlogger_data = reactive({
    errors: [],
});

onMounted(() => {
    get_errorlogger();
});

async function get_errorlogger(){
    var url = app_server + `/errorlogger/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    errorlogger_data.errors = data.errors;
}

</script>

<template>
    <div class="container mx-auto">
        <h1>Errors</h1>
        <div v-for="error, index in errorlogger_data.errors"
            class="font-mono truncate"
        >
            <pre v-if="index == extra_data.selected_index" 
                 @click="extra_data.selected_index = null"
            >{{ JSON.stringify( JSON.parse(error), null, 4) }}</pre>
            <span v-else 
                @click="extra_data.selected_index = index"
                  class="text-sm"
            >
                {{ error }}
            </span>
        </div>
    </div>
</template>

<style>
</style>



