<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {BookOpenIcon, ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import Header from './Header.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();
var slug;

const props = defineProps({
});

const extra_data = reactive({
    dbs: [],
});


onMounted(() => {
    get_dbs();
});

watch( () => route.query, (new_query, old_query) =>{
    get_dbs();
});

async function get_dbs(){
    var url = app_server + `/position/db/`;
    if(router.query){
        const params = new URLSearchParams(router.query);
        url += params.toString();
    }

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    if(data.status == "success"){
        extra_data.dbs = data.position_dbs;
    }
    
    console.log("DBS:", extra_data.dbs);
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
    <h1 class="text-4xl font-semibold text-center mt-8 mb-12">
        Position Databases
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color py-4 px-8 group relative transition-all rounded-lg" 
            v-for="db in extra_data.dbs">
            <div class="grow">
                <router-link :to="{name: 'position-db', params:{db_id: db.db_id || db.user_id}}">
                    <h2 class="text-4xl mb-8 tracking-wide capitalize hover:text-accent-3-color">
                        {{ db.name || "Unknown DB" }}
                    </h2>
                </router-link>
                <div class="flex gap-x-4 mt-3 items-center font-light">
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(db.last_edited_time*1000).toDateString() }}</span>
                    </div>
                </div>
                <div class="mt-4 text-lg font-light overflow-y-hidden h-24 relative">
                    <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" />
                    {{ db.description }}
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>




