<script setup>
import {ref, computed, reactive, watch, defineEmits, onMounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {XMarkIcon, ChevronRightIcon, ChevronLeftIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    }
});

const extra_data = reactive({
    show: false,
});

const emit = defineEmits([
    'close',
    'open',
]);

watch( () => props.show, (post, prevPost) => {
    extra_data.show = props.show;  
});

function cancel(){
    extra_data.show = false;
    emit('close');
}


onMounted(() => {
    extra_data.show = props.show;  
});

</script>
<template>
<Teleport to="body">
<TransitionRoot as="template" :show="extra_data.show">
    <Dialog as="div" class="relative z-10" @close="cancel()">
      <TransitionChild as="template" enter="ease-out duration-300" 
            enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" 
            leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-main-1-color opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-top justify-center p-4 text-center items-center md:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" 
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" 
            enter-to="opacity-100 translate-y-0 sm:scale-100" 
            leave="ease-in duration-200" 
            leave-from="opacity-100 translate-y-0 sm:scale-100" 
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel 
                class="relative transform rounded-lg bg-main-1-color 
                        px-4 pb-4 pt-5 text-left shadow-xl transition-all 
                        sm:my-8 w-full sm:max-w-prose sm:p-6
                        flex flex-col 
                        "
            >
              <slot></slot>
              <XMarkIcon 
                    class="size-6 absolute top-4 right-4 hover:text-accent-3-color cursor-pointer" 
                    @click="cancel()"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</Teleport>
</template>

<style scoped>
</style>


