<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Match } from '../assets/js/match.js'

import ButtonLoad from './ButtonLoad.vue'
import Popup from './Popup.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const router = useRouter();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const emit = defineEmits([
    'connect',
    'abandon',
]);

const extra_data = reactive({
    show: false,
    is_abandoning: false,
    active_matches: [],
});

onMounted(() => {  
    check_for_active_match();
});

onUnmounted(() =>{ 
});

async function check_for_active_match(){
    if(localStorage.getItem("jwt") == null){
        extra_data.show = false;
        return;
    }

    // wait a bit before requesting the active matches
    await new Promise(r => setTimeout(r, 2000));

    const response = await fetch(app_server + "/user/match/",{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    extra_data.active_matches.length = 0;
    extra_data.active_matches.push(...data.active_matches);

    const active_matches = data.active_matches;
    if(active_matches.length > 0){
        extra_data.show = true;
        // localStorage.setItem("current_match", JSON.stringify(data.active_matches[0]));
    }
}

async function abandon_on_server(match_id){
    const url = app_server + `/match/${match_id}/abandon/`
    extra_data.is_abandoning = true;
    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });

    const data = await response.json();

    if(data.status == "error"){
        messageStore.alertUser("Could not abandon", data.message);
    }else{
        messageStore.alertUser("Success", "Redirecting to the post game screen");
    }


    extra_data.is_abandoning = true;
}

function abandon_match(){
    var match = extra_data.active_matches[0];
    if(match == null){
        messageStore.alertUser("Error", "No match to abandon");
        return;
    }
    emit('abandon');
    extra_data.show = true;
    abandon_on_server(match.match_id).then(() => {
        setTimeout(
            () => router.replace(
                {name:"post", params:{match_id: match.match_id}}), 
            1000
        );
    });
}

function ignore(){
    extra_data.show=false
}

function connect_to_match(){
    const match = extra_data.active_matches[0];

    if(match != null){
        router.replace({name:"match", params:{match_id: match.match_id}, query:{force:true}});
    }
    extra_data.show = false;
    // emit('connect');
}

</script>
<template>
    <Teleport to="body">
    <TransitionRoot as="template" :show="extra_data.show">
    <Dialog as="div" class="relative z-10" @close="">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <h1 class="text-center font-lg">Match in progress...</h1>
            <div class="w-full justify-center flex gap-x-4">
                <button class="btn btn-blue mt-2" 
                    @click="connect_to_match()"
                >
                    Connect 
                </button>
                <button class="btn btn-blue mt-2" 
                    @click="ignore()"
                >
                    Ignore
                </button>
                <ButtonLoad 
                    :countdown="5"
                    class="btn btn-danger mt-2" 
                    :fn="abandon_match"
                >
                    Abandon
                </ButtonLoad>
            </div>
        
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
    </Teleport>
</template>

<style scoped>
</style>
