<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {MagnifyingGlassIcon} from '@heroicons/vue/24/solid'

import UserName from './UserName.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const props = defineProps({

});

const emit = defineEmits([
    'selecteduser',
]);

const extra_data = reactive({
    loading: false,
    partial_username: "",
    users: [],
    show_input: false,
});

var timeout_id = null;

async function find_user(partial_username){
    clearTimeout(timeout_id);

    if(partial_username.length <= 1){
        return;
    }
    
    timeout_id = setTimeout(async () => {
        extra_data.loading = true;

        var url = app_server + `/user/find/?name=${partial_username}`;
        const response = await fetch(url, {
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        })    ;

        const data = await response.json();

        extra_data.users.length = 0;
        extra_data.users.push(...data.users);
        extra_data.loading = false;
    }, 800);
}

function select_user(user){
    extra_data.show_input = false;
    emit('selecteduser', user);
}

onMounted(async () => {
});

</script>
<template>
    <div class="flex text-base">
        <div class="flex flex-col items-center gap-x-2 relative">
            <div class="flex gap-x-1">
                <input v-model.trim="extra_data.partial_username" 
                    v-if="extra_data.show_input"
                    @input="find_user(extra_data.partial_username)"
                    class="h-10 grow"
                    placeholder="User..."
                />
                <MagnifyingGlassIcon 
                    class="size-6 btn btn-yellow !p-0.5 shrink-0"
                    @click="extra_data.show_input ^= true"
                />
            </div>

            <div class="absolute top-10 py-3 px-2 rounded-b 
                 min-h-12 inset-x-0 gap-y-2 bg-main-3-color z-40"
                 v-show="extra_data.show_input"
            >
                <div v-if="extra_data.users.length == 0">
                    No results...
                </div>
                <div v-for="user in extra_data.users"
                     @click="select_user(user)"
                     class="hover:bg-accent-2-color cursor-pointer bg-main-3-color "
                >
                    <UserName 
                        :user="user" 
                        :link_to_userview="false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>


