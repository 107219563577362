import { defineStore } from 'pinia'

import {useSSEStore } from '@/stores/ssestore.js'
import {useUserStore } from '@/stores/userstore.js'
import {useMessageStore } from '@/stores/messagestore.js'
import {useDailyStore } from '@/stores/dailystore.js'

import { Match } from '../assets/js/match.js'

var heartbeat_timeout = 60;

const app_server = import.meta.env.VITE_APP_SERVER;

export const useMatchStore = defineStore('match', {
    /* This store manages the join/accept cycle of matches (and match queue's)
     * and makes sure you cannot try to join multiple matches or tournaments
     * at the same time.
     */
    state: () => { return {
        joinrequests: {},
        joins: {},
        active_joins: {},

        join_status: null,
        current_joinrequest: null,
        current_matchqueue: null,

        current_join_counter_interval_id: null,
        current_join_counter: null,
        current_matchqueue_info: {},

        active_tournament: null,

        matchqueue_info: {},
        matchqueue_queued: {},

        match_types : ["sync", "async"],
        blocking_match_types: ["sync", "pvp"],

        timeout: false,

        join_origin: null,
    };},
    getters: {
    active_joinrequest(){
        return this.current_joinrequest;
    },
    youngest_matches(){
        const blocking = [];
        for(let match_type of this.blocking_match_types){
            blocking.push(...(this.joinrequests[match_type] || []));
        }
        if(blocking.length > 0){
            return blocking;
        }else{
            return null;
        }
    },

    get_joins(){
        const pending_joins = [];
        for(let match_type of this.blocking_match_types){
            if(this.joinrequests[match_type] == null){
                continue;
            }
            for(let match of this.joinrequests[match_type]){
                const joins = this.joins[match_type][match.match_id];
                if(joins == null || joins.length == 0){
                    continue;
                }

                for(let [join, join_time] of joins){
                    join.match_id = match.match_id;
                    join.match = match;
                    join.join_time = join_time;
                    join.wait_time = (
                        join.match.time_to_wait - (Date.now()/1000 - join_time)
                    ).toFixed(0)
                }
                pending_joins.push(...joins.filter(x => x[0].wait_time > 0));

            }
        }
        return pending_joins;
    },

    all_joinrequests(){
        return [... Object.values(this.joinrequests)];
    },

    can_join_match(){
        /* If this is false we cannot join a matchqueue or match */
        if(this.timeout){
            return false;
        }
        return this.current_matchqueue == null && this.current_joinrequest == null 
            && this.active_tournament == null;
    },

    can_join_matchqueue(){
        /* If this is false we cannot join a matchqueue or match */
        return this.can_join_match && this.youngest_match == null && this.active_tournament == null;
    },

    current_queue(){
        return this.current_matchqueue;
    },
    },
    actions:{

    setup(){
        this.current_joinrequest = localStorage.getItem("joinrequest"); 
        this.current_matchqueue = localStorage.getItem("current_matchqueue");
        this.active_tournament = localStorage.getItem("active_tournament");

        this.addListeners();

        for(let match_type of this.match_types){
            this.get_active(match_type);
        }

        this.get_matchqueue_info(this.current_matchqueue);

        if(this.current_matchqueue){
            this.queue_for_arena(this.current_matchqueue);
        }
        if(this.active_tournament != null){
            this.play_tournament(this.active_tournament);
        }
    },

    addListeners(){
        const sseStore = useSSEStore();
        const messageStore = useMessageStore();
        const dailyStore = useDailyStore();
        sseStore.connect();

        sseStore.addListener("join request", (data) => {
            // someone joined one of our matches 
            console.log(data);
            const join_requests = [];
            for(let [join, join_time] of data.requests){
                console.log(join);
                join.join_time = join;
            }
            this.joins[data.match.match_type][data.match_id] = data.requests;
            console.log("NEW JOIN", this.joins);
            messageStore.alertUser("New join request", 
                `${data.requests[0].username} is waiting for you.`, {
                showNotification: true,
                playSound: true
            });   
        });
        
        sseStore.addListener("join cancelled", (data) => {
            // someone joined one of our matches 
            console.log("Cancelled join request", data);

            if(data.tournament_id && (
                localStorage.getItem("current_matchqueue") == data.tournament_id ||
                localStorage.getItem("active_tournament") == data.tournament_id
            )){
                /* If we are in a tournament queue, cancel */
                localStorage.removeItem("joinrequest");
                localStorage.removeItem("current_matchqueue");
                localStorage.removeItem("active_tournament");
                this.join_status = "ready";
                this.current_matchqueue = null;
                this.active_tournament = null;
                this.stop_join_counter();

                messageStore.alertUser("Matchmaking Cancelled", 
                    `Matchmaking has been cancelled, you can re-join the queue.`, {
                    showNotification: true,
                    timeToLive: 1000*60*3,
                });   

                return;
            }
            console.log(data);
            const match_type = data.match.match_type;

            if(this.joinrequests[match_type] != null){
                console.log("updating joins");
                this.joins[match_type][data.match_id] = data.joins;
            }
            console.log(this.joins);

            if(!this.blocking_match_types.includes(match_type)){
                return;
            }

            if(localStorage.getItem("joinrequest") == data.match_id){
                localStorage.removeItem("joinrequest");
                localStorage.removeItem("current_matchqueue");
                this.join_status = "ready";
                this.current_joinrequest = null;
                this.current_matchqueue = null;
                this.stop_join_counter();
            }
            
        });
        
        sseStore.addListener("join accepted", (data) => {
            // someone joined one of our matches 
            console.log("Accepted", data);

            var match_type = data.joinrequest.match_type;
            if(match_type == "pvp"){
                match_type = "sync";
            }
            if(!this.blocking_match_types.includes(match_type)){
                if(match_type == "async"){
                    dailyStore.get_token(data.joinrequest.match_id);
                    messageStore.alertUser("Match Joined", 
                        "Someone joined your daily match", {
                            showNotification: true,
                            url: {name: 'daily', 
                                  params:{match_id: data.match_id}},
                        });
                }
                return;
            }

            localStorage.removeItem("joinrequest"); 
            localStorage.removeItem("current_matchqueue");
            localStorage.removeItem("active_tournament");

            this.current_joinrequest = null;
            this.current_matchqueue = null;
            this.active_tournament = null;

            this.stop_join_counter();
            this.join_status = "joined";
            
            if(this.join_origin == window.tabId){ 
                setTimeout(() => { 
                    this.router.replace({
                        name:"match", 
                        params:{match_id: data.match_id}
                    });
                }, 2000);
                messageStore.alertUser("Match Joined", 
                    "The match will start soon.", {showNotification: true}); 
            }
        });

        sseStore.addListener("tournament join", (data) => {
            // someone joined one of our matches 
            console.log("Someone joined/left the tournament", data);
            this.matchqueue_queued[data.tournament_id] = data.users_queued;

            // messageStore.alertUser("User joined tournament", 
            //     `A user joined the ${extra_data.info.name} tournament`, {
            //     showNotification: true,
            //     playSound: true
            // });   
        });
    },

    get_joinrequest(match_id){
        console.log(this.joinrequests, Object.values(this.joinrequests));
        for(let joinrequest of this.all_joinrequests){
            console.log(joinrequest);
            if(joinrequest.match_id == match_id){
                return joinrequest;
            }
        }
        return null;
    },

    async create_joinrequest(match_arguments){
        console.log("ARGS", match_arguments);
        const response = await fetch(app_server + "/matchmaking/", {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(match_arguments),
        });
        
        const data = await response.json();

        if(data.status == "error"){
            const messageStore = useMessageStore();
            if(data.code == 403){
                messageStore.alertUser("Play Timeout", 
                    "You currently cannot play matches due to a timeout.");
                this.timeout = true;
            }

            if(data.message){
                messageStore.alertUser("Failed", data.message);
            }
        }

        return data;
    },

    async join_joinrequest(match_id){
        const messageStore = useMessageStore();

        const data = {
            match_id: match_id,
        };
        
        const response = await fetch(app_server + `/matchmaking/${match_id}/join/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
        
        const request_data = await response.json(); 
        
        if(request_data.status == "error"){
            if(data.code == 403){
                const messageStore = useMessageStore();
                messageStore.alertUser("Play Timeout", 
                    "You currently cannot play matches due to a timeout."
                );
                this.timeout = true;
            }else{
                messageStore.alertUser("Error", request_data.message); 
            }
            return;
        }    
        
        messageStore.alertUser("Join success", 
            "Join request success, waiting for accept", 5000); 
        console.log("join join", request_data);
        if(this.blocking_match_types.includes(request_data.joinrequest.match_type)){
            localStorage.setItem("joinrequest", match_id);
            this.join_status = "joining";
            this.join_origin = window.tabId;
            this.current_joinrequest = match_id;
            this.start_join_counter()
        }
        return;
    },

    async cancel_join(){
        const messageStore = useMessageStore();
        const match_id = this.current_joinrequest;

        const response = await fetch(app_server + `/matchmaking/${match_id}/cancel/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify({}), 
        });
        
        const request_data = await response.json(); 
        
        if(request_data.status == "error"){
            console.log("ERROR Joining", request_data.message);
        }    
        messageStore.alertUser("Join cancelled", "Join request cancelled", 5000); 

        var match_type = "sync";
        var joinrequest_match_id = null;
        if(request_data.joinrequest){
            match_type = request_data.joinrequest.match_type;
            joinrequest_match_id = request_data.joinrequest.match_id;
        }

        if(!this.blocking_match_types.includes(match_type)){
            if(joinrequest_match_id != this.current_joinrequest){
                console.log("Not removing");
                return;
            }
        }

        this.join_status = "ready"
        this.current_joinrequest = null;
        this.stop_join_counter();

        localStorage.removeItem("joinrequest");
        
        return;
    },

    async join_accept(match_id, user_id){
        const messageStore = useMessageStore();

        const data = {
            match_id: match_id,
            opponent_id: user_id,
        };

        const response = await fetch(app_server + `/matchmaking/${match_id}/accept/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
        
        const request_data = await response.json(); 

        if(request_data.status == "success"){
            console.log("JOIN ACCEPTED", request_data);
            this.join_origin = window.tabId;
        }else{
            if(data.code == 403){
                const messageStore = useMessageStore();
                messageStore.alertUser("Play Timeout", "You currently cannot play matches due to a timeout.");
                this.timeout = true;
            }
        }
    },

    async join_reject(match_id, user_id){
        const messageStore = useMessageStore();

        const response = await fetch(app_server + `/matchmaking/${match_id}/reject/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify({
                match_id: match_id,
                opponent_id: user_id,
            }), 
        });
        
        const data = await response.json(); 
        
        if(data.status == "error"){
            console.log("ERROR Joining", data.message);
        }    
        messageStore.alertUser("Join rejected", 
            "Join request rejected, they cannot join again", 5000); 
    },

    async get_active(match_type="sync"){
        const response = await fetch(app_server + `/matchmaking/active/?match_type=${match_type}`, {
            method: "GET",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        })        

        const data = await response.json();
        if(data.status == "success"){
            this.joinrequests[match_type] = data.joinrequests;  
            this.joins[match_type] = data.joins;
            this.active_joins[match_type] = data.active_joins;

            if(this.blocking_match_types.includes(match_type) && data.active_joins.length > 0){
                const match_id = data.active_joins[0];
                localStorage.setItem("active_join", match_id);
                this.join_status = "joining"
                this.current_joinrequest = match_id;
                console.log("ACTIVE JOIN", data);
            }
        }else{
            if(data.code == 403){
                const messageStore = useMessageStore();
                this.timeout = true;
            }
            
        }
    },

    async remove_joinrequest(match_id){
        const messageStore = useMessageStore();

        const data = {}
        const response = await fetch(app_server + `/matchmaking/${match_id}/remove/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(data), 
        });
         
        // remove the match from the DOM
        for( let [key, val] of Object.entries(this.joinrequests)){
            this.joinrequests[key] = val.filter(x => x.match_id != match_id);
        }
        delete this.joins[match_id];

        const request_data = await response.json(); 
        
        if(request_data.status == "error"){
            console.error("error deleting match", match_id, request_data.message);
        }    
        
        messageStore.alertUser("Match deleted", "");

        return;
    },

    /* arena methods */
    async get_matchqueue_info(tournament_id){
        if(tournament_id == null){
            return;
        }
        var url = app_server + `/arena/${tournament_id}/info/`;

        const response = await fetch(url,{
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        if(response.status == "error"){
            return;
        }
        
        const data = await response.json();
        this.current_matchqueue_info = data.info;
        this.matchqueue_info[tournament_id] = data.info;
    },

    async queue_for_arena(tournament_id, heartbeats=30){
        if(tournament_id == null){
            return;
        }
        if(this.timeout){
            return;
        }
        this.get_matchqueue_info(tournament_id);
        this.start_matchqueue_counter();
        await this.join_arena(tournament_id);
        for(let i=0; i < heartbeats; i++){
            if(this.current_matchqueue == null){
                clearInterval(this.current_join_counter_interval_id);
                return;
            }
            await new Promise(r => setTimeout(r, heartbeat_timeout*1000));
            if(!await this.send_heartbeat()){
                break;
            }
        }
        console.log(`Join timed out after ${heartbeats} heartbeats`);
        if(this.current_matchqueue != null){
            this.cancel_arena_join();
        }
    },

    async join_arena(tournament_id){
        if(this.timeout){
            return;
        }
        const response = await fetch(app_server + `/arena/${tournament_id}/join/`, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const data = await response.json(); 
        if(data.status == "error"){
            console.error(data.message);
            clearInterval(this.current_join_counter_interval_id);
            this.current_join_counter = null;
            this.join_status = "ready";

            const messageStore = useMessageStore();
            if(data.code == 403){
                messageStore.alertUser("Play Timeout", "You currently cannot play matches due to a timeout.");
                this.timeout = true;
            }else{
                messageStore.alertUser("Failed to join", "You are currently playing in a match.");
            }
            return;
        }
        
        if(data.status == "success"){ 
            this.matchqueue_queued[tournament_id] = data.users_queued;
            this.join_status = "joining";
            this.join_origin = window.tabId;

            this.current_matchqueue = tournament_id;
            localStorage.setItem("current_matchqueue", tournament_id);
            return;
        }
    },

    async send_heartbeat(){
        if(this.timeout){
            return false;
        }
        if(this.current_matchqueue == null || this.join_status != "joining"){
            console.error("Cannot send heartbeat to:", this.current_matchqueue);
            return false;
        }
        const url = `/arena/${this.current_matchqueue}/heartbeat/`;
        const response = await fetch(app_server + url, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        const data = await response.json();
        // console.log("HEARTBEAT:", data);
        if(data.status == "success"){
            this.matchqueue_queued[data.tournament_id] = data.users_queued;
            return true;
        }else{
            console.log("Heartbeat error, cancelling");
            await this.cancel_arena_join();
            return false;
        }
    },

    async cancel_arena_join(){
        const messageStore = useMessageStore();
        if(this.current_matchqueue == null){
            console.error("Tried cancelling a non-active matchqueue");
            return;
        }
        const data = await fetch(app_server + `/arena/${this.current_matchqueue}/cancel/`, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        this.join_status = "cancelling";
    },

    // Tournament Methods
    async play_tournament(tournament_id){
        if(this.timeout){
            return;
        }
        const response = await fetch(app_server + `/tournament/${tournament_id}/join/`, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const data = await response.json(); 
        if(data.status == "error"){
            console.error(data.message);
            clearInterval(this.current_join_counter_interval_id);
            this.current_join_counter = null;
            this.join_status = "ready";

            const messageStore = useMessageStore();
            if(data.code == 403){
                messageStore.alertUser("Play Timeout", 
                    "You currently cannot play matches due to a timeout."
                );
                this.timeout = true;
            }else{
                messageStore.alertUser("Failed to join", 
                    "You are currently playing in a match."
                );
            }
            return;
        }
        
        if(data.status == "success"){ 
            this.active_tournament = tournament_id;
            this.join_status = "joining";
            this.join_origin = window.tabId;

            localStorage.setItem("active_tournament", tournament_id);
            this.start_matchqueue_counter();
            return;
        }
    },

    async cancel_play_tournament(){
        const response = await fetch(app_server + `/tournament/${this.active_tournament}/cancel/`, {
            method: "POST",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const data = await response.json(); 
        
        if(data.status == "error"){ // An error occured, cancel the join
            messageStore.alertUser("Error Cancelling", data.message);
        }

        this.active_tournament = null;
        localStorage.removeItem("active_tournament");
    },

    start_join_counter(){
        document.onvisibilitychange = () => {
            if(window.hidden){
                alert("You are queuing, are you sure you want to leave?");
            }
        };

        if(this.current_join_counter_interval_id != null){
            console.log("current join counter already open");
            return;
        }
        if(this.current_join_counter == null){
            this.current_join_counter = 60;
            this.current_join_start = Date.now();
        }
        this.current_join_counter_interval_id = setInterval(() => {
            this.current_join_counter = (60 - (Date.now() - this.current_join_start)/1000).toFixed(0);
            if(this.current_join_counter < 0){
                this.cancel_join();
                this.current_join_counter = 0;
                this.stop_join_counter();
            }
        }, 1000);
    },

    start_matchqueue_counter(){
        if(this.current_join_counter_interval_id != null){
            return;
        }
        if(this.current_join_counter == null){
            this.current_join_counter = 0;
            this.current_join_start = Date.now();
        }
        this.current_join_counter_interval_id = setInterval(() => {
            this.current_join_counter = ((Date.now() - this.current_join_start)/1000).toFixed(0);
        }, 1000);
    },

    stop_join_counter(){
        if(this.current_join_counter_interval_id != null){
            clearInterval(this.current_join_counter_interval_id);
            this.current_join_counter = null;
            this.current_join_counter_interval_id = null;
        }
    },

    get_users_queued(tournament_id){
        return this.matchqueue_queued[tournament_id] || 0;
    },
    }
});

