<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {ChatBubbleBottomCenterTextIcon, ChatBubbleBottomCenterIcon, CpuChipIcon, 
    TrashIcon, SparklesIcon, UserCircleIcon, FireIcon, CheckIcon, PencilSquareIcon, 
    ShareIcon} from '@heroicons/vue/24/solid'

import {BoardState} from '../assets/js/board.js'

import Header from './Header.vue'
import Board from './Board.vue'
import ButtonLoad from './ButtonLoad.vue'
import Tooltip from './Tooltip.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();


const app_server = import.meta.env.VITE_APP_SERVER;

const router = useRouter();
const route = useRoute();

var slug;

const props = defineProps({
    rated: true,
});

const extra_data = reactive({
    db_id: "user",

    positions: [],
    tags: [],
    requests: [],
    info: {},
    category: "recent",
    
    offset: 0,
    selected_tags: [], //['prime', 'checker'],

    show_tag_id: null,
    tag_inputs: {},

    show_comment_id: null,

    editing: false,
});

const db_data = reactive({

});

onMounted(() => {
    
    if(route.params.db_id){
        extra_data.db_id = route.params.db_id;
    }
    get_positions();
    console.log(extra_data.db_id);
});

async function get_positions(){
    const get_parameters = [
        ["offset", extra_data.offset],
        ...extra_data.selected_tags.map( (tag) => ["tags", tag]),
    ];
    const search_params = new URLSearchParams(get_parameters)
    

    var url = app_server + `/position/db/${extra_data.db_id}/?${search_params.toString()}`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const position_data = await response.json();
    if(position_data.status == "success"){
        extra_data.positions = position_data.positions;
        extra_data.tags = position_data.tags;
        extra_data.info = position_data.info;
    }
    
    console.log(position_data);
}

async function edit_position(position){
    var url = app_server + `/position/db/${extra_data.db_id}/${position.p_id}/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(position),
    });
    
    const position_data = await response.json();
    return position_data.status == "success"
    console.log(position_data);
}

async function edit_db(){
    var url = app_server + `/position/db/${extra_data.db_id}/`;

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(extra_data.info),
    });
    
    const data = await response.json();
    if(data.status == "success"){
        messageStore.alertUser("Success", "Edited the position DB information");
        extra_data.editing = false;
    }else{
        messageStore.alertUser("Error", "Could not change information: " + data.message);
    }
}

async function delete_position(position_id){
    const response = await fetch(app_server + `/position/db/${extra_data.db_id}/${position_id}/`, {
        method: "DELETE",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    
    if(data.status == "success"){
        messageStore.alertUser("Success", "Removed the position from the DB");
        await get_positions();
    }else{
        messageStore.alertUser("Error", "Could not remove the position from the DB");
    }
}

function get_color(boardstate){
    const board = new BoardState(boardstate);

    return board.opponent[board.color];
}

function handle_add_tag(position){
    if(extra_data.show_tag_id == null){
        extra_data.show_tag_id = position.p_id;
        return;
    }
    const tag_value = extra_data.tag_inputs[position.p_id];
    if(tag_value.length < 2){
        return;
    }

    // We now have a tag that is filled in

    position.tags.push(tag_value);
    
    const success = edit_position(position);
    
    extra_data.show_tag_id = null;
    extra_data.tag_inputs[position.p_id] = "";

    if(success){
        messageStore.alertUser("Added Tag", "The tag got added to the position.");
    }
}

function handle_rem_tag(position, tag){
    const index = position.tags.indexOf(tag);
    if(index > -1){
        position.tags.splice(index, 1);
    }
    
    edit_position(position);
}

function toggle_tag(tag){
    const index = extra_data.selected_tags.indexOf(tag);
    if(index >= 0){
        extra_data.selected_tags.splice(index, 1);
    }else{
        extra_data.selected_tags.push(tag);
    }
    if(extra_data.selected_tags.length > 3){
        extra_data.selected_tags.shift();
    }
    get_positions();
}

function handle_comment(position){
    if(extra_data.show_comment_id == null){
        extra_data.show_comment_id = position.p_id;
        return;
    }

    const success = edit_position(position)
    extra_data.show_comment_id = null;

    if(success){
        messageStore.alertUser("Saved comment", "The comment is saved to the position.");
    }
}

function can_edit(){
    return userStore.info.user_id == extra_data.info.user_id;
}

async function copy_to_clipboard(text){
    if(text == null){
        await router.replace({name: "position-db", params: {db_id: extra_data.info.db_id}});
        text = window.location.href;
    }

    console.log(text);
    if(window.isSecureContext){
        await navigator.clipboard.writeText(text);
        messageStore.alertUser("Share", "Puzzle URL has been copied to your clipboard.");
    }
}


</script>

<template>
    <Header />
    <div class="container mx-auto flex flex-col items-center">
        <div class="text-center mt-8 flex gap-x-12 items-center mb-4">
            <span v-if="!extra_data.editing" class="text-4xl font-semibold">
                {{ extra_data.info.name || "Position DB" }}
            </span>
            <div v-else
                 class="gap-x-4 flex items-center"
            >
            <input type="text"
                v-model="extra_data.info.name"
                placeholder="Position DB name"
            />
            <Tooltip text="Can this DB be found in the public DB list?">
                <select v-model="extra_data.info.listed">
                    <option :value="1">Listed</option>
                    <option :value="0">Unlisted</option>
                </select>
            </Tooltip>
            </div>

            <div class="flex gap-x-2">
            <div v-if="can_edit()">
                <PencilSquareIcon v-if="!extra_data.editing" 
                    class="size-8 hover:text-accent-3-color cursor-pointer" 
                    @click="extra_data.editing = true"
                />
                <div v-else class="flex gap-x-2">
                <ButtonLoad 
                    class="btn btn-blue"
                    :fn="edit_db"
                >
                    Save
                </ButtonLoad>
                <button class="btn btn-yellow"
                    @click="extra_data.editing = false">
                    Cancel
                </button>
                </div>
            </div>
            <Tooltip text="Copy the link to this database to your clipboard">
                <ShareIcon
                    class="size-8 hover:text-accent-3-color cursor-pointer" 
                    @click="copy_to_clipboard()"
                    />
            </Tooltip>
            </div>

        </div>
        <div class="w-full max-w-prose text-center">
            <MarkdownRenderer v-if="!extra_data.editing"
                :markdown="extra_data.info.description" 
                :save="true"
            />
            <textarea v-else
                v-model="extra_data.info.description"
                class="w-full"
            ></textarea>
        </div>
        <div class="flex gap-x-1 gap-y-1 flex-wrap max-w-prose">
            <span 
                v-for="count, tag in extra_data.tags"
                class="flex items-center gap-x-1 rounded px-2 py-0.5
                  bg-accent-2-color cursor-pointer hover:bg-accent-3-color"
                :class="{'bg-accent-3-color': extra_data.selected_tags.includes(tag)}"
                @click="toggle_tag(tag)"
            >
            {{ tag }} <span class="text-sm font-light">({{ count }})</span>
            </span>
        </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8"
    >
        <div class="box py-4 px-4 relative transition-all" 
            v-for="position in extra_data.positions">
            <div class="flex w-full aspect-[5/4] md:aspect-auto mt-4 text-lg font-thin relative">
                <Board 
                    :boardID="position.p_id"
                    :positionString="position.p_id"
                    :player_color="get_color(position.p_id)" 
                    :can_do_actions="false"
                    :show_away_default="true"
                 class="grow"
                />
            </div>
            <div class="font-thin flex gap-x-4 items-center justify-between w-full mt-3" 
                v-if="position.tags"
            >
                <div class="flex gap-x-1 flex-wrap gap-y-1">
                    <span class="flex items-center gap-x-4 rounded px-2 py-0.5 
                          bg-accent-2-color transition group relative"
                        v-for="tag in position.tags"
                    >
                        {{ tag }}
                        <span 
                            class="text-2xl group-hover:visible hidden duration-700 
                            transition group-hover:inline-block
                            cursor-pointer
                            "
                            v-if="can_edit()"
                            @click="handle_rem_tag(position, tag)"
                        >
                            -
                        </span>
                    </span>
                    <span 
                        class="rounded bg-accent-2-color 
                                cursor-pointer hover:bg-accent-3-color 
                                flex gap-x-2 items-center"
                        v-if="can_edit()"
                    >
                        <input type="text" 
                            v-model="extra_data.tag_inputs[position.p_id]"
                            v-if="extra_data.show_tag_id == position.p_id"
                            class="!bg-accent-2-color !border-0 !py-0 !h-8"
                            @keydown.enter="handle_add_tag(position)"
                        >
                        <span class="text-xl text-center px-2"
                            @click="() => handle_add_tag(position)"
                        >
                        +
                        </span>
                    </span>
                </div>


                <div class="flex gap-x-2">
                <Tooltip text="Edit position comment"
                         v-if="can_edit() || position.comment"
                >
                    <ButtonLoad 
                        :fn="() => extra_data.show_comment_id = 
                             (extra_data.show_comment_id == position.p_id) ? 
                                null : position.p_id"
                    >
                        <ChatBubbleBottomCenterIcon 
                            v-if="!position.comment"
                            class="size-6 btn-blue rounded p-0.5 inline-block 
                             cursor-pointer" 
                        />
                        <ChatBubbleBottomCenterTextIcon 
                            v-else
                            class="size-6 btn-blue rounded p-0.5 inline-block 
                             cursor-pointer" 
                        />
                    </ButtonLoad>
                </Tooltip>
                <Tooltip text="Analyse position in the analyser"
                >
                    <router-link :to="{name: 'position', params:{position_id: position.p_id}}">
                        <CpuChipIcon 
                            class="size-6 btn-blue rounded p-0.5 inline-block 
                             cursor-pointer" 
                        />
                    </router-link>
                </Tooltip>
                <Tooltip text="Remove from DB"
                         v-if="can_edit()"
                >
                    <ButtonLoad
                            :fn="() => delete_position(position.p_id)"
                            :replace="false"
                    >
                        <TrashIcon 
                            class="size-6 btn-blue rounded p-0.5 inline-block 
                             cursor-pointer" 
                        />
                    </ButtonLoad>
                </Tooltip>
                </div>
            </div>

            <div
                class="my-4"
                v-if="position.p_id == extra_data.show_comment_id"
            >
                <textarea 
                    class="w-full border-0 border-b-2 focus:border-accent-3-color"
                    v-model="position.comment"
                    v-if="can_edit()"
                ></textarea>
                <div v-else>
                    {{ position.comment }}
                </div>
                <div class="flex gap-x-2"
                    v-if="can_edit()"
                >
                    <ButtonLoad 
                        class="btn btn-blue"
                        :fn="() => handle_comment(position)"
                    >
                        Save Comment
                    </ButtonLoad>
                    <button 
                        class="btn btn-yellow"
                        @click="extra_data.show_comment_id = null"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <div class="absolute top-2 right-2 px-2 py-1 rounded 
                        bg-accent-3-color text-light-color font-semibold"
                 v-if="position.added && position.added > (Date.now()/1000 - 3600*24)"
            >
                new
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>


