// Beginner
import {MartinBot} from './bots/martin.js'
import {LisaBot} from './bots/lisa.js'
import {TysonBot} from './bots/tyson.js'
import {BillieBot} from './bots/billie.js'
import {LolaBot} from './bots/lola.js'
// Intermediate
import {TobiasBot} from './bots/tobias.js'
import {PatrickBot} from './bots/patrick.js'
import {AlexBot} from './bots/alex.js'
//Engine
import {GnuBot} from './bots/gnubot.js'
import {BGBlitzBot} from './bots/bgblitzbot.js'
import {WildBGBot} from './bots/wildbgbot.js'

import {Match} from './match.js'
import {StateMachine} from './statemachine.js'
import {BoardState} from './board.js'

const evaluator_bot = {
    null: MartinBot,
    // Beginner 
    "martin": MartinBot,
    "lisa": LisaBot,
    "tyson": TysonBot,
    "billie": BillieBot,
    "lola": LolaBot,
    // Intermediate 
    "tobias": TobiasBot,
    "patrick": PatrickBot,
    "alex": AlexBot,
    // Engine
    "gnu": GnuBot,
    "bgblitz": BGBlitzBot,
    "wildbg": WildBGBot,
};

export class Bot{
    constructor(bot_id, options){
        this.bot_id = bot_id;
        this.bot_color = options.bot_color || "B";
        this.bot = new evaluator_bot[options.evaluator || "martin"](options.bot_config);
        this.match = new Match(this.bot_color, this.bot.match_length, options.initial_state);
        this.match.player_color = this.bot_color;
        this.start_play_timeout_id = null;

        this.state_machine = new StateMachine();
        this.state_machine.player_color = this.bot_color;
        this.state_machine.initial_board = new BoardState(options.initial_state);
        this.state_machine.roll_dice_callback = (dice, move_counter, distinct) => this.match.get_dice(dice, move_counter, distinct);

        console.log("EVALUATOR:", this.bot_color, this.bot, this.state_machine);

        this.player_event_func = (e) => {
            e.stopImmediatePropagation(); //
            try{
                this.on_message(e.detail);
            } catch(error){
                window.reportError(error);
            }
        };

        this.delay = options.delay || 600;
        this.recv_channel = options.recv_channel || "player-message";
        this.send_channel = options.send_channel || "bot-message";

        this.show_premoves = options.show_premoves || true;

        this.send_chat = (game_event, board) => {}; //console.log(game_event);

        this.messages_sent = 1000;
        this.last_move_id = 0;
        // this.delay = 0;

        // console.log(this);

        if(options.bot_config.match != null){
            this.delay *= (options.bot_config.match.speed || 1);
        }

        // register the message handler
        this.remove_eventlistener();
        document.addEventListener(this.recv_channel, this.player_event_func);
    }

    async start_play(initial_board){
        if(initial_board == null){
            return;
        }
        if(this.start_play_timeout_id != null){
            return;
        }
        if((initial_board.game_state == "IW" && this.bot_color == initial_board.color) || 
           (initial_board.game_state != "IW" && this.bot_color != initial_board.color)
        ){
            await this.do_bot_action(initial_board);
            this.start_play_timeout_id = null;
            // this.start_play_timeout_id = setTimeout(async () => {
            //     await this.do_bot_action(initial_board);
            //     this.start_play_timeout_id = null;
            // }, 1500);
        }    
    }

    handle_analysis(analysis_data){
        if(this.bot.handle_analysis != undefined){
            return this.bot.handle_analysis(analysis_data);
        }
    }

    remove_eventlistener(){
        document.removeEventListener(this.recv_channel, this.player_event_func);
    }

    sendMessage(positionString, type="move", extra_data=null){
        // send the move to the other player
        // console.log("SENDING", this.bot_color, positionString, type);
        // this.messages_sent -= 1; // DEBUG 

        if(this.messages_sent <= 0){
            console.log("Stopping due to messages_sent limit");
            return;
        }

        let signature = this.match.sign_position(positionString);
        const data = {
            type: type, 
            match_id: this.match.match_id, 
            state: positionString,
            signature: signature,
            extra_data: extra_data,
        };
        this.match.push_state(new BoardState(positionString), signature);

        console.debug("BOT SEND:", positionString, Date.now());
        
        document.dispatchEvent(new CustomEvent(this.send_channel, {
            detail: data
        }));
    }

    sendPremoves(premove_data){
        const data = {
            type: "premove", 
            match_id: this.match.match_id, 
            premoves: premove_data,
        };
        document.dispatchEvent(new CustomEvent(this.send_channel, {
            detail: data
        }));
    }

    async do_bot_action(board){
        if(board.game_state != "IW" && board.color == this.bot_color){
            // console.log("No action", board.game_state, board.color, this.bot_color);
            return null;
        }

        var action = null;
        const old_board = board.copy();

        if(board.game_state != "IW" && board.color != this.bot_color){
            action = await this.bot.find_move(board);
        }
        console.log(action)

        // console.log("BOT", this.bot_color, old_board.toPositionString(), action);
        let moves = [];
        if(typeof action == "object" && action != null){
            const action_cpy = action.copy();
            moves = old_board.getMove(action, true);
        }
        if(moves.data != null && Array.isArray(moves.data) && this.show_premoves){
            moves = moves.data.map( (m) => [
                m[0], Math.abs(m[1] - m[0]), board.opponent[board.color]], moves
            );
            await new Promise(resolve => setTimeout(resolve, this.delay + 50));
            for(var move_index in moves){
                move_index = parseInt(move_index);
                this.sendPremoves(moves.slice(0, move_index+1));
                await new Promise(resolve => setTimeout(resolve, this.delay + 50));
            }
        }
        
        if(moves.length > 0){
            this.send_chat("checker", board);
        }else if(action){
            this.send_chat(action, board);
        }

        await new Promise(resolve => setTimeout(resolve, this.delay + 50));

        return await this.handleMove(board.toPositionString(), action);
    }

    async on_message(data){
        if(data.type == "error"){
            this.sendMessage(data);
        }

        if(data.type != "move"){
            return;
        }

        let board = new BoardState(data["state"]);

        if(board.move_id < this.last_move_id){
            return;
        }

        this.last_move_id = board.move_id;
        this.match.push_state(board, data["signature"]);

        const new_boardstate = await this.do_bot_action(board);

        if(new_boardstate == null && board.game_state == "IB"){
            this.sendMessage(board.toPositionString());
        }
        else if(new_boardstate != null && ["G", "M"].includes(new_boardstate.game_state)){
            console.log("GAME", board, new_boardstate);
            // this.sendMessage(new_boardstate.toPositionString());
        }
        else if(new_boardstate != null && ["IW"].includes(new_boardstate.game_state)){
            this.sendMessage(new_boardstate.toPositionString());
        }
    }

    async handleMove(positionString, action=null){
        const boardstate = new BoardState(positionString);
        
        var new_boardstate = this.state_machine.next_state(boardstate, action);     

        if(new_boardstate == null && action != null){
            return;
        }
        // console.log("HANDLE", this.bot_color, positionString, new_boardstate);
        if(new_boardstate != null){
            if(new_boardstate.game_state == "IB" && 
                new_boardstate.color != this.state_machine.player_color)
            {
                this.sendMessage(new_boardstate.toPositionString());
            }
            else if(new_boardstate.game_state == "F"){
                const extra_data = this.match.to_json().player;
                this.sendMessage(new_boardstate.toPositionString(), "final", extra_data);
            }else{
                this.sendMessage(new_boardstate.toPositionString());
            }
        }else if(new_boardstate == null && boardstate.game_state == "IW"){
            this.sendMessage(boardstate.toPositionString());
        }else{
            // this.sendMessage(boardstate.toPositionString());
        }

        return new_boardstate;
    }
};
