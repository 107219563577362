<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    offset: 0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const status_options = [];

const releasenote_list = reactive([]);
onMounted(() => {
    load_releasenote_list();
});

async function load_releasenote_list(){
    var url = app_server + "/admin/releasenote/";

    const get_parameters = {
        offset: extra_data.offset,
        search: extra_data.search,
    };
    const search_params = new URLSearchParams(get_parameters)

    
    const response = await fetch(url,{// + "?" + search_params.toString(),{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    
    
    const data = await response.json();
    releasenote_list.length = 0;// empty the users list

    if(data.notes){
        releasenote_list.push(...data.notes);
    }
    console.log(data);
}

async function remove_releasenote(note_id){
    return;
    const response = await fetch(app_server + `/admin/releasenote/${note_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_releasenote_list();
}

async function create_releasenote(){
    const response = await fetch(app_server + `/admin/releasenote/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_releasenote_list();

}

function next_page(){
    extra_data.offset += 20;
    load_releasenote_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_releasenote_list();
}

function get_status(note){
    if(note.show_until_time == 0){
        return "inactive";
    } 
    else if(note.show_from_time*1000 < Date.now() && Date.now() < note.show_until_time*1000){
        return "showing";
    }
    else if(note.show_until_time*1000 < Date.now()){
        return "finished";
    }else{
        return "pending";
    }
}

</script>

<template>
<div class="flex-col">
<div class="flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

<div class="pl-6">
    <div class="my-4">
        <button class="btn btn-blue"
                @click="create_releasenote()"
        >
            + New Release Note
        </button>
    </div>


<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <!--<th scope="col" class="sticky top-0 z-10">ID</th> -->
        <th scope="col" class="sticky top-0 z-10">Title</th> 
        <th scope="col" class="sticky top-0 z-10">Author</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Show Time</th> 
        <th scope="col" class="sticky top-0 z-10">Clicks</th> 
        <th scope="col" class="sticky top-0 z-10">Views</th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="releasenote_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No release notes
    </td>
    </tr>
    <tr v-for="releasenote, index in releasenote_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'admin-releasenote-detail', 
                               params:{releasenote_id: releasenote.note_id}}"
            >
            {{ releasenote.title }}
            </router-link>
        </td>
        <td class="text-left">{{ releasenote.author.slice(0, 8) }}</td>
        <td class="text-left">{{ get_status(releasenote) }}</td>
        <!-- <td class="text-left">{{ releasenote.path }}</td> -->
        <td class="text-left">
            {{ new Date(releasenote.show_from_time*1000).toISOString().split(".")[0] }}
        </td>
        <td class="text-right tabular-nums">
            {{ releasenote.clicks }}
        </td>
        <td class="text-right tabular-nums">
            {{ releasenote.views }}
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>

