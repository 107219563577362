export function create_random_boardstate(){
    const pips = "123456789abcdefghijklmno";
    const half_board = [0,0,0,0,0,0,0,0,0,0,0,0];
    var checkers_left = 15;

    while(checkers_left > 0){
        const index = Math.floor(Math.random() * 12);
        const nrof_checkers = Math.min(checkers_left, // This evals to 1 or 2
            Math.floor(Math.random() * 1 + 1) + 1
        );
        //const nrof_checkers = Math.min(checkers_left, 2);

        var color = (Math.random() < 0.5) ? 1 : -1;

        if(half_board[index] != 0){
            color = Math.sign(half_board[index]);
        }

        console.log(nrof_checkers, checkers_left, color);

        half_board[index] += color * nrof_checkers
        checkers_left -= nrof_checkers
    }
    
    var position_w = "";
    var position_b = "";

    for(const index in half_board){
        const val = half_board[index];
        if(val > 0){
            position_w += pips[index].repeat(Math.abs(val));
            position_b += pips[23 - index].repeat(Math.abs(val));
        }else if(val < 0){
            position_w += pips[23 - index].repeat(Math.abs(val));
            position_b += pips[index].repeat(Math.abs(val));
        }
    }
    
    const postfix = "N0N::W:IW:0:0:1:0";
    
    return `${position_w}:${position_b}:${postfix}`;
}
