export function debounce(fn, wait, max_calls=null){
    let timer = null;
    let nrof_calls = 0;
    if(wait == null){
        wait=500;
    }
    return (...args) => {
        if(max_calls != null && nrof_calls > max_calls){
            clearTimeout(timer); // clear any pre-existing timer
            return; 
        }

        if(timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        timer = setTimeout( () => {
            fn.apply(this, args); // call the function if time expires
            nrof_calls += 1;
        }, wait);
    }
}

