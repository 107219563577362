<script setup>
/*
*/
import Board from './Board.vue'
import ButtonLoad from "./ButtonLoad.vue"
import Header from './Header.vue'
import Report from './Report.vue'
import Tooltip from './Tooltip.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

import {BoardState} from '../assets/js/board.js'
import { StateMachine } from '../assets/js/statemachine.js'
import {ref, watch, computed, reactive, onMounted} from 'vue'

import { useRoute, useRouter } from 'vue-router'

import {QuestionMarkCircleIcon, CalendarIcon, CpuChipIcon, CheckCircleIcon, XCircleIcon, PuzzlePieceIcon, ShareIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const state = reactive({
    current_question_index: -1,
    number_of_retries: 0,
    result: null,
    question: {
        correct: true,
    },
});

const extra_data = reactive({
    load_finish:false,
});

const lesson_data = reactive({
});

const clock_data = reactive({
    clock: {},
    clock_config: {},
});


var lesson_slug = undefined;
var course_slug = null;

onMounted(async () => {
    lesson_slug = route.params.slug;
    course_slug = route.query.course;
    await get_lesson(lesson_slug);
});

async function get_lesson(slug){
    const response = await fetch(app_server + `/lesson/slug/${ slug }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },

    });
    const data = await response.json();
    Object.assign(lesson_data, data.lesson);

    console.log(data);

    return data.lesson;
}

async function handleMove(positionString, action=null){
    const state_machine = new StateMachine();
    state_machine.player_color = get_board_color();
    state_machine.roll_dice_callback = () => [];

    var new_board = state_machine.next_state(new BoardState(positionString), action);

    console.log(positionString);
    console.log(new_board.toPositionString());
    state.question.correct = new_board.toPositionString() == state.question.solution;
}

function get_move(){
    const alt_played = extra_data.solve_info.alt_played;
    if(extra_data.puzzle_info.puzzle_type == "checker" && alt_played >= 0){
        const move = extra_data.puzzle_info.analysis.moves.find( x => x.alt == alt_played)
        if(move){
            return move.move.repr;
        }else{
            return "No move";
        }
    }else if(extra_data.puzzle_info.puzzle_type == "double"){
        const double_solutions = {
            "ND" : "No Double",
            "DT" : "Double, Take",
            "DP" : "Double, Pass",
        };
        return double_solutions[extra_data.solve_info.solution];
    }else{
        if(extra_data.solve_info.solution && extra_data.puzzle_info.position){
            const bs = new BoardState( extra_data.puzzle_info.position);
            return bs.getMove(new BoardState(extra_data.solve_info.solution)).text;
        }   
    }

    return "No Move" 
}

function next(){
    state.current_question_index += 1;
    if(state.current_question_index < lesson_data.questions.length){
         state.question = lesson_data.questions[state.current_question_index];
         state.question.correct = null;
    }
}
function retry(){
    state.number_of_retries += 1;
    state.question.correct = null;
}

async function finish(){
    extra_data.load_finish=true;
    const response = await fetch(app_server + `/lesson/${ lesson_data.lesson_id }/finish/`, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            nrof_retries: state.number_of_retries,
        }),

    });
    const data = await response.json();

    state.result = data;

    console.log("FINISH", data);
    extra_data.load_finish=false;
}

function get_board_color(){
    const board = new BoardState(state.question.position);
    return board.opponent[board.color];
}

function is_last(){
    if(lesson_data.questions){
        return lesson_data.questions.length <= state.current_question_index;
    }
    return false;
}

</script>

<template>
<Header />
<div class="container mx-auto h-rest flex-col relative">
    <div class="flex flex-col gap-x-8 md:flex-row md:relative h-full">
        <div class="flex w-full aspect-[5/4] md:aspect-auto md:w-2/3 md:h-rest relative">
            <Board :positionString="state.question.position" 
                   :player_color="get_board_color()" 
                   :clock_data="clock_data"
                   :arrows="extra_data.arrows"
                   :show_pip_numbers="true"
                   :key="state.number_of_retries"
                   @move-end="handleMove"
             class="grow"
            >
            </Board>
        </div>
        <div class="px-2 md:px-4 grow md:grow-0 md:w-1/2 pb-64 overflow-y-auto
                    flex flex-col gap-y-12 text-xl leading-relaxed
                "
        >
            <h1 class="text-4xl">
            {{ lesson_data.title }}
            </h1>

            <div v-if="state.current_question_index < 0" 
                class="min-h-64">
                <MarkdownRenderer :markdown="lesson_data.introduction" :save="true"/>
            </div>
            <div v-else-if="state.current_question_index < lesson_data.questions.length"
                 class=""
            >
                <div v-if="state.question.correct == null"
                    class="min-h-64"
                >
                    <MarkdownRenderer :markdown="state.question.introduction" :save="true"/>
                </div>

                <div v-else-if="state.question.correct == false"
                    class="min-h-64"
                >
                    <div class="flex items-center text-2xl gap-x-4 mb-8">
                        <XCircleIcon class="w-10 h-10 text-stone-b-color"/>
                        Try again!
                    </div>
                    <MarkdownRenderer :markdown="state.question.hint" :save="true"/>
                </div>

                <div v-else-if="state.question.correct == true"
                    class="min-h-64 border-accent-3-color"
                >
                    <div class="flex items-center text-4xl gap-x-4 mb-8">
                        <CheckCircleIcon class="size-12 text-field-color"/>
                        Correct!
                    </div>
                    <MarkdownRenderer :markdown="state.question.explanation" :save="true"/>
                </div>
            </div>
            <div v-else>
                <MarkdownRenderer :markdown="lesson_data.conclusion" :save="true"/>
                <div v-if="state.result != null"
                    class="flex flex-col gap-y-12 items-center my-16">
                    <span class="text-4xl">Congratulations!</span>
                    <span class="flex justify-center">
                        <svg v-for="i in Array(state.result.stars).keys()"
                             class="w-10 h-10 text-yellow-300 stroke-case-color stroke-2 ms-1" 
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" 
                             fill="currentColor" viewBox="0 0 22 20">
                             <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                    </span>

                    
                    <router-link v-if="course_slug == null"
                        :to="{name: 'lesson-list'}">
                        <button class="btn btn-blue h-12">
                            Continue!
                        </button>
                    </router-link>
                    <router-link v-else
                        :to="{name: 'course', params:{slug: course_slug}}">
                        <button class="btn btn-blue h-12">
                            Continue!
                        </button>
                    </router-link>
                </div>
            </div>
            
            <div class="flex justify-end gap-x-2"
                 v-if="state.result == null"
            >
                <button class="btn btn-blue h-10"
                    v-if="state.question.correct == false"
                    @click="retry()"
                >
                    Retry 
                </button>
                <span
                    v-if="is_last()"
                    class="w-full"
                >
                    <ButtonLoad class="btn btn-blue h-10 w-1/3"
                        :loading="extra_data.load_finish"
                        @click="finish()"
                    >
                        Finish
                    </ButtonLoad>
                </span>
                <button class="btn btn-blue h-10 w-1/3"
                    :disabled="!state.question.correct"
                    @click="next()"
                    v-else
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.game{
    height:100vh;
    height:100svh;
    width:100vw;
}
</style>

