<script setup>
import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { UserCircleIcon, PencilSquareIcon, ClockIcon, ShareIcon, TrashIcon} from '@heroicons/vue/24/outline'

import Header from './Header.vue'
import Board from './Board.vue'

import { Match } from '../assets/js/match.js'
import {BoardState} from '../assets/js/board.js'
import {StateMachine } from '../assets/js/statemachine.js'

const app_server = import.meta.env.VITE_APP_SERVER;

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

let update_analysis_timeout_id = null;

const state = reactive({
    current_board: new BoardState(),
    match_info: {},
    clock_data: {},
});

const extra_data = reactive({
    user_themes: [],
});

const color_preferences = reactive({
});

onMounted(async () => {
    get_themes(route.params.user_id).then( (themes) => {
        extra_data.user_themes = themes;
    });
});

onUnmounted(() => {
});

async function get_themes(user_id){
    const url = `/theme/user/${user_id}/`;
    const response = await fetch(app_server + url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    console.log("Themes", data);
    return data.themes
}

async function get_theme(theme_id){
    const response = await fetch(app_server + `/theme/${theme_id}/`,{
        method: "GET",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();

    console.log(data);
    return {...data.theme};
}

function select_theme(theme){
    Object.assign(color_preferences, theme.theme_colors);
    for(var key in color_preferences){
        if(theme.theme_colors[key] == null){
            color_preferences[key] = null;
        }
    }
    if(confirm("Are you sure you want to select this theme? This will overwrite your current theme")){
        messageStore.alertUser("Selected Theme", 
            `Selected theme ${theme.name} as your current theme.`
        );
        save_theme();
    }
}

function get_share_url(theme_id){
    return `/theme/builder/?theme_id=${theme_id}`;
}

async function share_theme(theme_id){
    if(window.isSecureContext){
        const url = new URL(`/theme/builder/?theme_id=${theme_id}`, window.location.href);
        await navigator.clipboard.writeText(url);
        messageStore.alertUser("Share", "Copied the share URL to the clipboard.");
    }
}

function save_theme(){
    for(let preference_key in color_preferences){
        userStore.updatePreference(
        'board', `color.${preference_key}`, color_preferences[preference_key])
    }
    extra_data.changed = false;
    userStore.savePreferences();
}

function get_user(){
    if(extra_data.user_themes.length > 0){
        return extra_data.user_themes[0].author_info;
    }
    return null;
}


</script>
<template>
<Header />
    <div class="h-rest container mx-auto max-w-screen-xl">
<h2 class="text-4xl text-center my-12">
    <span v-if="get_user() != null">
        {{ get_user().username }}'s
    </span>
    Themes
</h2>
<div class="grid grid-cols-1 sm:grid-cols-2 gap-4 px-2">
<!-- THEME Picker-->
        <div v-for="theme in extra_data.user_themes" 
            class="flex flex-col"
        >
            <div class="w-full mb-4 cursor-pointer hover:shadow-xl"
                @click.stop="select_theme(theme)"
            >
                <Board 
                    :boardID="theme.theme_id"
                    :positionString="'11cccghhijjjjkk:44666688dddddlm:N0N:51:W:R:4:2:9:4'" 
                    :can_do_actions="false"
                    :theme="theme.theme_colors"
                />
            </div>
            <div  
                class="flex justify-between items-center gap-x-6 -mt-3 opacity-70 hover:opacity-100 transition-all">
                <div class="flex gap-x-1 items-center">
                    <UserCircleIcon class="w-6 h-6" /> 
                    <span>{{ theme.author_info.username }}</span>
                </div>
                <div class="flex gap-x-1 items-center hidden">
                    <ClockIcon class="w-6 h-6" /> 
                    <span>{{ new Date(theme.create_time*1000).toDateString() }}</span>
                </div>
                <div>
                    <span>{{ theme.name }}</span>
                </div>
                <div class="flex gap-x-3">
                    <PencilSquareIcon
                        class="size-6 hover:text-accent-3-color cursor-pointer"
                        @click="router.push({name:'theme-builder', args:{theme_id: theme.theme_id}})"
                    />
                    <ShareIcon
                        class="size-6 hover:text-accent-3-color cursor-pointer"
                        @click="share_theme(theme.theme_id)"
                    />
                </div>
            </div>
    </div>
</div>
</div>
</template>

