<script setup>
import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {ArrowLeftIcon, ComputerDesktopIcon, ShareIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Board from './Board.vue'
import ButtonLoad from './ButtonLoad.vue'
import Tooltip from './Tooltip.vue'
import CheckBox from './CheckBox.vue'
import PositionAdd from './PositionAdd.vue'

import { Match } from '../assets/js/match.js'
import {BoardState} from '../assets/js/board.js'
import {StateMachine } from '../assets/js/statemachine.js'

const app_server = import.meta.env.VITE_APP_SERVER;

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore, useMatchSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();
const matchStore = useMatchSSEStore();

const route = useRoute();
const router = useRouter();

let update_analysis_timeout_id = null;

const dice_unicode = [
    [1, "&#9856;"],
    [2, "&#9857;"],
    [3, "&#9858;"],
    [4, "&#9859;"],
    [5, "&#9860;"],
    [6, "&#9861;"],
];

const engine_configs = [
    ["gnubg", ["1ply", "2ply", "3ply"/*, "4ply", "puzzle"*/]],
    // ["bgblitz", ["1ply"]],
    // ["wildbg", ["1ply"]],
];

const state = reactive({
    current_board: new BoardState(),
    board: new BoardState(),
    board_message: "",
    match_info: {},
    clock_data: {},
    edit_mode: false,
    cube_roll: false,
});

var analysis_data = reactive({
    analysis: null,
    analysis_id: null,
    win_probabilities: null,
    config: "1ply",
    engine: "gnubg",
    started_analysis: false,
    analysis_time: 0.0,
    total_analysis_time: 0.0,
});

onMounted(async () => {
    await userStore.loadUser();
    sseStore.addListener("analysis", handle_analysis);
    sseStore.connect();
    document.addEventListener("paste", handle_paste);
    
    state.board = new BoardState(route.params.position_id);
    if(["A", "P", "D"].includes(state.board.game_state)){
        state.board.game_state = "C"; // TODO
        state.board.cube.action= "N";
    }
    if(state.board.game_state == "C"){
        state.cube_roll = true;
    }
    updateCurrentBoard()

    userStore.loadPreferences().then(() => {
        for(const elem of document.getElementsByClassName("themed")){
            userStore.applyBoardPreferences(elem);
        }
    });
});

onUnmounted(() => {
    document.removeEventListener("paste", handle_paste);
});

function handle_paste(e){
    e.preventDefault();

    let position_id = (event.clipboardData || window.clipboardData).getData("text");

    if(position_id.startsWith("XGID=")){
        state.board = new BoardState();
        state.board.from_xgid(position_id);
    }else{
        try{
            state.board = new BoardState(position_id);
        }catch(error){
            console.log(error);
        }
    }
}

function handle_analysis(data){
    console.log("Analysis received:", data);
    analysis_data.analysis = data;
    if(data.checker.moves){
        analysis_data.win_probabilities = data.checker.moves[0];
    }
    analysis_data.analysis_id = data.analysis_id;
    analysis_data.started_analysis = false;
    analysis_data.analysis_time = (data.checker.analysis_time || 0) + (data.cube.analysis_time || 0) ;
    analysis_data.total_analysis_time += analysis_data.analysis_time;
}

async function getPositionAnalysis(position_id, move_played=null, config=[]){
    if(analysis_data.started_analysis){
        return;
    }
    getPositionTags(position_id);
    const response = await fetch(app_server + `/position/${ position_id }/analysis/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "move_played": move_played,
            "engine_id": config[0],
            "config": config[1],
        }),
    });
    const data = await response.json();
    console.log("Started analysis request", analysis_data);
    analysis_data.started_analysis = true;
    
    if(data.status == "error"){
        return;
    }
    return data;
}

async function getPositionTags(position_id){
    const response = await fetch(app_server + `/position/${ position_id }/classify/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    
    if(data.status == "success"){
        analysis_data.tags = data.tags;
    }
    console.log("Classifier: ", data);
}

function get_playercolor(){
    return state.board.opponent[state.board.color];
}

function get_cube_options(){
    const standard_options = [0,1,2,3,4,5,6];
    if(state.board.match_length == 0){
        if(! standard_options.includes(state.board.cube.value)){
            standard_options.push(state.board.cube.value);
        }
        return standard_options.map((x) =>  [x, 2**x]);
    }else{
        return standard_options.filter((x) => 2**(x-1) < state.board.match_length).map( (x) => [x, 2**x]);
    }
}

async function handleMove(positionString, action=null){
    if(state.edit_mode){
        state.board = new BoardState(positionString);
        updateCurrentBoard(); 
        return;
    }
    const state_machine = new StateMachine();
    state_machine.player_color = get_playercolor();
    state_machine.roll_dice_callback = () => [];
    
    const position = new BoardState(positionString)

    var solution = null;
    var new_board = state_machine.next_state(position, action);

    if(new_board.game_state == "PR" || new_board.game_state == "C" ){
        new_board.game_state = "R";
        new_board.dice[0] = 1;
        new_board.dice[1] = 2;
    }
    
    state.board = new_board;
    updateCurrentBoard(); 
    router.replace({name: "position", params:{position_id: new_board.toPositionString()}});
}

function updateAnalysis(){
    const engine_config_dict = Object.fromEntries(engine_configs);
    if(!engine_config_dict[analysis_data.engine].includes(analysis_data.config)){
        analysis_data.config = engine_config_dict[analysis_data.engine][0];
    }
    if(update_analysis_timeout_id){
        clearTimeout(update_analysis_timeout_id);
    }
    update_analysis_timeout_id = setTimeout(() => {
        getPositionAnalysis(state.current_board.toPositionString(), null, [analysis_data.engine, analysis_data.config]);
    }, 1500);
}

function updateCurrentBoard(){
    state.current_board = state.board.copy();
    router.replace({name: "position", params:{position_id: state.current_board.toPositionString()}});
    updateAnalysis();
}

async function copy_xgid_to_clipboard(){
    const xgid = state.current_board.to_xgid();
     
    if(window.isSecureContext){
        await navigator.clipboard.writeText(xgid);
        messageStore.alertUser("Share", "Copied XGID to clipboard.");
    }
}
async function copy_ogid_to_clipboard(){
    const ogid = state.current_board.toPositionString();
     
    if(window.isSecureContext){
        await navigator.clipboard.writeText(ogid);
        messageStore.alertUser("Share", "Copied OGID to clipboard.");
    }
}

function can_double(board){
    const state_machine = new StateMachine();
    state_machine.player_color = get_playercolor();
    const b = board.copy();
    b.game_state = "C";
    return state_machine.can_double(b);
}

function set_cube_roll(){
    if(state.cube_roll){// we want cube analysis   
        state.board.game_state = "C";
    }else{
        state.board.game_state = "R";
    }
    updateCurrentBoard();
}

async function share_url(){
    if(window.isSecureContext){
        const url = window.location.href;
        await navigator.clipboard.writeText(url);
        messageStore.alertUser("Share", "Copied the URL to the clipboard.");
    }
}

async function addPositionToDB(position_id){
    const response = await fetch(app_server + `/position/db/user/add/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "position_id": position_id,
        }),
    });
    const data = await response.json();
    
    if(data.status == "success"){
        messageStore.alertUser("Success", "Added the position to the DB");
    }else{
        messageStore.alertUser("Error", "Could not add the position to the DB: "+ data.message);
    }
}


</script>
<template>
<Header />
<div class="h-rest flex flex-col md:flex-row">
<div class="flex grow md:w-2/3 md:h-rest relative">
    <Board :positionString="state.board.toPositionString()" 
       :player_color="get_playercolor()" 
       :score="state.score"
       :clock_data="state.clock_data"
       :board_message="state.board_message"
       :match_info="state.match_info"
       :show_chat="false"
       :show_resign="false"
       :direction="'CW'"
       :can_do_actions="true"
       :edit_mode="state.edit_mode"
       @move-end="handleMove"
       class="grow"
    >
    </Board>
</div>
<div class="md:w-1/3 px-2 flex flex-col-reverse md:flex-col  gap-y-4 h-rest overflow-y-auto">
<div>
<h1 class="text-2xl font-bold my-4 flex items-center gap-x-2">
    <ArrowLeftIcon 
        class="size-6 hover:text-field-color cursor-pointer" 
        @click="router.go(-1)"
                
    />
    Position Setup
</h1>
<div class="flex flex-col gap-y-4">
    <label class="flex gap-x-2 items-center cursor-pointer" 
        v-if="true || can_double(state.board)"
    >
        <span class="">Cube</span>
        <CheckBox v-model="state.cube_roll" 
                  @change="set_cube_roll()" 
        />
        <span class="">Roll</span>
    </label>

    <div>
    <div class="mb-2">
        On Roll:
    </div>
    <label class="themed flex gap-x-4">
        <span class="rounded-full bg-stone-w-color border-case-color border w-6 h-6 inline-block cursor-pointer" 
              :class="{'ring': state.board.color == 'B'}"
              @click="state.board.color = 'B'; updateCurrentBoard();"
        />
        <span class="rounded-full bg-stone-b-color border-case-color border w-6 h-6 inline-block cursor-pointer"
              :class="{'ring': state.board.color == 'W'}"
              @click="state.board.color = 'W'; updateCurrentBoard();"
        />
    </label>
    </div>

    <div>
    <div class="mb-2">
        Cube:
    </div>
    <label class="themed flex gap-x-4 items-center">
        <span class="rounded-full bg-stone-w-color border-case-color border w-6 h-6 inline-block cursor-pointer" 
              :class="{'ring': state.board.cube.owner == 'W'}"
              @click="state.board.cube.owner = 'W'; updateCurrentBoard();"
        />
        <span class="rounded-full bg-case-light-color border-case-color border w-6 h-6 inline-block cursor-pointer" 
              :class="{'ring': state.board.cube.owner == 'N'}"
              @click="state.board.cube.owner = 'N'; updateCurrentBoard();"
        />
        <span class="rounded-full bg-stone-b-color border-case-color border w-6 h-6 inline-block cursor-pointer"
              :class="{'ring': state.board.cube.owner == 'B'}"
              @click="state.board.cube.owner = 'B'; updateCurrentBoard();"
        />
            <select v-model="state.board.cube.value">
                <option 
                    v-for="val in get_cube_options()" 
                    :value="val[0]"
                >
                {{ val[1] }}
                </option>
            </select>
    </label>
    </div>
    <div>
    <div class="text-[2em] flex gap-x-2 leading-none"
        v-for="dice_index in [0, 1]">
        <span v-for="val in dice_unicode"
             :class="{
                'text-accent-3-color': state.board.dice[dice_index] == val[0],
                'text-main-3-color': state.board.dice[dice_index] != val[0],
             }"
             @click="state.board.dice[dice_index] = val[0]; updateCurrentBoard();"
             v-html="val[1]"
             class="cursor-pointer hover:text-accent-2-color"
        >
        </span>
    </div>
    </div>
    <label class="relative inline-flex items-center cursor-pointer">
        <CheckBox v-model="state.edit_mode" />
      <span class="ms-3">Edit Position</span>
    </label>
    
    <h2 class="text-xl font-semibold">Match properties</h2>
    <div class="flex flex-col gap-y-2">
        <label>
            <select v-model="state.board.match_length" 
                class="" @change="updateCurrentBoard()">
                <option v-for="length in [0,1,3,5,7,9,11,13,15,17,21,23,25]" :value="length">
                    {{ length }}
                </option>
            </select>
            Match length
        </label>

        <div class="themed flex gap-x-2 items-center" v-show="state.board.match_length > 1">
            <label class="flex gap-x-2 items-center">
                <span class="rounded-full bg-stone-w-color border-case-color 
                             border size-4 inline-block" />
                <select 
                    v-model="state.board.score[0]" 
                    class="" 
                    @change="updateCurrentBoard()"
                >
                    <option 
                        v-for="score in [...Array(state.board.match_length).keys()]" 
                        :value="score"
                    >
                        {{ score }}
                    </option>
                </select>
            </label>
            <label class="flex gap-x-2 items-center">
                <span class="rounded-full bg-stone-b-color border-case-color 
                              border size-4 inline-block" />
                <select 
                    v-model="state.board.score[1]" 
                    class="" 
                    @change="updateCurrentBoard()"
                >
                    <option 
                        v-for="score in [...Array(state.board.match_length).keys()]" 
                        :value="score"
                    >
                        {{ score }}
                    </option>
                </select>
            </label>
        </div>
    </div>
    
    <div class="flex gap-x-2">
        <Tooltip text="Copy the XGID to your clipboard.">
        <button class="btn btn-blue w-16" 
                @click="copy_xgid_to_clipboard()">
            XGID
        </button>
        </Tooltip>
        <Tooltip text="Copy the OGID to your clipboard.">
        <button class="btn btn-blue w-16" 
                @click="copy_ogid_to_clipboard()">
            OGID
        </button>
        </Tooltip>
        <PositionAdd :position_id="state.current_board.toPositionString()" />
        <Tooltip text="Share a link to this analysis.">
            <button class="btn btn-blue p-0.5" 
                    @click="share_url()"
            >
                <ShareIcon class="size-6"/>
            </button>
        </Tooltip>
        <Tooltip text="Play this position against the computer.">
            <button class="btn btn-blue p-0.5" 
                    @click="router.push({name: 'bot-match', params: {bot_id: 'gnu-eval'}, query: {initial_state: state.current_board.toPositionString()}})">
                <ComputerDesktopIcon class="size-6"/>
            </button>
        </Tooltip>
    </div>

</div>
</div>

<div class="relative flex flex-col gap-y-4" :key="analysis_data.analysis_id">
<h1 class="text-2xl font-bold">Position Analysis <span class="font-thin">({{ analysis_data.engine}}-{{ analysis_data.config }})</span></h1>
<div class="z-10 opacity-40 bg-case-color w-full h-full absolute left-0 top-0 flex justify-center items-center"
    v-if="analysis_data.started_analysis"
>
    <div class="text-2xl text-point-w-color font-bold">Analysing...</div>
</div>
<div class="flex flex-col gap-y-2 relative">
<div class="flex gap-x-2">
    <button v-for="eg in engine_configs"
        class="btn btn-blue" :disabled="eg[0] == analysis_data.engine"
        @click="analysis_data.engine = eg[0]; updateAnalysis();"
    >
        {{ eg[0] }}
    </button>
</div>
<div class="flex gap-x-2">
    <button v-for="config in Object.fromEntries(engine_configs)[analysis_data.engine]"
        class="btn btn-blue" :disabled="config == analysis_data.config"
        @click="analysis_data.config = config; updateAnalysis();"
    >
        {{ config }}
    </button>
</div>
</div>

<div
class="border-solid border-b-2" 
v-if="analysis_data.win_probabilities"
>
<table class="w-full">
    <tbody>
    <tr class="font-thin">
    <td>Win</td>
    <td>Win G</td>
    <td>Win BG</td>
    <td>Lose G</td>
    <td>Lose BG</td>
    <td>Equity</td>
    </tr>
    
    <tr>
    <td class="text-left">{{ analysis_data.win_probabilities["W"].toFixed(3) }}</td>
    <td class="text-left">{{ analysis_data.win_probabilities["WG"].toFixed(3) }}</td>
    <td class="text-left">{{ analysis_data.win_probabilities["WBG"].toFixed(3) }}</td>
    <td class="text-left">{{ analysis_data.win_probabilities["LG"].toFixed(3) }}</td>
    <td class="text-left">{{ analysis_data.win_probabilities["LBG"].toFixed(3) }}</td>
    <td class="text-left">{{ analysis_data.win_probabilities["EQ"].toFixed(3) }}</td>
    </tr>
    </tbody>
</table>
</div>

<table class="w-full" v-if="analysis_data.analysis != null && analysis_data.analysis.checker">
    <tbody>
    <tr v-for="move in analysis_data.analysis.checker.moves">
        <td
            :class="{'font-semibold': analysis_data.analysis.checker.alt_played == move.alt - 1}"
            >
            {{ move.move.repr }}                        
        </td>
        <td class="pl-4 text-right">{{ move.EQ.toFixed(3) }}</td>
    </tr>
    </tbody>
</table>
<table class="w-full" v-else-if="analysis_data.analysis != null && analysis_data.analysis.cube && analysis_data.analysis.cube.double">
    <tbody>
    <tr v-for="move in analysis_data.analysis.cube.double['cube recommendation'].actions"
        :class="{'font-semibold': analysis_data.analysis.cube.double['cube recommendation'].best == move[0]}"
    >
        <td>
            {{ move[0] }}                        
        </td>
        <td class="pl-4 text-right">
            {{ move[1].toFixed(3) }}
        </td>
        <td class="pl-4 text-right">
            {{ move[2].toFixed(3) }}
        </td>
    </tr>
    </tbody>
</table>
<div v-else>No analysis available. Is this a valid position?</div>
</div>
<div class="font-thin flex gap-x-1 w-full" v-if="analysis_data.tags">
    <span class="rounded px-2 py-0.5 bg-accent-2-color"
        v-for="tag in analysis_data.tags">
        {{ tag }}
    </span>
</div>
<div class="font-thin" v-else>
    No tags
</div>

<div class="font-thin order-first md:order-none">
    It took {{ analysis_data.analysis_time.toFixed(2)}} seconds to analyse this position and
    you used 
    <span :class="{
            'text-red-500': analysis_data.total_analysis_time > 10, 
            'font-semibold': analysis_data.total_analysis_time > 5, 
                }">
        {{ analysis_data.total_analysis_time.toFixed(2) }}
    </span> 
    seconds of server time.
</div>
</div>
</div>
</template>
