<script setup>
import { BookmarkIcon, FireIcon, FlagIcon, SparklesIcon, ChatBubbleLeftIcon, ChatBubbleLeftEllipsisIcon} from '@heroicons/vue/24/solid'
import AutorollIcon from './AutorollIcon.vue'
import Tooltip from './Tooltip.vue'

const props = defineProps({
    show_resign: {
        type: Boolean,
        default: false,
    },
    disable_resign: {
        type: Boolean,
        default: false,
    },
    show_autoroll: {
        type: Boolean,
        default: false,
    },
    enabled_autoroll: {
        type: Boolean,
        default: false,
    },
    show_annotate: {
        type: Boolean,
        default: false,
    },
})
const emit = defineEmits([
    'resign',
    'autoroll',
    'annotate',
]);


</script>

<template>
<div class="flex gap-x-2">
    <Tooltip text="Resignation menu">
        <button 
            class='btn bg-stone-w-color enabled:hover:border-2 
                   disabled:hover:border-0 border-stone-w-color 
                   mb-2 size-6 transition disabled:grayscale'
               style="padding:0.25em"
               v-if="props.show_resign"
               @click="emit('resign')"
               :disabled="props.disable_resign"
            > 
            <FlagIcon class="text-case-color"/> 
        </button>
    </Tooltip>
    <Tooltip text="Toggle autoroll">
        <button class='btn bg-stone-w-color hover:border-2 border-stone-w-color 
                       mb-2 size-6' 
               style="padding:0.25em"
               v-if="props.show_autoroll"
               @click="emit('autoroll')"
            > 
            <!-- This is a custom icon -->
            <AutorollIcon :class="{
                'fill-case-color' : !props.enabled_autoroll, 
                'fill-point-b-color': props.enabled_autoroll}"/> 
        </button>
    </Tooltip>

    <Tooltip text="Mark the current position for future reference">
        <button class='btn bg-stone-w-color hover:border-2 border-stone-w-color 
                        mb-2 size-6' 
               style="padding:0.25em"
               v-if="props.show_annotate"
               @click="emit('annotate')"
            > 
            <!-- This is a custom icon -->
            <BookmarkIcon class="fill-case-color"/> 
        </button>
    </Tooltip>
</div>
</template>
