<script setup>
/*
*/
import Board from './Board.vue'
import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'
import Tooltip from './Tooltip.vue'
import UserFind from './UserFind.vue'
import UserName from './UserName.vue'

import {BoardState} from '../assets/js/board.js'
import { StateMachine } from '../assets/js/statemachine.js'
import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'

import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'

import {QuestionMarkCircleIcon, CalendarIcon, CpuChipIcon, CheckCircleIcon, 
        XCircleIcon, PuzzlePieceIcon, ShareIcon,
        ArrowLeftIcon, ArrowRightIcon, PlusIcon, MinusIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const start_position = "11ccccchhhjjjjj:55666888dddddoo:N0N:23:W:R:0:0:0:0"

const state = reactive({
    current_question_index: -1,
    board: new BoardState(),
    player_color: "W",
});

const extra_data = reactive({
    active_question: {},
    current_question: 0,
    edit_answer: false,
    editor_to_add: null,

    collapse_info: false,

    last_save: "",
    autosave: true,
    auto_saving: false,
});

const lesson_data = reactive({
    title: "",
    summary: "",
    status: "edit",
    introduction: "",
    conclusion: "",

    questions: [],
    editors: [],
    tags: [],
});

const clock_data = reactive({
    clock: {},
    clock_config: {},
});

const old_lesson_data = {};

var lesson_id = undefined;


// Auto save
const beforeUnloadHandler = (event) => {
    if(!unsaved_changes()){
        return false;
    }

    // Prevent the browser from 
    event.preventDefault();

    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = "Are you sure you want to leave? Your unsaved changes will be lost!";
};

onBeforeRouteLeave( (to, from) => {
    if(extra_data.last_save == JSON.stringify(lesson_data)){
        return;
    }
    
  const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  if (!answer) return false
});

watch( () => lesson_data, (post, prevPost) => {
    if(!unsaved_changes()){
        return;
    }
    extra_data.unsaved_changes = true;
    window.addEventListener("beforeunload", beforeUnloadHandler);

    clearTimeout(autosave_timeout_id);
    autosave_timeout_id = setTimeout(() => autosave(), 5000);
}, {deep: true});

var autosave_timeout_id = null;

onMounted(async () => {
    lesson_id = route.params.lesson_id;
    await get_lesson(lesson_id);

    // Add auto textarea sizingx
    document.querySelectorAll("textarea").forEach(function(textarea) {
        textarea.style.height = textarea.scrollHeight + "px";
        textarea.style.overflowY = "hidden";

        textarea.addEventListener("input", function() {
            this.style.height = "auto";
            this.style.height = this.scrollHeight + "px";
        });
    });
    // autosave_interval_id = setInterval(autosave, 10000);
});

onUnmounted( () => {
    clearTimeout(autosave_timeout_id);
});

async function get_lesson(lesson_id){
    console.log("Loading lesson", lesson_id);
    if(lesson_id == null){
        return;
    }

    const response = await fetch(app_server + `/lesson/${ lesson_id }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    Object.assign(lesson_data, data.lesson);
    Object.assign(old_lesson_data, data.lesson);
    extra_data.last_save = JSON.stringify(lesson_data);

    next_question(0);

    return data.lesson;
}

async function autosave(){
    if(!extra_data.autosave){
        return;
    }
    if(lesson_data.title != old_lesson_data.title){
        extra_data.autosave = false;
        messageStore.alertUser("Autosave Off", "Title has changed, turned off autosave");
        return;
    }

    if(!unsaved_changes()){
        return;
    }
    // we want to set all form fields to read only while saving
    const input_lesson_elements = document.querySelectorAll('[data-lesson]');

    input_lesson_elements.forEach( (x) => x.readOnly = true);
    extra_data.auto_saving = true;

    await save_lesson();
    await new Promise(r => setTimeout(r, 5000));

    extra_data.auto_saving = false;
    input_lesson_elements.forEach( (x) => x.readOnly = false);
}

async function save_lesson(){
    const  elem = document.getElementById("lessonform");

    if(!elem.checkValidity()){
        console.log("Form not valid:", elem.checkValidity());
        elem.reportValidity();
        return;
    }

    if(lesson_data.title != old_lesson_data.title){
        const cont = confirm(
            "By changing the lesson title you will automatically change the slug which changes the human readable link for this lesson. Are you sure you want to continue?"
        );

        if(!cont){
            lesson_data.title = old_lesson_data.title;
            return;
        }
    }

    var url = "/lesson/";
    if(lesson_data.lesson_id != null){
        url += lesson_data.lesson_id + "/";
    }

    console.log("SAVE:", url, lesson_data);

    const response = await fetch(app_server + url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(lesson_data),

    });
    const data = await response.json();
    if(data.status == "success"){
        Object.assign(lesson_data, data.lesson);
        extra_data.last_save = JSON.stringify(lesson_data);
        messageStore.alertUser("Success", "The lesson is saved.");
    }else{
        messageStore.alertUser("Validation Error", data.message);
        lesson_data.title = old_lesson_data.title;
    }

    router.replace({name: "lesson-create", 
        params: {"lesson_id": lesson_data.lesson_id}}
    );

    return data.lesson;
}

async function handleMove(positionString, action=null){
    if(extra_data.edit_answer){
        const state_machine = new StateMachine();
        state_machine.player_color = get_board_color();
        state_machine.roll_dice_callback = () => [];

        var solution = null;
        var new_board = state_machine.next_state(new BoardState(positionString), action);

        extra_data.active_question.solution = new_board.toPositionString();
    }else{
        extra_data.active_question.position = positionString;
        extra_data.active_question.solution = null;
    }
}

function get_move(){
    const alt_played = extra_data.solve_info.alt_played;
    if(extra_data.puzzle_info.puzzle_type == "checker" && alt_played >= 0){
        const move = extra_data.puzzle_info.analysis.moves.find( x => x.alt == alt_played)
        if(move){
            return move.move.repr;
        }else{
            return "No move";
        }
    }else if(extra_data.puzzle_info.puzzle_type == "double"){
        const double_solutions = {
            "ND" : "No Double",
            "DT" : "Double, Take",
            "DP" : "Double, Pass",
        };
        return double_solutions[extra_data.solve_info.solution];
    }else{
        if(extra_data.solve_info.solution && extra_data.puzzle_info.position){
            const bs = new BoardState( extra_data.puzzle_info.position);
            return bs.getMove(new BoardState(extra_data.solve_info.solution)).text;
        }   
    }
    return "No Move" 
}

function get_board_color(){
    const state = new BoardState(extra_data.active_question.position);
    return state.opponent[state.color];
}

function add_editor(){
    lesson_data.editors.push(extra_data.editor_to_add.user_id); 
    extra_data.editor_to_add=null;
    save_lesson();
}

function next_question(delta){
    extra_data.current_question = Math.min(
        Math.max(0, extra_data.current_question - delta),
        lesson_data.questions.length - 1
    );

    extra_data.active_question = lesson_data.questions[extra_data.current_question];
}

function remove_question(){
    if(!confirm("Are you sure you want to remove this question?")){
        return;
    }
    lesson_data.questions.splice(extra_data.current_question, 1);
}

function add_question(){
    lesson_data.questions.splice(extra_data.current_question + 1, 0,{
        position: start_position,
    });
    extra_data.current_question += 1;
}

function move_question(amount){
    if(extra_data.current_question + amount < 0 || extra_data.current_question + amount >= lesson_data.questions.length){
        return;
    }
    const question = lesson_data.questions.splice(extra_data.current_question, 1);

    lesson_data.questions.splice(extra_data.current_question + amount, 0, ...question);
    extra_data.current_question += amount;
}

function unsaved_changes(){
    return extra_data.last_save != JSON.stringify(lesson_data)
}

</script>

<template>
<div class="w-screen h-rest flex-col relative">
    <Header />
    <div class="flex flex-col w-full md:flex-row md:relative h-full">
        <div class="w-full md:h-rest md:w-1/2 relative">
            <Board :positionString="extra_data.active_question.position" 
                   :player_color="get_board_color()" 
                   :clock_data="clock_data"
                   :arrows="extra_data.arrows"
                   :show_pip_numbers="true"
                   :edit_mode="! extra_data.edit_answer"
                   @move-end="handleMove"
            >
            </Board>
        </div>
        <form id="lessonform"
            class="flex flex-col grow gap-y-4 px-2 md:px-4 overflow-y-auto pb-16"
        >
            <div class="flex justify-between">
                <div class="flex gap-x-4 items-center">
                    <h2 class="text-xl font-semibold">Lesson Info</h2>
                    <PlusIcon  class="size-5 cursor-pointer" 
                               v-if="extra_data.collapse_info"
                               @click="extra_data.collapse_info = false"
                    />
                    <MinusIcon  class="size-5 cursor-pointer" v-else
                               @click="extra_data.collapse_info = true"
                    />
                </div>
                <div class="flex items-center gap-x-1">
                    <Tooltip text="Autosave on/off" direction="left">
                        <input type="checkbox" v-model="extra_data.autosave"/>
                    </Tooltip>
                    <ButtonLoad class="btn btn-blue flex" 
                        :fn="save_lesson"
                        :disabled="!unsaved_changes()"
                        :loading="extra_data.auto_saving"
                    >
                        Save
                    </ButtonLoad>
                </div>
            </div>
            <div
                class="flex flex-col gap-y-4 mb-16"
                v-if="!extra_data.collapse_info"
            >
            <div>
                <label>Title</label>
                <input type="text" data-lesson
                       class="text-xl w-full" 
                       placeholder="A catching title for your lesson"
                       v-model="lesson_data.title" 
                       required
                />
            </div>
            <div>
                <label>Summary</label>
                <textarea  data-lesson class="w-full" v-model="lesson_data.summary" 
                    placeholder="A short summary of the lesson that is shown in the Lesson list." 
                    required
                />
            </div>
            <div>
                <label>Status:</label>
                <select v-model="lesson_data.status"  data-lesson
                    class="block" required
                >
                    <option :value="'edit'">Edit</option>
                    <option :value="'listed'">Listed</option>
                    <option :value="'unlisted'">Unlisted</option>
                </select>
            </div>
            <div>
                <label>Introduction:</label>
                <textarea class="w-full"  data-lesson
                          v-model="lesson_data.introduction" 
                          placeholder="An introduction that is shown when the lesson starts."
                          required
                />
            </div>

            <div>
                <label>Conclusion:</label>
                <textarea class="w-full"  data-lesson
                      v-model="lesson_data.conclusion" 
                      placeholder="Conclusion of the lesson, this text is shown after solving the last question."
                      required
                />
            </div>
            </div>
            
            <div class="flex flex-col gap-y-4">

                <div class="flex justify-between">
                    <ArrowLeftIcon 
                        class="size-6 cursor-pointer"
                        @click="next_question(1);"
                        :class="{
                            'opacity-40 cursor-disabled': extra_data.current_question == 0
                        }"
                    />
                    <div class="flex gap-x-4 items-center">
                        <h2 class="tabular-nums text-xl">
                            Question {{ extra_data.current_question + 1 }}/{{ lesson_data.questions.length  }} 
                        </h2>
                        <PlusIcon class="btn btn-blue size-6 !p-0.5" 
                            @click="add_question()"
                        />
                    </div>
                    <ArrowRightIcon 
                        class="size-6 cursor-pointer"
                        @click="next_question(-1);"
                        :class="{
                            'opacity-40 cursor-disabled': extra_data.current_question == lesson_data.questions.length-1
                        }"
                    />
                </div>

                <div class="flex flex-col gap-y-2 py-6 px-2"
                     :set="question=lesson_data.questions[extra_data.current_question]"
                     v-if= "lesson_data.questions[extra_data.current_question] != null"
                >
                    <div>
                        <label>Introduction:</label>
                        <textarea class="w-full" v-model="question.introduction" 
                            required data-lesson
                        />      
                    </div>

                    <div :class="{'font-bold border-2 border-accent-3-color bg-accent-1-color p-2': !extra_data.edit_answer}"
                        @click="extra_data.edit_answer=false"
                    >
                        <label>Position:</label>
                        <input type="text" class="w-full" v-model="question.position" 
                              required data-lesson
                        />
                    </div>

                    <div :class="{'font-bold border-2 border-accent-3-color bg-accent-1-color p-2': extra_data.edit_answer}"
                        @click="extra_data.edit_answer=true"
                    >
                        <label>Solution:</label>
                        <input type="text" class="w-full" 
                               v-model="question.solution" 
                               required data-lesson
                        />
                    </div>

                    <div>
                        <label>Hint:</label>
                        <textarea class="w-full" v-model="question.hint" required data-lesson/>      
                    </div>
                    <div>
                        <label>Explanation:</label>
                        <textarea class="w-full" v-model="question.explanation"  required data-lesson/>      
                    </div>

                    <div class="flex justify-between">
                        <div class="flex gap-x-2">
                            <button class="btn btn-yellow"
                                @click.prevent="move_question(1)"
                                :disabled="extra_data.current_question + 1 >= lesson_data.questions.length"
                            >
                                Move up
                            </button>
                            <button class="btn btn-yellow"
                                @click.prevent="move_question(-1)"
                                :disabled="extra_data.current_question -1 < 0"
                            >
                                Move down
                            </button>
                        </div>
                        <button 
                            class="btn btn-danger w-32"
                            @click.prevent="remove_question()"
                        >
                            Remove
                        </button>
                    </div>

                </div>
                <div v-if="lesson_data.author_id == userStore.info.user_id">
                    <span class="text-xl font-semibold">Editors:</span>
                    <ul class="mb-8">
                        <li class="flex justify-between gap-x-8 group w-96 py-2 px-1 hover:bg-accent-1-color"
                            v-for="editor in lesson_data.editors"
                        >
                            <UserName :user_id="editor"/>
                            <button class="invisible group-hover:visible btn btn-danger"
                                @click="lesson_data.editors = lesson_data.editors.filter(x => x != editor)"
                            >
                                Remove
                            </button>
                        </li>
                    </ul>
                    <div class="flex gap-x-4">
                        <UserFind @selecteduser="(x) => extra_data.editor_to_add = x"/>
                        <UserName v-if="extra_data.editor_to_add != null":user="extra_data.editor_to_add" />
                        <ButtonLoad class="btn btn-blue"
                            :fn="add_editor"
                            :disabled="extra_data.editor_to_add == null"
                        >
                            Add
                        </ButtonLoad>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div>
        
    </div>
</div>
</template>

<style scoped>
input[type=text]{
    border: 0;
    border-bottom: 2px solid;
    @apply py-3 border-accent-3-color;
}
textarea {
    border: 0;
    border-bottom: 2px solid;
    @apply py-3 font-normal border-accent-3-color;
}
label {
    @apply font-thin;
}
</style>


