<script setup>

import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {StarIcon} from '@heroicons/vue/24/solid'

import ButtonLoad from './ButtonLoad.vue'
import Tooltip from './Tooltip.vue'

import {BoardState} from '../assets/js/board.js'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const props = defineProps({
    position_id: {
        type: String,
    },
    direction: {
        type: String,
        default: "right",
    },
});

const extra_data = reactive({
    selected_db: "user",
    show_menu: false,
});

onMounted(async () => {
});

async function addPositionToDB(position_id){
    const response = await fetch(app_server + `/position/db/${extra_data.selected_db}/add/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "position_id": position_id,
        }),
    });
    const data = await response.json();
    
    if(data.status == "success"){
        messageStore.alertUser("Success", "Added the position to the DB", {
                url: {name: "position-db", params:{db_id: extra_data.selected_db}},
        });
    }else{
        messageStore.alertUser("Error", "Could not add the position to the DB: "+ data.message);
    }

    extra_data.show_menu = false;
}

</script>
<template>
    <Tooltip text="Add to position DB.">
    <ButtonLoad
        :fn="() => addPositionToDB(props.position_id)"
        :replace="false"
        class="relative"
        @contextmenu.prevent="extra_data.show_menu ^= true"
        @blur="extra_data.show_menu=false"
    >
        <StarIcon 
            class="size-6 btn-blue rounded p-0.5 inline-block 
             cursor-pointer" 
        />
        <div v-if="extra_data.show_menu"
             class="absolute top-0 translate-y-6 min-w-32 z-10 
                    bg-main-2-color flex flex-col py-2 gap-y-2 border-t-4 border-accent-3-color"
             :class="{
                'right-0': props.direction == 'left',
                'left-0': props.direction == 'right',
             }"
        >
            <span class="truncate hover:bg-accent-2-color px-4"
                v-for="db in userStore.position_databases"
                @click="extra_data.selected_db = db.db_id || 'user'"
            >
                {{ db.name }}
            </span>

        </div>
    </ButtonLoad>
    </Tooltip>
</template>

<style>
</style>

