<script setup>

import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {ArrowLeftIcon, UserCircleIcon, FireIcon, SparklesIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import {BoardState} from '../assets/js/board.js'
import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'

import Header from './Header.vue'
import Message from './Message.vue'
import UserName from './UserName.vue'
import Avatar from './Avatar.vue'
import UserTag from './UserTag.vue'
import Board from './Board.vue'
import ButtonLoad from './ButtonLoad.vue'
import Report from './Report.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const actions = [
    "chat", "match", "login",
];

const translate_double_moves = {
    "EQ ND": "No Double",
    "EQ DT": "Double, Take",
    "EQ DP": "Double, Pass",
};

const durations = [
    ["1h", 3600],
    ["1d", 3600*24],
    ["3d", 3600*24*3],
    ["1w", 3600*24*7],
    ["1m", 3600*24*31],
    ["1y", 3600*24*365],
    ["1c", 3600*24*365*100],
];

const duration_lookup = Object.fromEntries(durations.map( ([a,b]) => [b,a]));
console.log(duration_lookup);

const extra_data = reactive({
    report: null,
    extra_data: null,
    reports: [],
    user: null,
    user_submitted: null,
    match_data: null,
    dice_ok: null,
    open_modal: false,
});

const timeout_data = reactive({
    reason: "",
    duration: 0,
    obj: null,
    user_id: "",
});

function go_to_report(report){ // COPIED from ModerateReports.vue
    if(report.object_type == "user"){
        router.push({name: 'mod-report-user', params: {report_id: report.report_id}});
    }else if(report.object_type == "match"){
        router.push();
    }else if(report.object_type == "match"){
        router.push();
    }
}

watch( () => route.params.report_id, (newID, oldID) =>{
    get_report();
});


async function get_report(){
    const report_id = route.params.report_id;

    const response = await fetch(app_server + `/report/${report_id}/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    console.log(data);

    extra_data.report = data.report;
    extra_data.extra_data = data.extra_data;
    extra_data.match = data.extra_data.object;
    extra_data.reports = data.reports;

    get_user_info(extra_data.report.submitter_id).then(
        (x) => extra_data.user_submitted = x
    );
}

async function get_user_info(user_id=null){
    if(user_id == null){
        user_id = extra_data.user_id;
    }

    const response = await fetch(app_server + `/user/${user_id}/info/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    
    const user = data.user; 
    user.date_joined = new Date(user.date_joined);

    console.log(data);

    return user
}

async function change_report_status(new_status){
    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/changestatus/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({'status': new_status}),
    });
    
    const data = await response.json();
    console.log("Changed status", data);
    get_report();
    messageStore.alertUser("Changed Status", "Succesfully changed the status to: "+new_status);
}

async function get_match(){
    const response = await fetch(app_server + `/match/${ extra_data.report.object_id }/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const match_data = await response.json();

    console.log("MATCH", match_data);

    extra_data.match_data = new Match();
    extra_data.dice_ok = check_dice(match_data.match);
    extra_data.match_data.from_json(match_data.match);

    console.log(extra_data.match_data);
}

async function cancel_match(){
    if(!confirm("Are you sure you want to cancel this match? This will remove it from the player's history!")){
        return;
    }
    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/cancelmatch/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const data = await response.json();
    get_report();

    if(data.status == "success"){
        messageStore.alertUser("Cancelled Match", "Succesfully cancelled the match");
    }
}

async function revert_match(){
    if(!confirm("Are you sure you want to revert this match? This will reset the rating changes!")){
        return;
    }
    const response = await fetch(app_server + `/report/${extra_data.report.report_id}/revertmatch/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const data = await response.json();
    get_report();
    if(data.status == "success"){
        messageStore.alertUser("Reverted Match", "Succesfully reverted the match");
    }
}

function check_dice(match_dict){
    const match = new Match();
    match.from_json(match_dict);
    
    const player_1 = match.commitments[0];
    const player_2 = match.commitments[1];
    if(player_1 == null || player_2 == null){
        console.log("Could not load player secrets/commitments");
        return false;
    }
    

    const s = new StateMachine()

    match.set_secret(player_1.secret, player_1);
    match.set_secret(player_2.secret, player_2);
    
    const players = {}
    players[player_1.color] = player_1;
    players[player_2.color] = player_2;
    
    
    // First check the commitments
    if(player_1.commitment != match.get_commitment(player_1)){
        console.log("Commitment player 1 not valid!");
        return false;
    }
    if(player_2.commitment != match.get_commitment(player_2)){
        console.log("Commitment player 2 not valid!");
        return false;
    }
    console.log("Commitments are OK");
    
    for(let game of match.games){
        const states = game.states_log.map(x => x[0]);
        const roll_states = states.filter(x => ["R", "PR"].includes(x.game_state));
        
        for(let i=0; i < roll_states.length; i+=2){
            const PR = roll_states[i];
            const R = roll_states[i+1];
            
            const PR_dice = match.get_dice([], PR.move_id, false, null, 
                players[PR.color] // we need the opponent
            );

            const R_dice = match.get_dice(PR_dice, R.move_id, false, null, 
                players[R.color] // we need the opponent
            );
            if(PR_dice.join("") != PR.dice.join("") || 
                R_dice.join("") !=  R.dice.join("")){
                
                console.log("States:", PR.toPositionString(), R.toPositionString())
                console.log("PR:", PR_dice.join(""), PR.dice.join(""), PR.move_id);
                console.log("R: ", R_dice.join(""), R.dice.join(""), R.move_id);
                return false;
            }
        } 
    }
    console.log("Dice are OK");
    return true;
}

function get_match_duration(){
    const seconds = new Date(extra_data.match.finished_time+"Z") - new Date(extra_data.match.create_time * 1000);

    return get_time(seconds/1000);
}

function get_time(seconds){
    /*
    * returns a human reabable time string (bit overcomplicated, but wanted it to
    * be nicely formatted.
    */
    const months = Math.floor(seconds / (30*24*60*60)).toString();
    const days = Math.floor((seconds % (30*24*60*60)) / (24*60*60)).toString();
    const hours = Math.floor((seconds % (24*60*60)) / (60*60)).toString();
    const minutes = Math.floor((seconds % (60*60)) / 60).toString();
    const sec = Math.floor(seconds % 60).toString();

    var time = 0;
    var unit = ""

    if(months > 0){
        time = months;
        unit = `months`;
    }else if(days > 0){
        time = days;
        unit = `days`;
    }else if(hours > 0){
        time = hours;
        unit = `hours`;
    }else if(seconds >= 60){
        time = minutes;
        unit = `minutes`
    }else{
        time = sec; 
        unit = "seconds";
    }

    if(time == 1){
        unit = unit.slice(0, -1);
    }
    
    return `${time} ${unit}`;
}

onMounted(async () => {
    await Promise.allSettled([
        userStore.loadUser(),
        get_report(),
    ])
    await get_match();
});

function handleMove(){

}

</script>
<template>
    <Header/>
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-8 mb-32 px-2">
        <h1 class="flex gap-x-4 items-center text-4xl mt-8 mb-4 font-semibold">
            <a @click="router.go(-1)" class="hover:text-accent-3-color cursor-pointer">
                <ArrowLeftIcon class="size-8" />
            </a>
            Match Report
        </h1>
        <div v-if="extra_data.report != null">
            <h2 class="text-2xl">Report</h2>
            <h3 class="text-xl">Status: {{ extra_data.report.status }}</h3>
            <h3 class="text-xl">Reason: {{ extra_data.report.reason }}</h3>
            <h3 class="font-light">Time: {{ (new Date(extra_data.report.submit_time*1000)).toISOString().slice(0,19) }}</h3>
            <h3 class="font-light" v-if="extra_data.report.status == 'closed'">
                Time Closed: {{ (new Date(extra_data.report.closed_time*1000)).toISOString().slice(0,19) }}</h3>
            <h3 class="font-light flex items-center" 
                v-if="extra_data.user_submitted != null"
            >
                <span>Submitted By: </span>
                <UserName :user="extra_data.user_submitted"/>
            </h3>
            <h3 class="font-light flex items-center" 
                v-if="extra_data.report.closed_by"
            >
                <span>Closed By: </span>
                <UserName :user_id="extra_data.report.closed_by"/>
            </h3>
            <div class="my-4">
                {{ extra_data.report.description }}
            </div>
            <ButtonLoad class="btn btn-blue mt-8 h-10"
                    :fn="() => change_report_status('closed')"
                    :disabled="extra_data.report.status == 'closed'"
            >
                Close Report
            </ButtonLoad>
        </div>

        <div class="flex flex-col" v-if="extra_data.match">
            <h2 class="font-semibold text-2xl">Match Info</h2>
            <span class="text-lg font-semibold capitalize">Match Status: {{ extra_data.match.status }} </span>
            <span>Length: {{ extra_data.match.match_length }}</span>
            <span v-if="extra_data.match_data">
                Games: {{ extra_data.match_data.games.length }} ({{ extra_data.match_data.games.map((x) => x.states.length) }})
            </span>
            <span>Score: {{ extra_data.match.score.W }} - {{ extra_data.match.score.B }}</span>
            <span>Stake: {{ extra_data.match.stake }}</span>
            <span>Time control: {{ extra_data.match.time_control }} </span>
            <span>Start Time: {{ new Date(extra_data.match.create_time * 1000).toISOString() }} </span>
            <span>Finish Time: {{ new Date(extra_data.match.finished_time+"Z" ).toISOString() }} </span>
            <span>Duration: {{ get_match_duration() }} </span>
            <span>Analysis: 
                <router-link :to="{name: 'analysis', params: {match_id: extra_data.match.match_id}}"> 
                    <span v-if="extra_data.extra_data.analysis"
                          :set="analysis=extra_data.extra_data.analysis"
                    >
                    {{ analysis.config }} - {{ analysis.engine_id }} - {{analysis.status}}
                    </span>
                </router-link>
            </span>
            <span>
                <router-link :to="{name: 'user-vs-info', params: {user1_id: extra_data.match.white.user_id, user2_id: extra_data.match.black.user_id}}"> 
                    <span v-if="extra_data.extra_data.analysis"
                          :set="analysis=extra_data.extra_data.analysis"
                    >
                        Show VS page
                    </span>
                </router-link>
            </span>

            <div v-if="extra_data.match_data" class="flex flex-col mt-4 hidden">
                <span class="font-semibold">Advanced Match data</span>
                <span>Dice: {{ extra_data.dice_ok }}</span>
                <span>Secrets: {{ extra_data.match_data.commitments.map((x) =>  x.secret != null) }}</span>
                <span>Commitments: {{ extra_data.match_data.commitments.map((x) =>  x.commitment != null) }}</span>
            </div>
        </div>
        <div class="flex gap-x-16 w-full"
             v-if="extra_data.match != null"
        >
            <div class="flex flex-col gap-y-4 relative"
                 v-for="user in [extra_data.match.white, extra_data.match.black]"
            >
                <h3 class="text-lg font-medium">
                    <router-link :to="{name: 'user-info', params:{user_id: user.user_id}}">
                        <UserName :user="user" />
                    </router-link>
                </h3>
                <div>
                    ER: {{ user.er.toFixed(2) }}
                </div>
                <div class="flex gap-x-4">
                    <span class="flex items-center">
                        <FireIcon class="size-6" /> 
                        {{ user.rating.toFixed(0) }}
                    </span>
                    <span class="flex items-center">
                        <SparklesIcon class="size-6" /> 
                        {{ user.rating.toFixed(0) }}
                    </span>
                    <span class="flex items-center">
                        XP {{user.experience.toFixed(0)}}
                    </span>
                </div>
                <div class="flex gap-x-4">
                    <span class="flex items-center" v-if="user.new_rating">
                        <FireIcon class="size-6" /> 
                        {{ (user.new_rating - user.rating).toFixed(2) }}
                    </span>
                        <span class="flex items-center" v-if="user.new_coins">
                        <SparklesIcon class="size-6" /> 
                        {{ (user.new_coins - user.coins).toFixed(0) }}
                    </span>
                    <span class="flex items-center">
                        XP {{extra_data.match.match_length.toFixed(0)}}
                    </span>
                </div>
                <Report :object_type="'user'" :object_id="user.user_id">
                    <button class="btn btn-blue">Report User</button>
                </Report>
            </div>
        </div>

        <div v-if="extra_data.extra_data != null "
             class="flex flex-col gap-y-8"
             :set="match=extra_data.extra_data.object"
        >
        </div>

        <div v-if="extra_data.report != null && extra_data.report.status == 'open'" class="flex flex-col gap-y-4">
            <h2 class="text-2xl">Actions</h2>
            <ButtonLoad class="btn btn-blue h-12 max-w-96"
                        :fn="cancel_match"
            >
                Cancel Match
            </ButtonLoad>
            <ButtonLoad class="btn btn-blue h-12 max-w-96"
                        :fn="revert_match"
            >
                Revert Match
            </ButtonLoad>


        </div>

        <div>
            <h2 class="text-2xl">Recent Reports</h2>
            <div class="flex justify-between gap-x-8"
                 v-for="report in extra_data.reports"
            >
                <span class="grow hover:underline hover:text-field-color cursor-pointer" 
                    @click="go_to_report(report)"
                >
                    {{ report.reason || 'No Reason'}}
                </span> 
                <span>
                    {{ report.status}}
                </span> 
                <span>
                    {{ report.submitter_id.slice(0,5)}}
                </span> 
                <span class="font-light tabular-nums">
                    {{ (new Date(report.submit_time*1000)).toISOString().slice(0,19) }}
                </span>
            </div>
        </div>

    </div>
</template>

<style>
</style>
