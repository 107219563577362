export function set_theme_color(color=null){
    const meta = document.querySelector('meta[name="theme-color"]')
    if(meta){
        if(color == null){
            color = window.getComputedStyle(document.body).getPropertyValue("background-color");
            console.log("using default color", color);
        }
        meta.setAttribute('content', color)
    }else{
        console.log("No theme!");
    }
}

