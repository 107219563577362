<script setup>
import Board from './Board.vue'
import Message from './Message.vue'
import GameSidebar from './GameSidebar.vue'

import { set_theme_color } from '../assets/js/helpers.js'
import { Match } from '../assets/js/match.js'
import { StateMachine } from '../assets/js/statemachine.js'
import { Bot } from '../assets/js/bots.js'
import {BoardState} from '../assets/js/board.js'
import {create_random_boardstate} from '../assets/js/random_boardstate.js'

import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

const route = useRoute();
const router = useRouter();

const play_server = import.meta.env.VITE_WEBSOCKET_SERVER;
const app_server = import.meta.env.VITE_APP_SERVER;

var finalized = false;
var forcedmove_timeout_id = null;

const state = reactive({
    currentState: null,
    score: {},
});

const extra_data = reactive({
    message : "",
    board_message : "",
    match_info: {black: {}, white: {}},
    can_start: false,
});

const premove_data = reactive({
    premoves: [],
});

const clock_data = reactive({
    clock: {"W":-1, "B":-1, "delay":-1},
    clock_config: {},
});

const bot_data = reactive({
    move_callback: null, 
});

const chat_data = reactive({
    lobbies: [],
});

var match = new Match();
const state_machine = reactive(new StateMachine());
var bot = null;
var bot_id = null;
var custom_initial_state = null;

var auto_bot = null;

let move_socket = null;
var finalize_timeout = null;

const bot_event_func = (e) => {
    try{
        onMessage(e.detail)
    } catch(error){
        window.reportError(error);
    }
}

onMounted(async () => {
    bot_id = route.params.bot_id;

    custom_initial_state = route.query.initial_state;

    const data = await get_bot_info();   
    Object.assign(bot_data, data);

    var initial_state = custom_initial_state || bot_data.initial_state;

    if(initial_state == "random"){
        initial_state = create_random_boardstate();
    }

    if(custom_initial_state == null){
        const initial_board = new BoardState(initial_state);
        initial_board.match_length = bot_data.match_length;
        initial_state = initial_board.toPositionString();
    }

    bot = new Bot(bot_id, {
        evaluator:bot_data.evaluator, 
        bot_config:bot_data.config, 
        initial_state: initial_state,
        delay: 500,
        bot_color: "B", 
        recv_channel: "player-message",
        send_channel: "bot-message",
    });

    bot.bot.app_server_url = app_server;
    bot.send_chat = (game_event, board) => handle_bot_chat(game_event, board);

    chat_data.lobbies = [`BOT_${bot_data.name}`];

    sseStore.connect();
    sseStore.addListener("analysis", (data) => bot.handle_analysis(data));
    
    match = new Match("W", bot_data.match_length, initial_state); 
    match.player.color = "W";
    extra_data.match_info.points = match.match_length;

    const last_state = match.get_state();
    if(last_state == null){
        state.currentState = initial_state;
    }else{
        state.currentState = last_state;
    }
    state_machine.player_color = "W";
    state_machine.initial_board = new BoardState(initial_state);
    state_machine.roll_dice_callback = (dice, move_counter, distinct) => 
        match.get_dice(dice, move_counter, distinct);

    extra_data.match_info.black = {
        username: bot_data.name,
        elo: bot_data.elo,
        rating: bot_data.elo,
        user_id: bot_data.name,
    };
    extra_data.match_info.white = {
        username: "Anonymous",
        elo: 800,
        rating: 800,
    };
    
    // register the message handler
    document.removeEventListener("bot-message", bot_event_func);
    document.addEventListener("bot-message", bot_event_func);
    
    userStore.loadUser().then(() => {
        userStore.loadPreferences().then( () => {
            userStore.applyBoardPreferences(document.getElementById("game"))
            set_theme_color(userStore.preferences.board.color.case);

            if(userStore.preferences.board.animation_duration != 0){
                bot.delay = parseFloat(userStore.preferences.board.animation_duration || 0.5) * 1000;
            }
        });
        extra_data.match_info.white = {
            username: userStore.info.username,
            avatar: userStore.info.avatar,
            elo: userStore.info.elo,
            rating: userStore.info.rating,
        };
    });

    if(route.query.auto != null){
        auto_bot = new Bot("auto", {
            evaluator: "gnu", 
            bot_config: {}, 
            initial_state: initial_state,
            delay: 100,
            bot_color: "W", 
            recv_channel: "bot-message",
            send_channel: "player-message",
        });
        auto_bot.bot.app_server_url = app_server;

        auto_bot.show_premoves = false;
        bot.show_premoves = false;
        auto_bot.delay = 10;
        bot.delay = 10;

        sseStore.addListener("analysis", (data) => {
            // console.log("ANALYSIS", data);
            auto_bot.handle_analysis(data);
            bot.handle_analysis(data);
        });

        document.addEventListener("player-message", bot_event_func);

        // document.removeEventListener("auto-message", bot_event_func);
        // document.addEventListener("auto-message", bot_event_func);
    }

    if(!restore_botmatch()){
        match.push_state(new BoardState(initial_state));
    }
    await bot.start_play(match.get_state());
    extra_data.can_start = true;
    setTimeout( () => handle_bot_chat("greeting", new BoardState(initial_state)), 2000);
});

onUnmounted( () => {
    document.removeEventListener("bot-message", bot_event_func);
    if(bot){
        bot.remove_eventlistener();
    }
    if(auto_bot){
        auto_bot.remove_eventlistener();
    }
});

function handle_bot_chat(game_event, board){
    const chat = bot_data.chat_config[game_event] || {};
    const settings = chat.settings || bot_data.chat_config.settings || {};
    
    const dice_string = (board.dice.sort( (a,b) => b-a ) || []).join("");

    var lines = [];
    var prob = 1;
    if(chat[dice_string] != null){
        lines = chat[dice_string];
        prob = 1;
    }else{
        lines =  chat["default"] || [];
        prob = settings.prob || 0.1;
    }

    if(Math.random() <= prob){
        const random_line = lines[ Math.floor(Math.random() * lines.length)];
        chatStore.bot_chat(bot_data.name, random_line);
    }
}

function get_match_info(match_id){
    extra_data.match_info = bot.get_matchinfo();
}

async function get_bot_info(){
    const response = await fetch(app_server + `/bot/${bot_id}/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log("Bot Data:", data);
    if(data.status == "success"){
        extra_data.match_info.black = data.info;
        bot_data.config = data.info.config;
    }
    return data.info;
}

function getDiceString(move_id){
    return match.get_dice_string(move_id);
}

function getMoveString(move_id){
    return match.get_move_string(move_id);
}

function sendMessage(positionString, type="move", extra_data=null){
    // send the move to the other player
    if(finalized || match.is_won()){
        return;
    }
    
    let signature = match.sign_position(positionString);
    const data = {
        type: type, 
        match_id: match.match_id, 
        state: positionString,
        signature: signature,
        extra_data: extra_data,
    };

    const bs = new BoardState(positionString);
    if(type == "move"){
        const last_state = match.get_state();
        if(last_state != null && bs.move_id >= last_state.move_id){
            match.push_state(bs, signature);
        }else{
            return;
        }
    }
    if(bs.color == "W"){
        store_botmatch();
    }

    if(auto_bot == null){
        console.debug("PYR SEND:", positionString, Date.now());
        document.dispatchEvent( new CustomEvent("player-message", {
            detail: data,
        }));
    }

    if(type == "finalize"){ // We wait for 10 seconds for the opponent to finalize, otherwise move on
        finalize_timeout = setTimeout(() => {
                extra_data.board_message = "Redirecting";
                router.replace({name:"post", params:{match_id: match.match_id}})
            }, 10000
        );
    }
}

function handleMove(positionString, action=null){
    if(forcedmove_timeout_id){
        clearTimeout(forcedmove_timeout_id);
        extra_data.board_message = "";
        forcedmove_timeout_id = null;
    }
    if(finalized){
        return;
    }
    const boardstate = new BoardState(positionString);
    if(["D", "A", "P"].includes(boardstate.game_state)){
        document.dispatchEvent(new CustomEvent("board-sound", {
            detail: {
                "type": "cube",
                "is_accept": boardstate.game_state == "A",
                "is_pass": boardstate.game_state == "P",
                "is_double": boardstate.game_state == "D",
            },
        }));
    }
    var new_boardstate = state_machine.next_state(boardstate, action);     

    if(new_boardstate == null){
        new_boardstate = boardstate;
    }else{
        state.currentState = new_boardstate.toPositionString();
        if(new_boardstate.game_state == "F" && !finalized){
            const extra_data = match.to_json().player;
            finalized = true;
            // sendMessage(new_boardstate.toPositionString(), "final", extra_data);
            finalize_match();
            return true;
        }else{
            sendMessage(new_boardstate.toPositionString());
            return true;
        }
    }
    if(["IB", "R", "C", "D", "A"].includes(new_boardstate.game_state)){
        state.currentState = new_boardstate.toPositionString();
    }
    return false;
}

function resetMatch(){
    match.match_id = null;
    match.player.token = null; 
    match.player_color = "";
    match.player_secret = "";
    localStorage.removeItem("current_match");
    router.replace("/");
}

function onMessage(data){
    if(data.type == "error"){
        console.log("Error:", data);
        messageStore.alertUser("Error", "onMessage: " + data.message);

        resetMatch();
        return;
    }
    
    if(data.type == "chat"){
        // chat_data.message = data;
    }    
    if(data.type == "premove"){
        premove_data.premoves = data.premoves;
        return;
    }    
     
    var could_move = false;
    if(data.type == "move" || data.type == "final"){
        let board = new BoardState(data["state"]);
        if(board.game_state == "IB" || board.color == "B"){
            match.push_state(board, data["signature"]);
        }
        if(board.game_state == "IB" && board.color == state_machine.player_color ||
            board.game_state == "R" && board.color == state_machine.player_color
        ){
            sendMessage(board.toPositionString());
        }
        could_move = handleMove(board.toPositionString());
    }

    if(data.type == "final"){
        match.set_secret(data.extra_data["secret"], match.opponent);
        match.set_token(data.extra_data["token"], match.opponent);
        
        const result = match.check_log();
         
        clearTimeout(finalize_timeout);
        return;
    }
    
    // apply forced moves
    const board = new BoardState(state.currentState);
    var action = null;
    if(!could_move && board.game_state == "R" && match.player.color != board.color){
        const valid_states = Object.values(board.getValidStates());
        var next_state;
        
        if(valid_states.length <= 1){
            if(valid_states.length == 0){
                extra_data.board_message = "No Move";
                action = board;
            }else{
                extra_data.board_message = "Forced Move";
                action = valid_states[0][0][0];
            }
            // match.push_state(board);
            forcedmove_timeout_id = setTimeout(() => {
                    handleMove(board.toPositionString(), action);
                    extra_data.board_message = "";
                }, 1200
            );
            return;
        }
    }
    
    return;
}

async function finalize_match(){
    var match_transcript;

    if(auto_bot){
        match_transcript = auto_bot.match.to_json();
    }else{
        match_transcript = match.to_json();
    }

    setTimeout( () => handle_bot_chat("final", new BoardState(state.currentState)), 100);
    extra_data.board_message = "Match Finished";

    const response = await fetch(app_server + `/bot/${bot_id}/match/create/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            "match": match_transcript,
        }), 
    });

    const match_data = await response.json();
    if(match_data.status == "error"){
        console.log(match_data);
        console.log(match_transcript);
        messageStore.alertUser("Error", match_data.message);
    }

    localStorage.removeItem(`bot:save:${bot_id}`);

    if(auto_bot == null){
        setTimeout( () => router.replace({name:"post", 
            params:{match_id: match_data.match_id}}), 2000);
    }else{
        console.log(match_data.match_id); 
        location.reload();
    }
}

function store_botmatch(){
    localStorage.setItem(`bot:save:${bot_id}`, JSON.stringify(match.to_json()));
}

function restore_botmatch(){
    const match_json = localStorage.getItem(`bot:save:${bot_id}`);
    if(!match_json){
        return false;
    }
    if(!confirm("Do you want to restore your old match?")){
        localStorage.removeItem(`bot:save:${bot_id}`);
        return false;
    }

    match.from_json(JSON.parse(match_json));
    for(let i=-1; i > -6; i--){
        const last_player_state = match.get_state(i);
        console.log(i, last_player_state);
        if(last_player_state.color == "W"){
            handleMove(last_player_state.toPositionString());
            break;
        }
    }
    return true;
}

</script>
<template>
<Message />
<div id="game" 
    class="game flex flex-col-reverse sm:flex-row relative"
>
    <Board :positionString="state.currentState" 
           :player_color="match.player.color" 
           :state_machine="state_machine"
           :all_players_connected="extra_data.can_start"
           :differences="match.get_game().get_difference(null)"
           :clock_data="clock_data"
           :board_message="extra_data.board_message"
           :match_info="extra_data.match_info"
           :show_resign="true"
           :premoves="premove_data.premoves"
           @move-end="handleMove"
            class="grow"
    >
    </Board>
    <GameSidebar 
           :player_color="match.player.color" 
           :match_info="extra_data.match_info"
           :chat_data="chat_data"
        class="grow"
    />
</div>
</template>

<style scoped>
body{
    background: var(--case-color);
}

.game{
    height: calc(100svh);
    width: 100svw;
    max-width: 100%;
    max-height: 100%;
    --case-med-color: color-mix(in srgb, var(--case-color) 80%, white);
    background: var(--case-color);
    background: linear-gradient(0.25turn, var(--case-med-color), var(--case-color), var(--case-med-color));
    /*background-color: linear-gradient(var(--case-med-color), var(--case-color), var(--case-med-color));*/
    overflow: hidden;
}
</style>
