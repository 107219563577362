<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {PlusIcon, UsersIcon, ClockIcon, UserCircleIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var slug;

const props = defineProps({
});

const extra_data = reactive({
    clubs: [],
});


onMounted(async () => {
    get_clubs();
});

async function get_clubs(){
    var url = app_server + `/club/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();

    if(data.status == "success"){
        extra_data.clubs.push(...data.clubs);
    }
    
    console.log(data);
}

</script>

<template>
    <Header />
    <div class="container mx-auto">
    <h1 class="text-4xl font-semibold text-center mt-8 mb-12 tracking-wide flex items-center justify-center gap-x-4">
        Clubs
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color shadow rounded py-4 px-6 group relative transition-all"
            v-for="club in extra_data.clubs"
            :class="{'!bg-accent-1-color': club.start_time*1000 > Date.now()}"
        >
            <div class="grow flex flex-col gap-y-2 w-full">
                <h2 class="text-xl text-center tracking-wide font-medium 
                    mb-4 group-hover:text-accent-3-color w-full truncate"
                >
                    <router-link :to="{name: 'club-info', params:{club_id: club.club_id}}">
                        <div class="flex flex-col gap-y-4">
                            <span class="w-full px-8 truncate">
                                {{ club.name }}
                            </span>
                            <span class="w-full text-3xl px-8 truncate">
                                {{ club.tag }}
                            </span>
                        </div>
                    </router-link>
                </h2>

                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Club Director">
                        <UserCircleIcon class="w-4 h-4" />
                    </Tooltip>
                    <UserName :user_id="club.creator" />
                </div>
                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Since">
                        <ClockIcon class="size-4" />
                    </Tooltip>
                    <span>{{ new Date(club.create_time*1000).toLocaleDateString() }}</span>
                </div>
                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Rating">
                        <FireIcon class="size-4" />
                    </Tooltip>
                    <span>{{ club.rating.toFixed(0) }}</span>
                </div>
                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Experience">
                        XP
                    </Tooltip>
                    <span>{{ club.experience.toFixed(0) }}</span>
                </div>
                <div class="flex gap-x-1 items-center">
                    <Tooltip text="Members">
                        <UsersIcon class="size-4" />
                    </Tooltip>
                    <span>{{ club.members.length }}</span>
                </div>
                <div class="mt-4 text-lg font-thin overflow-y-hidden h-24 relative">
                    <!-- <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" /> -->
                    <MarkdownRenderer 
                        :markdown="club.description" 
                        :save="false" 
                    />
                </div>
                <router-link :to="{name: 'club-info', params:{club_id: club.club_id}}">
                    <button class="btn btn-blue h-10 w-full">Info</button>
                </router-link>
            </div>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>


