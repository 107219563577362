<script setup>

import {ref, watch, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Message from './Message.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const extra_data = reactive({
    reports: [],
    filter_type: null,
    filter_status: 'open',
    offset: 0,
    total_reports: 0,
});

async function get_reports(){
    const params = new URLSearchParams({
        offset: extra_data.offset,
        type: extra_data.filter_type,
        status: extra_data.filter_status,
    });

    router.replace({ query: {
        offset : extra_data.offset,
        filter_type : extra_data.filter_type,
        filter_status : extra_data.filter_status,
    }})
    const params_str = params.toString();

    const response = await fetch(app_server + `/report/?${params_str}`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    if(data.status == "success"){
        extra_data.reports.length = 0
        extra_data.reports.push(...data.reports);
        extra_data.total_reports = data.nrof_reports;
    }else{
        messageStore.alertUser("Forbidden", data.message);
        router.push({name: "home"});
    }
    console.log(data);
}

function go_to_report(report){
    if(report.object_type == "user"){
        router.push({name: 'mod-report-user', params: {report_id: report.report_id}});
    }else if(report.object_type == "match"){
        router.push({name: 'mod-report-match', params: {report_id: report.report_id}});
    }else if(report.object_type == "puzzle"){
        router.push({name: 'mod-report-puzzle', params: {report_id: report.report_id}});
    }
}

onMounted(async () => {
    console.log(route.query);
    Object.assign(extra_data, route.query);
    get_reports();
});

watch(extra_data, (newd, oldd) => {
    console.log("HELP");
    // router.replace({ query: {
    //     type : extra_data.filter_type,
    //     offset : extra_data.offset,
    //     status : extra_data.filter_status,
    // }})
});

</script>
<template>
    <Header/>
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-4">
        <div class="flex gap-x-8">
            <div class="flex gap-x-2 items-center">
                <span>Type:</span>
                <select class="w-32"
                    @change="get_reports()"
                    v-model="extra_data.filter_type">
                    <option :value="null">All</option>
                    <option value="puzzle">Puzzle</option>
                    <option value="match">Match</option>
                    <option value="user">User</option>
                </select>
            </div>
            <div class="flex gap-x-2 items-center">
                <span>Status:</span>
                <select class="w-32"
                    @change="get_reports()"
                    v-model="extra_data.filter_status">
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                </select>
            </div>
        </div>
        <table>
            <thead>
            <tr class="text-2xl font-bold">
                <td>Type</td>
                <td>Status</td>
                <td>Reason</td>
                <td class="text-right">Date</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="report in extra_data.reports">
                <td @click="go_to_report(report)"
                    class="hover:underline hover:text-field-color cursor-pointer"
                >
                    {{ report.object_type }}
                </td>
                <td>{{ report.status }}</td>
                <td>{{ report.reason }}</td>
                <td class="tabular-nums text-right">
                    {{ (new Date(report.submit_time*1000)).toISOString().slice(0,10) }}</td>
            </tr>
            </tbody>
        </table>
        <div class="flex justify-between">
            <button @click="extra_data.offset = Math.max(0, extra_data.offset - 20); get_reports()">
                 Previous
            </button>
            <span class="tabular-nums">
                {{ extra_data.offset}}/{{ extra_data.total_reports }}
            </span>
            <button @click="extra_data.offset = Math.min(extra_data.total_reports-20, extra_data.offset + 20); get_reports()">
                Next
            </button>
        </div>

    </div>
</template>

<style>
</style>

