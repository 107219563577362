<script setup>
import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'

import ReleaseNote from './ReleaseNote.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

var note_id;
var autosave_timeout_id = null;

const extra_data = reactive({
    show_note: false,
    page_index: 0,
});

const note_data = reactive({
    pages: {},
})


onBeforeRouteLeave( (to, from) => {
    if(!extra_data.unsaved_changes){
        return true;
    }

  const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  if (!answer) return false
});

onMounted(() => {
    note_id = useRoute().params.releasenote_id;
    get_note();
});

onUnmounted( () => {
});

const date_fields = ["show_from_time", "show_until_time"]; 

async function save_note(){
    var url = app_server + `/admin/releasenote/${note_id}/`;

    const data = Object.assign({}, note_data);
    for(let field of date_fields){
        if(data[field] != null){
            data[field] = new Date(data[field]) / 1000;
        }
    }

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const releasenote_data = await response.json();

    await get_note();

    extra_data.unsaved_changes = false;
}

async function delete_note(){
    if(!confirm("Are you sure you want to delete this release note?")){
        return;
    }
    var url = app_server + `/admin/releasenote/${note_id}/`;

    const response = await fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const releasenote_data = await response.json();
    
    router.push({name: 'admin-releasenote'});
}


async function get_note(){
    var url = app_server + `/admin/releasenote/${note_id}/`;

    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    Object.assign(note_data, data.note);
    for(let field of date_fields){
        if(note_data[field] != null){
            note_data[field] = get_time_string(note_data[field]);
        }
    }
    console.log(data);
}

async function upload_media(){
    var url = app_server + `/admin/releasenote/${note_id}/media/`;

    const upload_input = document.getElementById('media-upload');
    const data = new FormData();
    data.append('file', upload_input.files[0])

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: data,
    });
    
    const media_data = await response.json();

    if(media_data.status == "success"){
        get_page().image = "/media/" + media_data.media_url
    }
    console.log(media_data);
}

function insert_media_link(media){
    const content_field = document.getElementById('content-field');
    const link = "/media/" + media.path;
    const media_tag = `![alt-text](${link})`;

    if (content_field.selectionStart || content_field.selectionStart == '0') {
        var startPos = content_field.selectionStart;
        var endPos = content_field.selectionEnd;
        content_field.value = content_field.value.substring(0, startPos)
            + media_tag
            + content_field.value.substring(endPos, content_field.value.length);
    }
}

function get_time_string(utc_timestamp){
    /* 
    Returns a local timestamp accounting for the timezon of the client 

    The timestamp is to be used in datetime-local inputs
    */
    const timezone_offset = (new Date()).getTimezoneOffset()*60*1000;
    const time_string = new Date(
        (utc_timestamp * 1000) - timezone_offset
    ).toISOString().slice(0,16);

    return time_string;
}

function add_page(){
    if(note_data.pages.data == null){
        note_data.pages.data = [];
    }

    note_data.pages.data.push({
        title: "",
        content: "",
        action_text: "",
        action_url: "/",
        image: "",
    });
    console.log(note_data.pages.data);
}

function get_page(){
    if(note_data.pages.data == null){
        return null;
    }
    if(extra_data.page_index < 0){
        extra_data.page_index = note_data.pages.data.length - 1;
    }

    if(extra_data.page_index >= note_data.pages.data.length){
        extra_data.page_index = 0;
    }

    return note_data.pages.data[extra_data.page_index];
}


function show_note(){
    extra_data.show = extra_data.show ? false : true;
}

</script>
<template>
<div class="container max-w-prose mx-auto pb-32">
    <div class="flex flex-col gap-y-3 h-full pl-2">
        <div class="flex justify-between my-3">
            <h1 class="text-2xl font-semibold">Note Information</h1>
            <div class="flex gap-x-2">
            <button class="btn btn-danger" 
                @click="delete_note()" 
            >
                Delete
            </button>
            <button class="btn btn-blue" 
                @click="save_note()" 
            >
                Save
            </button>
            <button class="btn btn-blue" 
                @click="show_note()" 
            >
                Show
            </button>
            </div>
        </div>
        <label class="label">
            <div>Title</div>
            <input class="input" type="text" placeholder="An awesome guide to opening moves"
                v-model="note_data.title" required
            >
        </label>
        <label class="label">
            <div>Show Between</div>
            <input type="datetime-local"
                v-model="note_data.show_from_time"
            > -
            <input type="datetime-local"
                v-model="note_data.show_until_time"
            >
        </label>

        <div v-if="get_page() != null" class="">
            <span class="text-center flex justify-center text-xl mt-8"> 
                Page: {{ extra_data.page_index + 1}} / {{ note_data.pages.data.length }} 
            </span>
            <label class="flex flex-col"> 
                Title
                <input type="text" v-model="get_page().title" placeholder="Page Title"/>
            </label>

            <div class="w-full min-h-64 flex items-center border border-main-5-color my-2">
                <span v-if="!get_page().image" class="text-2xl text-center w-full">
                    No Image
                </span>
                <img v-else class="w-4/5" :src="get_page().image" />
            </div>
            <div
                class="flex gap-y-2  "
            >
                <input type="file" id="media-upload" />
                <button class="btn btn-blue" @click="upload_media()">
                    Upload
                </button>
            </div>

            <label class="flex flex-col"> 
                Content
                <textarea v-model="get_page().content" placeholder="Page Content"></textarea>
            </label>
            <label class="flex flex-col"> 
                action_text
                <input type="text" v-model="get_page().action_text" />
            </label>
            <label class="flex flex-col"> 
                action_url
                <input type="text" v-model="get_page().action_url" />
            </label>

        </div>

        <div class="flex gap-x-4">
            <button class="btn btn-blue"
                @click="console.log('HIIIIIII', extra_data.page_index); extra_data.page_index -= 1"
            >
                Prev
            </button>
            <button class="btn btn-blue grow"
                @click="add_page()"
            >
                + Add Page
            </button>
            <button class="btn btn-blue"
                @click="extra_data.page_index += 1"
            >
                Next
            </button>
        </div>
    </div>

    <div v-if="note_data.pages != null">
        <ReleaseNote :show="extra_data.show" 
                     :pages="note_data.pages.data" 
                     :in_admin="true"
        />
    </div>
</div>
</template>
<style scoped>
    .label{
        @apply block text-sm font-medium leading-6;
    }
    .input{
        @apply w-full border-0 border-b-2 bg-transparent py-1.5 pl-1 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6;
    }
</style>


