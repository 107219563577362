<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {TrashIcon, CheckIcon, XMarkIcon, SparklesIcon, ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/solid'

import { time_controls, time_control_to_text, to_timecontrol_string } from '../assets/js/timecontrols.js'

import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import ButtonLoad from "./ButtonLoad.vue"
import Popup from './Popup.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const emit = defineEmits([
    'match-joined',
    'cancel',
]);
const props = defineProps(
    { 
        "show" : {
            type : Boolean,
            default: false,
        },
        "match_id" : {
            type: String,
            default: null,
        }
    }
);

var update_wait_timer_id = null;

const extra_data = reactive({
    counter: 0,
    show:false,
    state:"idle",

    join_index: 0,
    current_active_index: 0,
});

onMounted(() =>{ 
    matchStore.setup();

    extra_data.counter = 0;
    setTimeout(() => update_wait_time(), 0);

    update_wait_timer_id = setInterval(() => {
        update_wait_time();
    }, 1000);
});

onUnmounted(() =>{ 
    extra_data.counter = 0;
    clearInterval(update_wait_timer_id);
});

watch(props, async (newProps, oldProps) => {
    if(newProps.show){
        extra_data.counter = 60;   
        counter_interval_id = setInterval(() => {
            if(extra_data.counter == 0){
                cancel_join_request();
                clearInterval(counter_interval_id);
            }else{
                extra_data.counter--;
            }
        }, 1000);
    } 
});

function update_wait_time(){
    for(const match_type of matchStore.match_types){
        if(matchStore.joinrequests[match_type] == null){
            continue;
        }
        for(const joinrequest of matchStore.joinrequests[match_type]){
            var wait_time = joinrequest.time_to_fill;
            wait_time -= Math.floor((Date.now() - joinrequest.create_time*1000)/1000);
            
            wait_time = Math.max(0, wait_time);
            if(wait_time == 0){
                matchStore.remove_joinrequest(joinrequest.match_id);
            }

            const seconds = Math.floor(wait_time % 60);
            
            const wait_string = `${Math.floor(wait_time / 60)}:${String(seconds).padStart(2, "0")}`;
            joinrequest.wait_time = wait_string;
        }
    }
    for(let [join, join_time] of matchStore.get_joins){
        if(join == null || join.match == null){
            continue;
        }
        join.wait_time = (join.match.time_to_wait - (Date.now()/1000 - join_time)).toFixed(0)
    }

}

function get_current_active_match(){
    if(extra_data.current_active_index >= matchStore.youngest_matches.length){
        extra_data.current_active_index = 0;
    }
    else if(extra_data.current_active_index < 0){
        extra_data.current_active_index = matchStore.youngest_matches.length - 1;
    }
    return matchStore.youngest_matches[extra_data.current_active_index];
}

function get_current_active_join(){
    if(matchStore.get_joins.length == 0){
        return [null, null];
    }
    if(extra_data.join_index < 0){
        extra_data.join_index = matchStore.get_joins.length - 1;
    }else if(extra_data.join_index >= matchStore.get_joins.length){
        extra_data.join_index = 0;
    }

    return matchStore.get_joins[extra_data.join_index];
}
</script>
<template>
    <div v-if="matchStore.youngest_matches != null"
         :set="active_match = get_current_active_match()"
    >
        <div class="text-xl font-semibold flex justify-center items-center 
            gap-x-2 p-2 bg-accent-3-color text-light-color"
        >
            <ChevronLeftIcon 
                class="size-6 hover:text-accent-2-color cursor-pointer" 
                :class="{
                    'invisible': (matchStore.youngest_matches.length <= 1)
                }"
                @click="extra_data.current_active_index -= 1"
            />

            <div class="flex max-w-prose grow justify-center items-center gap-x-4">
                <span> Active Match: </span>
                <span v-if="active_match.match_length == 0" class="flex gap-x-0 items-center">
                    {{ active_match.stake }}<SparklesIcon class="size-6" />
                </span> 
                <span v-else>
                    {{ active_match.match_length }}pt
                </span> 
                <span class="tabular-nums">
                    {{ active_match.wait_time }}
                </span>
                <Tooltip :text="'Cancel this match'" 
                    :direction="'bottom'">
                    <span class="sr-only">
                        Cancel
                    </span>
                    <XMarkIcon 
                        class="w-6 h-6 cursor-pointer hover:text-blunder-color"
                        @click="matchStore.remove_joinrequest(active_match.match_id)"
                    />
                </Tooltip>
            </div>

            <ChevronRightIcon 
                class="size-6 hover:text-accent-2-color cursor-pointer" 
                :class="{
                    'invisible': (matchStore.youngest_matches.length <= 1)
                }"
                @click="extra_data.current_active_index += 1"
            />

        </div>
    </div>

    <Popup
        :show="matchStore.get_joins != null && matchStore.get_joins.length > 0"
    >
    <div
         v-if="get_current_active_join() != null"
        :set="[join, join_time] = get_current_active_join()"
    >
    <ChevronLeftIcon
        class="size-8 left-0 top-1/3 absolute cursor-pointer hover:text-accent-3-color" 
        :class="{
            'invisible': (matchStore.get_joins.length <= 1)
        }"
        @click="extra_data.join_index -= 1"
    />
    <ChevronRightIcon 
        class="size-8 right-0 top-1/3 absolute cursor-pointer hover:text-accent-3-color" 
        :class="{
            'invisible': (matchStore.get_joins.length <= 1)
        }"
        @click="extra_data.join_index += 1"
    />
    <div class="flex flex-col items-center gap-y-3"
         v-if="join != null"
    >
        <span class="text-xl">
            {{ extra_data.join_index + 1 }} / {{ matchStore.get_joins.length }}
        </span>
    <div class="flex justify-center items-center gap-x-4" 
    >
        <span class="text-2xl font-semibold"> 
            <UserName :user="join" :link_to_userview="false"/>
        </span>
        <span class="text-xl font-thin">({{ join.rating.toFixed(0) }})</span>
    </div>
    <h1 class="text-center">Wants to join your:</h1>
    <div class="flex flex items-center text-2xl gap-x-2">
        <span v-if="join.match.match_length == 0" class="flex gap-x-1 items-center">
            {{ join.match.stake }}<SparklesIcon class="size-6" />
        </span>
        <span v-else>
            {{ join.match.match_length }} point
        </span>
        <span> ({{to_timecontrol_string(join.match)[0] }})</span>
    </div>
    <span>Match</span>
    <h1 class="mx-auto text-4xl font-semibold text-center my-4">
        {{  join.wait_time }}
    </h1>
    <div class="flex flex-col w-full">
        <ButtonLoad class="btn btn-blue mt-2 h-10" 
            @click="extra_data.state='accepting'; matchStore.join_accept(join.match_id, join.user_id)"
                    :loading="extra_data.state == 'accepting'"
        >
            Accept
        </ButtonLoad>
        <button class="btn btn-red mt-2 w-full" 
            @click="matchStore.join_reject(join.match_id, join.user_id)"
        >
            Reject 
        </button>
    </div> 
    </div> 
    </div>
    </Popup>
</template>

<style scoped>
</style>

