<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {MoonIcon, SunIcon, ComputerDesktopIcon} from '@heroicons/vue/24/solid'

const router = useRouter();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const props = defineProps({
});

const states = ["light", "system", "dark"];

const extra_data = reactive({
    state: "system",
});

function get_darkmode_preference(){
    if( typeof window !== 'undefined' ){
        const preference = localStorage.getItem("themePreference");
        if(preference == null){
            return false;
        }else if(preference == "system"){
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches === true;
        }else{
            return preference == "dark";
        }
    }
    return false;
}

function set_darkmode(){
    document.body.classList.toggle('darkmode', get_darkmode_preference());

    const meta = document.querySelector('meta[name="theme-color"]')
    if(meta){
        const color = window.getComputedStyle(document.body).getPropertyValue("background-color");
        meta.setAttribute('content', color )
    }else{
        console.log("No theme!");
    }
}

function set_preference(state){
    if(!states.includes(state)){
        console.error("Preference not supported:", state);
        return;
    }
    
    if( ["light", "dark", "system"].includes(state) ){
        localStorage.setItem("themePreference", state);
    }else{
        localStorage.removeItem("themePreference");
    }

    extra_data.state = state;
    set_darkmode();
}

onMounted(async () => {
    const preference = localStorage.getItem("themePreference");

    console.log(preference);

    if( ["light", "dark", "system"].includes(preference) ){
        extra_data.state = preference;
    }else{
        extra_data.state = "light";
    }
});

</script>
<template>
    <div class="flex w-32 items-center gap-x-4 bg-main-2-color rounded-full justify-between 
        transition duration-500">
        <SunIcon 
            class="size-8 rounded-full p-0.5 cursor-pointer hover:text-accent-4-color" 
            :class="{
                'bg-accent-3-color text-light-color': extra_data.state == 'light',
            }"
            @click="set_preference('light')"
        />
        <ComputerDesktopIcon 
            class="size-8 rounded-full p-0.5 cursor-pointer hover:text-accent-4-color" 
            :class="{
                'bg-accent-3-color text-light-color': extra_data.state == 'system',
            }"
            @click="set_preference('system')"
        />
        <MoonIcon
            class="size-8 rounded-full p-0.5 cursor-pointer hover:text-accent-4-color" 
            :class="{
                'bg-accent-3-color text-light-color': extra_data.state == 'dark',
            }"
            @click="set_preference('dark')"
        />
    </div>
</template>

<style>
</style>

