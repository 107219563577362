<script setup>
/*
*/
import {ref, watch, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {TrashIcon, CheckIcon, FireIcon, XMarkIcon, SparklesIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import Board from './Board.vue'
import Username from './UserName.vue'
import MatchList from './MatchList.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useDailyStore } from '@/stores/dailystore.js'
const dailyStore = useDailyStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const extra_data = reactive({
    matches: [],
    joinrequests: [],
});

onMounted( () => {
    userStore.loadUser().then( () => {
        dailyStore.get_user_matches();
        console.log(dailyStore.matches);
        dailyStore.current_match_id = null;
    });
});

async function accept_match(match_id, user_id){
    await matchStore.join_accept(match_id, user_id); 
    await dailyStore.get_user_matches();
    await matchStore.get_active("async");
}

</script>

<template>
<Header />
<div class="w-screen h-rest flex-col relative">
    <div class="container mx-auto">
        <h2 class="text-2xl my-4">
            My Daily Matches
        </h2>
        <div v-for="joins, match_id in matchStore.joins['async']" 
            class="my-12"
        >
            <div v-for="[join, join_time] in joins"
                 class="flex justify-between items-center h-10"
            >
                <div class="flex gap-x-4 items-center">
                    <Username 
                        :user="join"
                    />
                    <span class="flex items-center">
                        <FireIcon class="w-4 h-4" />
                        {{ join.rating.toFixed(0) }}
                    </span>

                </div>
                <div class="flex gap-x-4">
                    <button class="btn btn-blue mt-2 w-full" 
                        @click="accept_match(match_id, join.user_id)"
                    >
                        Accept
                    </button>
                    <button class="btn btn-red mt-2 w-full" 
                        @click="matchStore.join_reject(match_id, join.user_id)"
                    >
                        Reject 
                    </button>
                </div> 
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="match in dailyStore.matches"
                :class="{'grayscale': !dailyStore.is_active(match)}"
                 class=""
            >
                <router-link :to="{name: 'daily', params: {match_id:match.match_id}}"
                    class="flex flex-col justify-center items-center"
                >
                <div class="flex w-full relative">
                    <Board 
                        :boardID="'daily_' + match.match_id"
                        :positionString="match.state"
                        :player_color="dailyStore.get_player_color(match)" 
                        :can_do_actions="false"
                        class="grow"
                    />
                </div>
                <div class="flex items-center gap-x-4">
                    <Username :user="dailyStore.get_player(match, true)"/>
                    <span>{{ match.match_length}}pt</span> 
                </div>
                </router-link>
            </div>
        </div>

        <h2 class="text-2xl my-4">
            Looking for an Opponent
        </h2>
        <router-link :to="{name: 'match-menu', query: {selected: 'daily'}}"
            class="my-4"
        >
            <button class="btn btn-blue h-12 mb-4">
                Create Daily Match
            </button>
        </router-link>

        <MatchList 
            :match_type="'async'"
            :match_status="['active']"
        />
    </div>
</div>
</template>

<style scoped>
</style>

