<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon, ArrowLeftIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import UserFind from './UserFind.vue'
import Avatar from './Avatar.vue'
import UserTag from './UserTag.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
const route = useRoute();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const club_id = route.params.club_id;
const json_fields = ["match_presets"]; 
const date_fields = []; 

const select_fields = {
};

const advanced_fields = ["match_presets"];

const editable_fields = [
    "name", "description", "tag", "match_presets",
];

const actions = {
};


const field_info = {
    "name": "A short name for the club",
    "name": "The tag that can be used by members",
    "description": "A description of the club. This field accepts markdown.",
};

const extra_data = reactive({
    info: {},
    club: {},
    match_list: [],
    invite: null,
    show_edit: false,
    show_advanced: false,

    current_action: null,
});

const club_data = reactive({
});

const user_data = reactive({
});

const ping_data = reactive({
    title: "",
    message: "",
})

function get_time_string(utc_timestamp){
    /* 
    Returns a local timestamp accounting for the timezon of the client 

    The timestamp is to be used in datetime-local inputs
    */
    const timezone_offset = (new Date()).getTimezoneOffset()*60*1000;
    const time_string = new Date(
        (utc_timestamp * 1000) - timezone_offset
    ).toISOString().slice(0,16);

    return time_string;
}

async function get_club_info(){
    var url = app_server + `/club/${club_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();


    if(data.matches){
        extra_data.match_list.push(...data.matches);
    }

    Object.assign(club_data, data.club);

    if(data.club != null){
        if(data.club.name != null){
            // setPageTitle(data.club.name);
        }
        for(let field of json_fields){
            if(club_data[field] != null){
                club_data[field] = JSON.stringify(
                    club_data[field], null, 4
                );
            }
        }
        for(let field of date_fields){
            if(club_data[field] != null){
                club_data[field] = get_time_string(club_data[field]);
            }
        }
        if(data.club.round_times != null){
            for(let i in data.club.round_times){
                club_data.round_times[i][0] = get_time_string(club_data.round_times[i][0]);
                club_data.round_times[i][1] = get_time_string(club_data.round_times[i][1]);
            }
        }
    }
    console.log("Club data", data);

    Object.assign(extra_data, data);
}

async function get_user_data(){
    var url = app_server + `/club/mod/${club_id}/userinfo/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();
    console.log(data);
    Object.assign(user_data, data.users);
}

async function ping_members(){
    if(!confirm(`Are you sure you want to ping all club members?.`)){
        return;
    }
    const response = await fetch(app_server + `/club/mod/${ club_id }/ping/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(ping_data),
    });
    const data = await response.json();

    console.log(data);

    get_club_info();
}

async function invite_member(){
    if(!confirm(`Are you sure you want to invite ${extra_data.invite.username}?`)){
        return;
    }
    const response = await fetch(app_server + `/club/mod/${ club_id }/invite/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            user_id: extra_data.invite.user_id, 
        }),
    });
    const data = await response.json();

    extra_data.invite = null;

    console.log(data);

    get_club_info();
}

async function uninvite_member(user_id){
    if(!confirm(`Are you sure you want to cancel the invitation for ${user_data[user_id].username}?`)){
        return;
    }
    const response = await fetch(app_server + `/club/mod/${ club_id }/uninvite/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            user_id: user_id, 
        }),
    });
    const data = await response.json();

    console.log(data);

    get_club_info();
}

async function kick_member(user_id){
    if(!confirm(`Are you sure you want to kick ${user_data[user_id].username}?`)){
        return;
    }
    const response = await fetch(app_server + `/club/mod/${ club_id }/kick/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            user_id: user_id, 
        }),
    });

    const data = await response.json();

    console.log(data);

    get_club_info();
}

async function save_club(){
    // First compute timestamps from the club times
    const data = Object.assign({}, club_data);
    var url = app_server + `/club/${club_id}/`;

    // if(data["tag"].length != 3){
    //     messageStore.alertUser("Tag incorrect", "Club tags can only be 3 alphanumeric characters long");
    //     return;
    // }

    for(let field of json_fields){
        if(data[field] != null){
            data[field] = JSON.parse(data[field]);
        }
    }
    for(let field of date_fields){
        if(data[field] != null){
            data[field] = new Date(data[field]) / 1000;
        }
    }

    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const resp_data = await response.json();

    if(resp_data.status == "error"){
        messageStore.alertUser("Error", resp_data.message);
    }
    else if(resp_data.status == "success"){
        messageStore.alertUser("Success", "The changes have been saved.");
    }

    console.log(resp_data);
    get_club_info();
}

onMounted(async () => {
    await Promise.allSettled([
        userStore.loadUser(),
        get_user_data(),
    ])
    await get_club_info();
});

</script>
<template>
    <Header/>
    <div class="container mx-auto max-w-[70svh] flex flex-col gap-y-8 mb-32">
        <h1 class="text-4xl mt-8 mb-4 flex items-center gap-x-4">
            <router-link :to="{name: 'club-info', params: {club_id: club_id}}">
                <ArrowLeftIcon class="size-8 hover:text-accent-3-color" />
            </router-link>
            {{ extra_data.info.name }}
        </h1>
        <div class="flex gap-x-2">
            <button class="btn btn-blue" @click="extra_data.show_edit ^= true">
                Edit
            </button>
            <select
                v-model="extra_data.current_action"
            >
                <option :value="null">Choose action...</option>
                <option v-for="key in Object.keys(actions)"
                >
                    {{ key }}
                </option>
            </select>
            <ButtonLoad 
                class="btn btn-blue"
                :disabled="extra_data.current_action == null"
                :fn="actions[extra_data.current_action]"
            >
                Do Action
            </ButtonLoad>
        </div>
        <div class="flex flex-col gap-y-4">
            <div class="flex flex-col gap-y-2 max-w-prose" v-if="extra_data.show_edit">
                <h2 class="text-xl">Club Properties</h2>
                <label v-for="field in editable_fields.filter((x) => extra_data.show_advanced || !advanced_fields.includes(x))">
                    <Tooltip :text="field_info[field] || ''">
                    <span class="capitalize">{{ field.replaceAll("_", " ") }}</span>
                    </Tooltip>
                    <textarea 
                        v-model="club_data[field]"
                        v-if="['description', 'rruleset', ...json_fields].includes(field)"
                        :id="field"
                        class="w-full"
                    ></textarea>
                    <input type="datetime-local" v-else-if="date_fields.includes(field)"
                        v-model="club_data[field]"
                    >
                    <select v-else-if="select_fields[field] != null"
                        v-model="club_data[field]"
                    >
                        <option v-for="option in select_fields[field]"
                                :value="option[1]">
                            {{ option[0] }}
                        </option>
                    </select>
                    <input 
                        class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                                ring-inset ring-gray-300 placeholder:text-gray-400 
                                focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        v-model="club_data[field]"
                        v-else
                        :id="field"
                    />
                </label>

                <div class="flex gap-x-2">
                    <ButtonLoad :fn="save_club" class="h-10 btn btn-blue grow">
                        Save Club Details
                    </ButtonLoad>
                    <button class="h-10 btn btn-yellow" @click="extra_data.show_advanced=true">
                        Advanced
                    </button>
                </div>
            </div>
        </div>
        <div class="" v-if="extra_data.club.members">
            <h2 class="text-xl mb-2">
                Members ({{ extra_data.club.members.length }} / {{extra_data.club.max_members}})
            </h2>
            <div v-for="user_id in extra_data.club.members"
                 :key="user_data[user_id]"
                 class="hover:bg-accent-1-color flex justify-between group"
            >
                <UserName :user="user_data[user_id]" :user_id="user_id"/>
                <div class="invisible group-hover:visible">
                    <ButtonLoad class="btn btn-blue" :fn="() => kick_member(user_id)">
                        Kick
                    </ButtonLoad>
                </div>
            </div>
        </div>

        <div class="">
            <h2 class="text-xl mb-2">
                Invited Users
            </h2>
            <div v-for="user_id in extra_data.club.invited"
                 :key="user_data[user_id]"
                 class="hover:bg-accent-1-color flex justify-between group"
            >
                <UserName :user="user_data[user_id]" :user_id="user_id"/>
                <div class="invisible group-hover:visible">
                    <ButtonLoad class="btn btn-blue" :fn="() => uninvite_member(user_id)">
                        Remove
                    </ButtonLoad>
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-y-4">
            <h2 class="text-xl mb-2">
                Invite Member
            </h2>
            <UserName  v-if="extra_data.invite != null" :user="extra_data.invite"/>
            <div class="flex gap-x-2 items-center">
                <ButtonLoad class="btn btn-blue w-64 h-10"
                        :disabled="extra_data.invite == null"
                        :fn="invite_member"
                >
                    Invite
                </ButtonLoad>
                <UserFind @selecteduser="(x) => extra_data.invite = x"/>
            </div>
        </div>
        <div class="flex flex-col gap-y-4">
            <h2 class="text-xl mb-2">
                Ping members
            </h2>
            <textarea 
                v-model="ping_data.message" 
                class="w-full max-w-prose"
                placeholder="Message to the members of the club"></textarea>
            <button class="btn btn-blue w-64 h-10"
                    :disabled="ping_data.message.length < 3"
                    @click="ping_members()"
            >
                Send</button>

        </div>
    </div>
</template>

<style>
</style>

