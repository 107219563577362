<script setup>
import {ref, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { UserGroupIcon, NumberedListIcon, UsersIcon, SparklesIcon, ComputerDesktopIcon, CalendarIcon} from '@heroicons/vue/24/solid'

import { Match } from '../assets/js/match.js'
import { time_controls, time_control_to_text } from '../assets/js/timecontrols.js'
import { debounce } from '../assets/js/debounce.js'

import Header from './Header.vue'
import Tooltip from './Tooltip.vue'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

const options = [
    {
        name: "Match",
        type: "match",
        options: {
            match_length: [1, 21],
            time_control: time_controls.match,
        },
        description: "Play a match against another human. Winning the match will earn you rating points.",
        icon: UsersIcon,
        buttons: [{
            text: "Create Match",
            router_to: debounce(create_match),
        }]
    },
    {
        name: "Unlimited",
        type: "unlimited",
        options: {
            stake: true, 
            time_control: time_controls.unlimited,
        },
        description: "Play an unlimited game against another human. Winning a game will earn you sparkles. If you lose you lose sparkles. Each first day of the month your sparkles are reset to 800.",
        icon: SparklesIcon,
        buttons: [{
            text: "Create Match",
            router_to: debounce(create_match),
        }],
    },
    {
        name: "Computer",
        description: "Play against one of our bots to practice. Unlock more bots by winning stars!",
        icon: ComputerDesktopIcon,
        buttons: [{
            text: "Choose Opponent",
            router_to: () => router.push({name: 'bot'}),
        }],
    },
    {
        name: "Daily",
        type: "daily",
        options: {
            match_length: [1, 21],
            time_control: time_controls.daily,
        },
        description: "You have one full day to think about your move!",
        icon: CalendarIcon,
        buttons: [{
            text: "Create Match",
            router_to: debounce(create_match),
        },{
            text: "Match List",
            router_to: () => router.push({name: 'daily-list'}),
        }],
    },
    {
        name: "Tournament",
        description: "Play in structured tournaments to win MP, flairs, and achievements!",
        icon: NumberedListIcon,
        buttons: [{
            text: "Tournament List",
            router_to: () => router.push({name: 'tournament-list'}),
        }],
    },
    {
        name: "Clubs",
        description: "Play together in your club on OpenGammon",
        icon: UserGroupIcon,
        buttons: [{
            text: "Club List",
            router_to: () => router.push({name: 'club-list'}),
        }],
    },
];

const initial_states = [
    ["Backgammon", "11jjjjjhhhccccc:ooddddd88866666:N0N::W:IW:0:0"],
    // ["Last Roll", "cc:66:N0N::W:IW:0:0"],
    // ["End Game", "jjjkkkllmm:1223344455666go:N0N:W:IW:0:0"],
];

const extra_data = reactive({
    selected_choice: options[0],
});

const match_settings = reactive({
    match_length: 5,
    stake: 10,
    time_control: "Normal",
    initial_state: "11jjjjjhhhccccc:ooddddd88866666:N0N::W:IW:0:0",
});

const custom_time = reactive({
    reserve: 60,
    delay: 10,
    increment: 0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const tournament_id = route.params.tournament_id;

onMounted(() => {
    let last_settings = localStorage.getItem("last_match_settings");
    if(last_settings != null){
        last_settings = JSON.parse(last_settings);
        Object.assign(match_settings, last_settings);
    }
    if(route.query.selected){ // This allows for linking to a certain sub
        console.log("SELECTED", route.query.selected);
        extra_data.selected_choice = options.find((x) => x.type == route.query.selected);
    }

});

onUnmounted( () => {
    localStorage.setItem("last_match_settings", JSON.stringify(match_settings));
});

async function create_match(){
    const create_match_settings = Object.assign({}, match_settings);
    if(extra_data.selected_choice.type == "match"){
        create_match_settings.stake = null;
    }else if(extra_data.selected_choice.type == "daily"){
        create_match_settings.stake = null;
        create_match_settings.match_type = "async";
        create_match_settings.time_control = "24h";
    }else if(extra_data.selected_choice.type == "unlimited"){
        create_match_settings.match_length = 0;
    }

    if(extra_data.selected_choice.type == "daily"){
    }
    console.log("Creating match", extra_data.selected_choice);

    const time_controls = Object.fromEntries(extra_data.selected_choice.options.time_control)
    create_match_settings.timecontrol = time_controls[create_match_settings.time_control];

    const match_data = await matchStore.create_joinrequest(create_match_settings);

    if(create_match_settings.match_type == "async"){
        router.push({name: 'daily-list'});
    }else{
        router.push({name: 'home'});
    }
}

function max_stake(){
    return Math.max(1, Math.ceil(userStore.info.coins / 16));
}
function min_stake(){
    return 0;
}

function update_custom_timecontrol(){
    extra_data.selected_choice.options.time_control.at(-1)[1] = 
        `${custom_time.reserve}|${custom_time.delay}|${custom_time.increment}`;
    console.log(extra_data.selected_choice.options.time_control);
}

</script>

<template>
<div>
    <Header />
     
    <div class="container mx-auto max-w-xl flex flex-col-reverse items-center gap-8 mb-16">
        <div class="mx-auto w-96 bg-main-2-color flex flex-col items-center gap-y-4 p-4 rounded-lg relative"
        >
            <div class="flex flex-col items-center gap-y-4">
                <component :is="extra_data.selected_choice.icon" 
                    class="size-16 overflow-hidden text-accent-3-color">
                </component>
                <p class="text-xl font-semibold">
                    {{ extra_data.selected_choice.name}}</p>
            </div>
            <p class="text-center min-h-[5em]">
                {{ extra_data.selected_choice.description }}
            </p>
            <div v-if="extra_data.selected_choice.options" 
                class="w-full flex flex-col gap-y-4 md:gap-y-8">
                <div class="flex flex-col gap-y-2 items-center" 
                    v-if="extra_data.selected_choice.options.match_length">
                    <label for="match_length" 
                        class="block text-lg font-medium leading-6">
                        Match Length:
                    </label>
                    <span class="text-4xl">
                        {{ match_settings.match_length }}
                    </span>
                    <input type="range" v-model="match_settings.match_length" 
                        class="w-3/4"
                        :min="extra_data.selected_choice.options.match_length[0]" 
                        :max="extra_data.selected_choice.options.match_length[1]" 
                        step="2" 
                    />
                </div>
                <div class="flex flex-col gap-y-2 items-center" 
                    v-if="extra_data.selected_choice.options.stake">
                    <label for="stake" 
                        class="block text-lg font-medium leading-6">
                        Stake:
                    </label>
                    <span class="text-4xl">
                        <SparklesIcon class="w-6 h-6 inline-block"/>
                        {{ match_settings.stake }}
                    </span>
                    <input type="range" v-model="match_settings.stake" 
                        class="w-3/4"
                        :min="min_stake()" :max="max_stake()" step="1" 
                    />
                </div>
                <div class="flex flex-col gap-y-3 items-center" 
                    v-if="extra_data.selected_choice.options.time_control">
                    <label for="stake" 
                        class="block text-lg font-medium leading-6">
                        Time Control:
                    </label>
                    <div class="grid grid-cols-3 gap-2">
                        <div class="w-24 h-12 rounded transition-all 
                                text-lg font-semibold
                                flex items-center justify-center cursor-pointer
                                ring-accent-3-color"
                            @click="match_settings.time_control = time_control[0]" 
                            :class="{
                                'bg-accent-3-color ring': 
                                match_settings.time_control == time_control[0],
                                ' bg-main-3-color':
                                match_settings.time_control != time_control[0],
                            }"
                            v-for="time_control in extra_data.selected_choice.options.time_control">
                            <Tooltip :text="time_control_to_text(time_control[1])">
                            {{ time_control[0] }}
                            </Tooltip>
                        </div>
                    </div>
                    <div v-if="match_settings.time_control == 'Custom'"
                        class="flex items-center text-case-med-color"
                        >
                        <input type="number" min="0" max="300" 
                            v-model="custom_time.reserve" 
                            @change="update_custom_timecontrol()"
                        > |
                        <input type="number" min="0" max="300" 
                            v-model="custom_time.delay" 
                            @change="update_custom_timecontrol()"
                        > |
                        <input type="number" min="0" max="300" 
                            v-model="custom_time.increment" 
                            @change="update_custom_timecontrol()">
                    </div>
                </div>
            </div>
            <button v-for="button in extra_data.selected_choice.buttons"
                class="btn btn-blue h-12 md:h-16 w-3/4 text-2xl py-2"
                @click="button.router_to()"
            >
                {{ button.text }}
            </button>
        </div>
        <div class="grid grid-cols-3 gap-8"
        >
            <div class="size-24 bg-main-2-color p-2 rounded-lg 
                 ring-accent-3-color overflow-hidden relative cursor-pointer"
                 :class="{
                    'ring !bg-accent-1-color': option.name == extra_data.selected_choice.name
                 }"
                v-for="option in options"
                @click="extra_data.selected_choice = option"
            >
                <div class="flex flex-col items-center gap-y-4 text-accent-3-color">
                    <component :is="option.icon" 
                        class="size-16"
                        :class="{'': option.name == extra_data.selected_choice.name}"
                    >
                    </component>
                </div>
                <p class="font-thin text-center">
                    {{ option.name}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<style>
</style>


